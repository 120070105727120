import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { User } from '@/interfaces/user';
import api from '@/lib/api';

interface QueryGetAllUsers {
	current_page: number;
	limit: number;
	total_pages: number;
	total_rows: number;

	results: User[];
}
export async function getAllUsers(token: string): Promise<QueryGetAllUsers> {
	const res = await api.get(`/person`, getHeadersAuthorization(token));
	return res.data;
}
