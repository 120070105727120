import LoadingPage from '@/components/loading-page';
import SocialMediaButtons from '@/components/social-media-buttons';
import Background404 from '@/components/ui/background-404';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { motion } from 'framer-motion';
import { Suspense, useEffect } from 'react';

export default function DrawHasNotStarted() {
	const { theme, setTheme } = useTheme();

	useEffect(() => {
		if (theme === 'light') setTheme('dark');
	}, []);
	return (
		<Suspense fallback={<LoadingPage />}>
			<Background404>
				<div className="z-50 flex flex-col items-center gap-4">
					<motion.div variants={SlideFromTop} initial="initial" animate="animate" className="flex flex-col gap-2 ">
						<motion.h1 variants={SlideFromTopItem} className="text-xl sm:text-3xl">
							O sorteio ainda não está disponível
						</motion.h1>
						<motion.h1 variants={SlideFromTopItem} className="text-base font-normal">
							Por favor, aguarde e acompanhe-nos nas redes para ficar por dentro dos próximos.
						</motion.h1>
					</motion.div>
					<SocialMediaButtons />
				</div>
			</Background404>
		</Suspense>
	);
}
