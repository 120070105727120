import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { DrawReleaseTimePreset } from '@/constants/draw/draw-release-time-preset';
import { DrawType } from '@/constants/draw/draw-types';
import { cleanPhoneNumber } from '@/functions/clean-phone-number';
import { formatCPF } from '@/functions/format-cpf';
import { getZodErrors } from '@/functions/get-zod-errors';
import { getPublicDrawId } from '@/functions/tanstack-query/draws/get-public-draw-id';
import useDebounce from '@/hooks/use-debounce';
import { useDevToolsStatus } from '@/hooks/use-dev-tools-status';
import useWindowSize from '@/hooks/use-window-size';
import api from '@/lib/api';
import DrawFiles from '@/pages/draw/components/draw-files';
import { Clock } from '@/pages/draw/release-draw/components/clock';
import { NewUserFormSchema, UserForm } from '@/pages/users/components/table/components/users-data-table-toolbar';
import AlreadyAnsweredDraw from '@/templates/already-answered-draw';
import DrawHasEnded from '@/templates/draw-has-ended';
import DrawHasNotStarted from '@/templates/draw-has-not-started';
import { Checkbox, CircularProgress, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs, useDisclosure } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { isAfter } from 'date-fns';
import { LucideArrowLeft, LucideArrowRight, LucideGift, LucideUser } from 'lucide-react';
import { ChangeEvent, Suspense, useEffect, useState } from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { Key } from 'ts-key-enum';
import ModalTermsOfUse from '../components/modal-terms-of-use';

export type Keys = 'identification' | 'participation';

interface PostTextForm {
	id_draw: string;
	cpf: string;
	text: string;
}

interface PostQuestionaryForm {
	id_draw: string;
	cpf: string;
	question_answer: {
		id_question: string;
		id_answer: string;
	}[];
}

export default function TryMyLuck() {
	const { id } = useParams();
	const { width } = useWindowSize();

	const isDevToolsOpen = useDevToolsStatus();
	const navigate = useNavigate();

	const modalSeeTerms = useDisclosure();
	const modalEditUserData = useDisclosure();
	const modalIsLoading = useDisclosure();

	useHotkeys(Key.F12, () => !isMobile && navigate('/nao-permitimos-acesso-ao-console'));
	useHotkeys('ctrl+shift+c', () => !isMobile && navigate('/nao-permitimos-acesso-ao-console'));
	useHotkeys('ctrl+shift+i', () => !isMobile && navigate('/nao-permitimos-acesso-ao-console'));

	useEffect(() => {
		if (!isSafari && isDevToolsOpen && !isMobile) return navigate('/nao-permitimos-acesso-ao-console');
	}, [isDevToolsOpen, width, isSafari]);

	const [isLoadingPost, setIsLoadingPost] = useState(false);
	const [isLoadingUserData, setIsLoadingUserData] = useState(false);
	const [isLoadingUserParticipation, setIsLoadingUserParticipation] = useState(false);

	useEffect(() => {
		if (isLoadingUserData || isLoadingPost || isLoadingUserParticipation) return modalIsLoading.onOpen();
		return modalIsLoading.onClose();
	}, [isLoadingUserData, isLoadingPost, isLoadingUserParticipation]);

	const [selectedKey, setSelectedKey] = useState<Keys>('identification');

	const { data: Draw, error } = useQuery({
		queryKey: ['get-public-draw-id', id],
		queryFn: () => getPublicDrawId(id!),
		enabled: !!id,
	});

	const [drawHasEnded, setDrawHasEnded] = useState(false);

	const [timeIsOver, setTimeIsOver] = useState(false);
	const [hasStartedDraw, setHasStartedDraw] = useState(false);

	const [totalTimeForAnswersInSeconds, setTotalTimeForAnswersInSeconds] = useState<number>(0);

	const [daysInput] = useState('');
	const [hoursInput] = useState('');
	const [minutesInput] = useState('');

	useEffect(() => {
		let totalSeconds = 0;
		if (daysInput !== '') {
			totalSeconds += parseInt(daysInput) * 24 * 60 * 60;
		}
		if (hoursInput !== '') {
			totalSeconds += parseInt(hoursInput) * 60 * 60;
		}
		if (minutesInput !== '') {
			totalSeconds += parseInt(minutesInput) * 60;
		}
		setTotalTimeForAnswersInSeconds(totalSeconds);
	}, [daysInput, hoursInput, minutesInput]);

	const calcRestantTimeForAnswer = () => {
		if (Draw && Draw.end_date) {
			const currentTime = new Date();
			const finalTime = new Date(Draw.end_date);
			const diffInMilliseconds = finalTime.getTime() - currentTime.getTime();

			if (diffInMilliseconds <= 0) {
				setTimeIsOver(true);
				setTotalTimeForAnswersInSeconds(0);
				return;
			}

			const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
			setTotalTimeForAnswersInSeconds(diffInSeconds);
		}
	};

	useEffect(() => {
		setDrawHasEnded(false);
		if (Draw && Draw.end_date) {
			if (isAfter(new Date(), Draw.end_date) || Draw.drawed_at) {
				return setDrawHasEnded(true);
			} else {
				setHasStartedDraw(true);
				const intervalId = setInterval(calcRestantTimeForAnswer, 1000);
				return () => clearInterval(intervalId);
			}
		} else {
			setHasStartedDraw(false);
		}
		return;
	}, [Draw]);

	const initialUserDataState: UserForm = {
		name: '',
		cpf: '',
		phone: '',
		accept_terms: true,
	};

	const [userData, setUserData] = useState<UserForm>(initialUserDataState);
	const [notFoundUser, setNotFoundUser] = useState(true);
	const [alreadyAnswered, setAlreadyAnswered] = useState(false);
	const debouncedCpf = useDebounce(userData.cpf, 1250);

	const fillUserData = async () => {
		setIsLoadingUserData(true);
		await api
			.get(`/person/${debouncedCpf}`)
			.then(async (res) => {
				setIsLoadingUserData(false);

				setNotFoundUser(false);
				return setUserData({
					cpf: debouncedCpf,
					name: res.data.name,
					phone: res.data.phone,
					accept_terms: true,
				});
			})
			.catch(() => {
				return setNotFoundUser(true);
			})
			.finally(() => {
				setIsLoadingUserData(false);
				return setTimeout(async () => {
					await verifyIfAlreadyAnswered(Draw?.id!, debouncedCpf);
				}, 350);
			});
	};

	const verifyIfAlreadyAnswered = async (drawId: string, cpf: string) => {
		setIsLoadingUserParticipation(true);
		await api
			.get(`/person-draw/${drawId}/${cpf}`)
			.then(() => {
				setAlreadyAnswered(false);
				setSelectedKey('participation');
			})
			.catch((err) => {
				if (err.response.status === 406) {
					setUserHits(err.response.data.hits);
					return setAlreadyAnswered(true);
				}
			})
			.finally(() => setIsLoadingUserParticipation(false));
	};

	useEffect(() => {
		if (debouncedCpf?.length === 11) {
			fillUserData();
		}
		if (debouncedCpf?.length !== 60 && debouncedCpf?.length !== 11) {
			setUserData({
				cpf: debouncedCpf,
				name: initialUserDataState.name,
				phone: initialUserDataState.phone,
				accept_terms: initialUserDataState.accept_terms,
			});
			setNotFoundUser(true);
		}
		return;
	}, [debouncedCpf]);

	const handleVerifyUserAndCreate = async (user_data: UserForm, is_editing: boolean) => {
		if (!Draw) return;
		try {
			setIsLoadingUserData(true);
			const { id, ...userDataWithoutId } = user_data;

			const parsedUserForm = await NewUserFormSchema.parseAsync(userDataWithoutId);
			if (notFoundUser) {
				return await api
					.post(`/person`, parsedUserForm)
					.then(() => {
						toast.success(`Usuário criado com sucesso!`);
						setNotFoundUser(false);
						return setTimeout(async () => {
							return await fillUserData();
						}, 1500);
					})
					.catch((err) => toast.error(err.response.data.message))
					.finally(() => setIsLoadingUserData(false));
			}
			if (is_editing) {
				return await api
					.patch(`/person`, parsedUserForm)
					.then(() => {
						toast.success(`Usuário editado com sucesso!`);
						setNotFoundUser(false);
						return setTimeout(async () => {
							return await fillUserData();
						}, 1500);
					})
					.catch((err) => toast.error(err.response.data.message))
					.finally(() => setIsLoadingUserData(false));
			}
		} catch (error) {
			getZodErrors(error);
		}
	};

	function renderUserSubscriptionForm() {
		return (
			<div className=" flex w-full flex-col items-center justify-center gap-6">
				<Label htmlFor="cpf" className="w-full">
					CPF
					<Input
						id="cpf"
						placeholder="Apenas números"
						max={11}
						value={formatCPF(userData.cpf)}
						required
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const formattedCPF = e.target.value.replace(/\D/g, '').slice(0, 11);
							setUserData({ ...userData, cpf: formattedCPF });
						}}
					/>
					<span className="text-sm font-normal text-muted-foreground">Para garantirmos confiabilidade dos dados!</span>
				</Label>

				<Label htmlFor="name" className="w-full">
					Nome completo
					<Input
						id="name"
						placeholder="Insira um nome..."
						autoFocus
						value={userData.name}
						required
						disabled={!notFoundUser || (notFoundUser && debouncedCpf.length === 0)}
						onChange={(e: ChangeEvent<HTMLInputElement>) => setUserData({ ...userData, name: e.target.value })}
					/>
				</Label>
				<Label htmlFor="phone" className="w-full">
					Nº Telefone
					<Input
						id="phone"
						placeholder="-- - --------"
						value={cleanPhoneNumber(userData.phone!)}
						required
						disabled={!notFoundUser || (notFoundUser && debouncedCpf.length === 0)}
						max={11}
						onChange={(e: ChangeEvent<HTMLInputElement>) => setUserData({ ...userData, phone: cleanPhoneNumber(e.target.value!) ?? null })}
					/>
					<span className="text-sm font-normal text-muted-foreground">É por onde entraremos em contato com o ganhador!</span>
				</Label>
				{notFoundUser ? (
					<div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
						<div className="flex items-center gap-1">
							<Checkbox isSelected={userData.accept_terms!} onValueChange={(value) => setUserData({ ...userData, accept_terms: value })} />
							<h1
								onClick={modalSeeTerms.onOpen}
								className="cursor-pointer text-sm font-normal text-muted-foreground underline underline-offset-1"
							>
								Concordo com os termos de uso
							</h1>
						</div>

						{isLoadingUserData ? (
							<CircularProgress className="justify-end self-end" />
						) : (
							<Button
								disabled={
									!userData.accept_terms ||
									!userData?.cpf ||
									!userData?.name ||
									!userData?.phone ||
									!userData.phone.length ||
									userData.phone.length !== 11
								}
								onClick={() => handleVerifyUserAndCreate(userData, false)}
								className="w-full sm:w-auto"
							>
								{userData.accept_terms ? 'Quero participar!' : 'Aceite os termos'}
							</Button>
						)}
					</div>
				) : (
					<Button variant="link" onClick={modalEditUserData.onOpen}>
						Quero editar meus dados
					</Button>
				)}
			</div>
		);
	}

	const [counterText, setCounterText] = useState<number | null>(null);
	const [userText, setUserText] = useState<string | null>(null);
	const [userQuestions, setUserQuestions] = useState<PostQuestionaryForm['question_answer'] | null>([] as PostQuestionaryForm['question_answer']);
	const [userHits, setUserHits] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (selectedKey && selectedKey === 'identification') {
			setUserQuestions([] as PostQuestionaryForm['question_answer']);
			setUserText(null);
		}
		return;
	}, [selectedKey]);

	const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const inputText = e.target.value;

		const textWithoutSpecialChars = inputText.replace(/[,.!?*:;º°ª\-#_%]/gi, ' ');

		const words = textWithoutSpecialChars.split(/\s+/);

		const filteredWords = words.filter((word) => word.trim() !== '');

		setUserText(inputText);
		setCounterText(filteredWords.length);
	};

	const handleCheckboxChange = async (id_question: string, id_answer: string) => {
		setUserQuestions((prev) => {
			if (prev && prev.some((question) => question.id_question === id_question)) {
				return prev.map((question) => (question.id_question === id_question ? { id_question, id_answer } : question));
			}
			return prev && [...prev, { id_question, id_answer }];
		});

		await hideAnsweredQuestion(id_question);
	};

	const hideAnsweredQuestion = async (id_question: string) => {
		const Question = document.querySelector(`#question-${id_question}`);

		setTimeout(() => {
			Question?.setAttribute('class', 'hidden');
		}, 350);
	};

	function renderDrawForm() {
		if (!Draw || error) return <h1>Falha ao carregar dados, estamos tentando novamente...</h1>;

		if (Draw.type === DrawType.text) {
			let disableSendButton = false;
			if (Draw.text?.minimum_words) {
				disableSendButton = !userText || counterText! < Draw.text.minimum_words;
			} else {
				disableSendButton = !userText;
			}

			return (
				<div className="flex h-full w-full flex-col items-center justify-center gap-12">
					<div className="space-y-2 text-center">
						{Draw.files && Draw.files.length > 0 && <DrawFiles Draw={Draw} />}
						<h1 className="text-2xl font-medium">{Draw.title}</h1>
						<p className="overlfow-hidden text-ellipsis text-pretty font-normal">{Draw.description}</p>
					</div>

					<Label className="w-full">
						{Draw.text?.label}
						<Textarea className="min-h-36" value={userText!} onChange={handleTextChange} placeholder={Draw.text?.text} />
						{Draw.text?.minimum_words && counterText! < Draw.text.minimum_words && (
							<span className=" self-end text-xs font-normal">
								faltam {counterText} / {Draw.text.minimum_words} palavras
							</span>
						)}
					</Label>

					{isLoadingUserData || isLoadingPost ? (
						<CircularProgress className="self-end" />
					) : (
						<div className="flex flex-row items-center justify-between gap-4">
							<Button
								onClick={() => {
									setSelectedKey('identification');
									setUserQuestions(null);
								}}
								variant="emphasis"
							>
								<LucideArrowLeft size={20} />
								Voltar
							</Button>
							<Button
								className="self-end"
								disabled={disableSendButton}
								onClick={() => postUserParticipation(DrawType.text, { cpf: debouncedCpf, id_draw: Draw.id, text: userText! })}
							>
								Enviar
							</Button>
						</div>
					)}
				</div>
			);
		}

		if (Draw.type === DrawType.questions) {
			return (
				<div className="flex h-full w-full flex-col items-center justify-center gap-8">
					<div className="space-y-2 text-center">
						{Draw.files && Draw.files.length > 0 && <DrawFiles Draw={Draw} />}
						<h1 className="text-2xl font-medium">{Draw.title}</h1>
						<p className="overlfow-hidden text-ellipsis text-pretty font-normal">{Draw.description}</p>
					</div>

					{Draw.questions &&
						Draw.questions.map((question, questionIndex) => {
							return (
								<Card
									key={'question' + questionIndex}
									id={`question-${question.id}`}
									className="relative mb-4 w-full rounded-lg border p-4"
								>
									{Draw.questions?.length !== 1 && (
										<div className="absolute -top-4 left-4 bg-background px-2">{questionIndex + 1}.</div>
									)}
									<CardHeader className="p-1 ">
										<h1 className="mb-2 line-clamp-none w-full text-base">{question.question}</h1>
									</CardHeader>
									<CardContent className="flex flex-col gap-2 p-0">
										{question.answers &&
											question.answers.map((answer, answerIndex) => {
												return (
													<div
														key={'answer' + answerIndex}
														className="mb-2 flex items-center gap-2 rounded-md border border-input p-2"
													>
														<h1 className=" w-full overflow-hidden text-ellipsis text-base">{answer.answer}</h1>
														<Checkbox onChange={() => handleCheckboxChange(answer.id_question!, answer.id!)} />
													</div>
												);
											})}
									</CardContent>
								</Card>
							);
						})}

					{isLoadingPost ? (
						<CircularProgress className="self-end" />
					) : (
						<div className="flex w-full flex-row items-center justify-between gap-4">
							<Button onClick={() => setSelectedKey('identification')} variant="emphasis">
								<LucideArrowLeft size={20} />
								Voltar
							</Button>

							<Button
								className="self-end"
								disabled={!(userQuestions?.length === Draw.questions?.length)}
								onClick={() =>
									postUserParticipation(DrawType.questions, { cpf: debouncedCpf, id_draw: Draw.id, question_answer: userQuestions! })
								}
							>
								Enviar
							</Button>
						</div>
					)}
				</div>
			);
		}
		return;
	}

	async function postUserParticipation(drawType: string, data: PostTextForm | PostQuestionaryForm) {
		setIsLoadingPost(true);
		if (drawType === DrawType.text) {
			await api
				.post(`/person-draw/text`, data)
				.then(() => {
					toast.success(`Obrigado por participar. Boa sorte! 😄`);
					return setTimeout(async () => {
						await verifyIfAlreadyAnswered(Draw?.id!, debouncedCpf);
					}, 350);
				})
				.catch((err) => toast.error(err.response.data.message))
				.finally(() => setIsLoadingPost(false));
		} else if (drawType === DrawType.questions) {
			await api
				.post(`/person-draw/question`, data)
				.then(() => {
					toast.success(`Obrigado por participar. Boa sorte! 😄`);
					return setTimeout(async () => {
						await verifyIfAlreadyAnswered(Draw?.id!, debouncedCpf);
					}, 350);
				})
				.catch((err) => toast.error(err.response.data.message))
				.finally(() => setIsLoadingPost(false));
		} else return;
	}

	if (alreadyAnswered) {
		return <AlreadyAnsweredDraw hits={userHits} />;
	}

	if (drawHasEnded || timeIsOver || Draw?.type === DrawType.list) {
		return <DrawHasEnded />;
	}

	if (!hasStartedDraw && !drawHasEnded && !timeIsOver) {
		return <DrawHasNotStarted />;
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Page>
					<Template.Content className=" my-8 flex flex-col items-center space-y-12 bg-background px-4 ">
						<Clock
							minutesInput={minutesInput}
							hoursInput={hoursInput}
							daysInput={daysInput}
							handleInputChange={() => null}
							totalTimeForAnswersInSeconds={totalTimeForAnswersInSeconds}
							hasStartedDraw={hasStartedDraw}
							timePreset={DrawReleaseTimePreset.days}
							hasTimePreset
							onlyRenderTextMessage
						/>

						<div className="m-auto w-full sm:w-[80%] lg:w-[65%]">
							<Tabs
								disabledKeys={notFoundUser ? ['participation'] : []}
								selectedKey={selectedKey}
								onSelectionChange={(value) => setSelectedKey(value as Keys)}
								variant="underlined"
								color="primary"
								classNames={{
									base: 'w-full',
									tabList: 'gap-0 w-full relative p-0 border-b border-input',
									cursor: 'w-full border-b-1 rounded-none ',
									tab: 'h-10 w-full',
									tabContent: 'w-full group-data-[selected=true]:text-primary ',
								}}
								aria-label="Options"
							>
								<Tab
									key="identification"
									title={
										<div className="flex items-center gap-2">
											<LucideUser size={18} />
											<h1 className="text-sm font-medium">Identificação</h1>
										</div>
									}
								>
									{renderUserSubscriptionForm()}
								</Tab>
								<Tab
									key="participation"
									title={
										<div className="flex items-center gap-2">
											<LucideGift size={18} />
											<h1 className="text-sm font-medium">Participação</h1>
										</div>
									}
								>
									{renderDrawForm()}
								</Tab>
							</Tabs>

							<div data-hide={notFoundUser} className="flex w-full items-center data-[hide=true]:hidden ">
								{selectedKey === 'identification' && (
									<Button className="ml-auto" onClick={() => setSelectedKey('participation')} variant="emphasis">
										Continuar
										<LucideArrowRight size={20} />
									</Button>
								)}
							</div>
						</div>
					</Template.Content>
				</Template.Page>
			</Template.Root>

			<Modal backdrop="blur" shouldBlockScroll isOpen={modalEditUserData.isOpen} onOpenChange={modalEditUserData.onOpenChange}>
				<ModalContent className=" bg-background">
					<ModalHeader>Editar dados</ModalHeader>
					<ModalBody className="">
						<Label htmlFor="cpf" className="w-full">
							CPF
							<Input id="cpf" placeholder="Apenas números" max={11} value={formatCPF(userData.cpf)} disabled readOnly />
						</Label>

						<Label htmlFor="name" className="w-full">
							Nome
							<Input
								id="name"
								placeholder="Insira um nome..."
								autoFocus
								value={userData.name}
								required
								onChange={(e: ChangeEvent<HTMLInputElement>) => setUserData({ ...userData, name: e.target.value })}
							/>
						</Label>
						<Label htmlFor="phone" className="w-full">
							Nº Telefone
							<Input
								id="phone"
								placeholder="-- - --------"
								value={cleanPhoneNumber(userData.phone!)}
								required
								max={11}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setUserData({ ...userData, phone: cleanPhoneNumber(e.target.value!) ?? null })
								}
							/>
						</Label>
					</ModalBody>
					<ModalFooter>
						{isLoadingUserData ? (
							<CircularProgress className="self-end" />
						) : (
							<Button
								disabled={!userData.name.trim() || !userData.phone.length || userData.phone.length !== 11}
								onClick={() => handleVerifyUserAndCreate(userData, true)}
							>
								Finalizar
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal
				backdrop="blur"
				hideCloseButton
				isDismissable={false}
				isKeyboardDismissDisabled
				shouldBlockScroll
				isOpen={modalIsLoading.isOpen}
				onOpenChange={modalIsLoading.onOpenChange}
			>
				<ModalContent className=" bg-background">
					<ModalHeader>
						<h1 className="text-center">
							{isLoadingUserData
								? 'Verificando a autenticidade dos dados...'
								: isLoadingPost
									? 'Sua participação está sendo contabilizada...'
									: isLoadingUserParticipation && 'Estamos verificando sua participação'}
						</h1>
					</ModalHeader>
					<ModalBody className="my-6 space-y-4">
						<CircularProgress className="mx-auto" />
					</ModalBody>
				</ModalContent>
			</Modal>

			<ModalTermsOfUse isOpen={modalSeeTerms.isOpen} onOpenChange={modalSeeTerms.onOpenChange} />
		</Suspense>
	);
}
