import { LucideDice6, LucideDices } from 'lucide-react';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { ThemeToggle } from '../ui/theme-toggle';

interface TemplateNavProps extends HTMLAttributes<HTMLElement> {}
export default function TemplateNav({ className, children, ...rest }: TemplateNavProps) {
	return (
		<nav
			className={twMerge(
				'justify-beetween group sticky top-0 z-50 flex h-16 items-center gap-2 border-b border-input bg-background/80 p-2 backdrop-blur-sm sm:p-4',
				className,
			)}
			{...rest}
		>
			{children}
			<ThemeToggle />
			<h1 className="text-base sm:text-lg md:text-2xl">Pormade Sorteador</h1>
			<LucideDices size={22} className="text-primary transition-all duration-250 group-hover:rotate-180  group-hover:scale-0" />
			<LucideDice6
				size={22}
				className="scale-0 text-primary transition-all duration-250 group-hover:-translate-x-8 group-hover:rotate-180 group-hover:scale-100"
			/>
		</nav>
	);
}
