import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { DrawReleaseTimePreset } from '@/constants/draw/draw-release-time-preset';
import { motion } from 'framer-motion';
import { ChangeEvent, ReactNode } from 'react';
import { ClassNameValue, twMerge } from 'tailwind-merge';

interface ClockProps {
	totalTimeForAnswersInSeconds: number;
	hasTimePreset?: boolean;
	hasStartedDraw?: boolean;
	timePreset?: string;
	minutesInput?: string;
	hoursInput?: string;
	daysInput?: string;
	handleInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onlyRenderTextMessage?: boolean;
}

export function Clock({
	totalTimeForAnswersInSeconds,
	hasTimePreset,
	hasStartedDraw,
	timePreset,
	daysInput,
	handleInputChange,
	hoursInput,
	minutesInput,
	onlyRenderTextMessage,
}: ClockProps) {
	const { days, hours, minutes, seconds } = calculateTime(totalTimeForAnswersInSeconds);

	const defaultWrapperClassName: ClassNameValue = twMerge(
		hasTimePreset && timePreset === DrawReleaseTimePreset.days ? 'lg:flex-row' : 'gm:flex-row',
		'temporizer flex flex-col items-center justify-center gap-3 p-4 text-6xl font-normal transition-all duration-150 gm:text-7xl',
	);

	const defaultLabelClassName: ClassNameValue = twMerge(
		hasTimePreset && timePreset === DrawReleaseTimePreset.days ? 'lg:absolute' : 'gm:absolute',
		'w-full -bottom-16',
	);

	const renderTime = () => {
		if (hasTimePreset) {
			if (timePreset === DrawReleaseTimePreset.minutes) {
				return renderMinutesAndSeconds();
			} else if (timePreset === DrawReleaseTimePreset.hours) {
				return renderHoursMinutesAndSeconds();
			} else if (timePreset === DrawReleaseTimePreset.days) {
				return renderDaysHoursMinutesAndSeconds();
			}
		} else {
			return renderDaysHoursMinutesAndSeconds();
		}
	};

	const renderDaysHoursMinutesAndSeconds = () => {
		return (
			<motion.div variants={SlideFromTop} initial="initial" animate="animate" className={twMerge(defaultWrapperClassName, '')}>
				{renderTimePart(
					days,
					<Label htmlFor="days" className={twMerge(defaultLabelClassName)}>
						Dia(s)
						<Input type="number" min={0} max={30} name="days" id="days" value={daysInput} onChange={handleInputChange} />
					</Label>,
				)}
				{renderDivider()}
				{renderTimePart(
					hours,
					<Label htmlFor="hours" className={twMerge(defaultLabelClassName)}>
						Hora(s)
						<Input type="number" min={0} max={23} name="hours" id="hours" value={hoursInput} onChange={handleInputChange} />
					</Label>,
				)}
				{renderDivider()}
				{renderTimePart(
					minutes,
					<Label htmlFor="minutes" className={twMerge(defaultLabelClassName)}>
						Minuto(s)
						<Input type="number" min={0} max={59} name="minutes" id="minutes" value={minutesInput} onChange={handleInputChange} />
					</Label>,
				)}
				{renderDivider()}
				{renderTimePart(seconds)}
			</motion.div>
		);
	};

	const renderHoursMinutesAndSeconds = () => {
		return (
			<motion.div variants={SlideFromTop} initial="initial" animate="animate" className={twMerge(defaultWrapperClassName, '')}>
				{renderTimePart(
					hours,
					<Label htmlFor="hours" className={twMerge(defaultLabelClassName)}>
						Hora(s)
						<Input type="number" min={0} max={23} name="hours" id="hours" value={hoursInput} onChange={handleInputChange} />
					</Label>,
				)}
				{renderDivider()}
				{renderTimePart(
					minutes,
					<Label htmlFor="minutes" className={twMerge(defaultLabelClassName)}>
						Minuto(s)
						<Input type="number" min={0} max={59} name="minutes" id="minutes" value={minutesInput} onChange={handleInputChange} />
					</Label>,
				)}
				{renderDivider()}
				{renderTimePart(seconds)}
			</motion.div>
		);
	};

	const renderMinutesAndSeconds = () => {
		return (
			<motion.div variants={SlideFromTop} initial="initial" animate="animate" className={twMerge(defaultWrapperClassName, '')}>
				{renderTimePart(
					minutes,
					<Label htmlFor="minutes" className={twMerge(defaultLabelClassName)}>
						Minuto(s)
						<Input type="number" min={0} max={59} name="minutes" id="minutes" value={minutesInput} onChange={handleInputChange} />
					</Label>,
				)}
				{renderDivider()}
				{renderTimePart(seconds)}
			</motion.div>
		);
	};

	const renderTimePart = (time: string, inputElement?: ReactNode) => {
		return (
			<div className="relative flex flex-col gap-2">
				<motion.div
					variants={SlideFromTop}
					initial="initial"
					animate="animate"
					className="flex select-none items-center gap-2 transition-all duration-150"
				>
					<motion.div
						variants={SlideFromTopItem}
						className="flex h-24 w-20 items-center justify-center rounded-lg bg-muted hover:bg-muted/80 lg:w-[74px]"
					>
						{time[0]}
					</motion.div>
					<motion.div
						variants={SlideFromTopItem}
						className="flex h-24 w-20 items-center justify-center rounded-lg bg-muted hover:bg-muted/80 lg:w-[74px]"
					>
						{time[1]}
					</motion.div>
				</motion.div>
				{!hasStartedDraw && inputElement!}
			</div>
		);
	};

	const renderDivider = () => {
		return (
			<motion.div
				variants={SlideFromTop}
				initial="initial"
				animate="animate"
				className={twMerge(
					hasTimePreset && timePreset === DrawReleaseTimePreset.days ? 'lg:flex-col' : 'gm:flex-col',
					'flex flex-row items-center gap-4 ',
				)}
			>
				<motion.div variants={SlideFromTopItem} className="h-6 w-6 rounded-full bg-primary transition-all duration-150 hover:bg-primary/70" />
				<motion.div variants={SlideFromTopItem} className="h-6 w-6 rounded-full bg-primary transition-all duration-150 hover:bg-primary/70" />
			</motion.div>
		);
	};

	return (
		<div className="flex w-full flex-col gap-4">
			<motion.h1 data-hide={!timePreset} className="mx-auto select-none text-pretty text-center text-lg data-[hide=true]:hidden">
				Restam
				<motion.span variants={SlideFromTopItem} initial="initial" animate="animate">
					{' '}
					{days} dia(s),{' '}
				</motion.span>
				<motion.span variants={SlideFromTopItem} initial="initial" animate="animate">
					{hours} hora(s),{' '}
				</motion.span>
				<motion.span variants={SlideFromTopItem} initial="initial" animate="animate">
					{minutes} minuto(s) e{' '}
				</motion.span>
				<motion.span variants={SlideFromTopItem} initial="initial" animate="animate">
					{seconds} segundos{' '}
				</motion.span>
				para o <code className="text-primary">Prêmio</code> ser sorteado!
			</motion.h1>
			{!onlyRenderTextMessage && renderTime()}
		</div>
	);
}

export const calculateTime = (totalSeconds: number) => {
	const days = Math.floor(totalSeconds / (3600 * 24))
		.toString()
		.padStart(2, '0');
	const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600)
		.toString()
		.padStart(2, '0');
	const minutes = Math.floor((totalSeconds % 3600) / 60)
		.toString()
		.padStart(2, '0');
	const seconds = (totalSeconds % 60).toString().padStart(2, '0');
	return { days, hours, minutes, seconds };
};
