import LoadingPage from '@/components/loading-page';
import SocialMediaButtons from '@/components/social-media-buttons';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import useWindowSize from '@/hooks/use-window-size';
import { motion } from 'framer-motion';
import { LucidePartyPopper } from 'lucide-react';
import { Suspense, useEffect } from 'react';
import Confetti from 'react-confetti';

export default function AlreadyAnsweredDraw({ hits }: { hits?: number }) {
	const { width, height } = useWindowSize();
	const { theme, setTheme } = useTheme();

	useEffect(() => {
		if (theme === 'light') setTheme('dark');
	}, []);

	return (
		<Suspense fallback={<LoadingPage />}>
			<Confetti numberOfPieces={250} recycle={false} width={width} height={height} />
			<div className="fixed flex h-screen w-full flex-col items-center justify-center text-center ">
				<div className="z-50 flex flex-col items-center gap-4">
					<motion.div variants={SlideFromTop} initial="initial" animate="animate" className="flex flex-col items-center gap-2 ">
						<motion.h1 variants={SlideFromTopItem} className="text-xl sm:text-3xl">
							Você já respondeu a esse sorteio.
						</motion.h1>
						{hits && hits > 0 ? (
							<motion.h1 variants={SlideFromTopItem} className="flex items-center gap-1.5 text-lg font-normal">
								Parabéns pelo(s) {hits} acertos!
								<LucidePartyPopper className="stroke-1 text-primary" size={26} />,
							</motion.h1>
						) : null}
						<motion.h1 variants={SlideFromTopItem} className="text-base font-normal">
							Acompanhe-nos nas redes para ficar por dentro dos próximos.
						</motion.h1>
					</motion.div>
					<SocialMediaButtons />
				</div>
			</div>
		</Suspense>
	);
}
