import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface TemplatePageProps extends HTMLAttributes<HTMLDivElement> {}
export default function TemplatePage({ className, children, ...rest }: TemplatePageProps) {
	return (
		<main className={twMerge(' relative h-screen w-full overflow-auto', className)} {...rest}>
			{children}
		</main>
	);
}
