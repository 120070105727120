import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { SlideInFromLeft } from '@/constants/animations/slide-in-from-left';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { getHeadersAuthorizationWithMultipart } from '@/functions/get-headers-multipart-and-authorization';
import { getZodErrors } from '@/functions/get-zod-errors';
import { getDraw } from '@/functions/tanstack-query/draws/get-draw';
import { urlToFile } from '@/functions/url-to-file';
import { useAuth } from '@/hooks/use-auth';
import api from '@/lib/api';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { LucideMenu } from 'lucide-react';
import { FormEvent, Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';
import { Draw } from '../utils/interfaces';
import CreateDrawAsideActions from './components/aside-actions';
import NewDrawConfigurationsForm from './components/forms/new-draw-configurations-form';
import { DrawDisplay, DrawType } from './utils/interfaces';

export const DrawFormSchema = z.object({
	title: z.string({ required_error: 'Título é um campo obrigatório.' }).min(10, { message: 'Título deve ter no mínimo 10 caracteres.' }).trim(),
	description: z.string().min(10, { message: 'Descrição deve ter no mínimo 10 caracteres.' }).trim().optional(),
	files: z
		.array(z.union([z.string(), z.instanceof(File), z.instanceof(Blob), z.null()]))
		.refine(
			(value) => {
				if (!Array.isArray(value)) {
					return false;
				}
				for (const item of value) {
					if (item === null) {
						continue;
					} else if (!item || !(item instanceof File) || !(item instanceof Blob)) {
						return false;
					}
				}
				return true;
			},
			{ message: 'O valor deve ser um array contendo strings, instâncias de File, instâncias de Blob ou null.' },
		)
		.optional()
		.nullable(),
	prize: z.string({ required_error: 'Prêmio é um campo obrigatório.' }),
	display: z.enum(['wheel', 'box'], { required_error: 'Display é um campo obrigatório.' }),
	type: z.enum(['list', 'text', 'questions'], { required_error: 'Escolha o tipo do sorteio.' }),
});

const PartialDrawFormSchema = z.object({
	title: z.optional(z.string({ required_error: 'Título é um campo obrigatório.' }).min(10, { message: 'Título deve ter no mínimo 10 caracteres.' }).trim()),
	description: z.optional(z.string().min(10, { message: 'Descrição deve ter no mínimo 10 caracteres.' }).trim()),
	files: z
		.array(z.union([z.string(), z.instanceof(File), z.instanceof(Blob), z.null()]))
		.refine(
			(value) => {
				if (!Array.isArray(value)) {
					return false;
				}
				for (const item of value) {
					if (item === null) {
						continue;
					} else if (!item || !(item instanceof File) || !(item instanceof Blob)) {
						return false;
					}
				}
				return true;
			},
			{ message: 'O valor deve ser um array contendo strings, instâncias de File, instâncias de Blob ou null.' },
		)
		.optional()
		.nullable(),
	prize: z.optional(z.string({ required_error: 'Prêmio é um campo obrigatório.' })),
	display: z.optional(z.enum(['wheel', 'box'], { required_error: 'Display é um campo obrigatório.' })),
	type: z.optional(z.enum(['list', 'text', 'questions'], { required_error: 'Escolha o tipo do sorteio.' })),
	minimum_hits: z.optional(z.number()).nullable(),
});

export const EditDrawFormSchema = PartialDrawFormSchema;

export type DrawForm = z.infer<typeof DrawFormSchema>;
export type EditDrawForm = z.infer<typeof EditDrawFormSchema>;

export default function CreateDraw() {
	const { id } = useParams();
	const { user } = useAuth();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [hasChanges, setHasChanges] = useState(false);

	const { data: Draw } = useQuery({
		queryKey: ['get-draw-id', id],
		queryFn: () => getDraw(user?.token!, id!),
		enabled: !!user?.token && !!id,
	});

	useEffect(() => {
		const fetchFiles = async (files: string[]) => {
			if (!files) return [];
			const filePromises = files.map(async (fileUrl) => {
				const fileName = fileUrl.split('/').pop();
				return await urlToFile(fileUrl, fileName!);
			});
			return Promise.all(filePromises);
		};

		if (Draw) {
			setTitle(Draw.title);
			setDescription(Draw.description);
			setPrize(Draw.prize);
			setDisplay(Draw.display as DrawDisplay);
			setTypeDraw(Draw.type as DrawType);
			if (Draw.files && Draw.files.length > 0) {
				fetchFiles(Draw.files!).then(setFiles);
			}
		}

		setHasChanges(false);

		return;
	}, [Draw]);

	const [title, setTitle] = useState<string | undefined>('');
	const [description, setDescription] = useState<string | undefined>('');
	const [prize, setPrize] = useState<string | undefined>('');
	const [display, setDisplay] = useState<DrawDisplay>('wheel');
	const [typeDraw, setTypeDraw] = useState<DrawType | undefined>(undefined);
	const [files, setFiles] = useState<File[] | null>(null);

	const removeFile = useCallback((fileToRemove: File) => {
		setFiles((prevFiles) => {
			const newFiles = prevFiles?.filter((file) => file !== fileToRemove) || null;
			if (newFiles?.length !== prevFiles?.length) {
				setHasChanges(true);
			}
			return newFiles;
		});
	}, []);

	const onDropFiles = useCallback((droppedFiles: File[]) => {
		setFiles((prevFiles) => {
			const newFiles = prevFiles ? [...prevFiles, ...droppedFiles] : droppedFiles;
			setHasChanges(true);
			return newFiles;
		});
	}, []);

	const [contentModalQuest, setContentModalQuest] = useState<string | null>(null);
	const modalQuest = useDisclosure();

	async function handleCreateDraw(e: FormEvent) {
		e.preventDefault();
		const drawForm: DrawForm = {
			title: title?.trim()!,
			description: description?.trim()!,
			files: files ? files : null,
			display: display!,
			prize: prize!,
			type: typeDraw!,
		};
		try {
			const parsedDrawForm = await DrawFormSchema.parseAsync(drawForm);

			if (parsedDrawForm.files && parsedDrawForm.files?.length > 0) {
				const formData = new FormData();

				parsedDrawForm.files.map((file) => {
					return formData.append('files', file!);
				});
				formData.append('title', parsedDrawForm.title);
				if (parsedDrawForm.description) {
					formData.append('description', parsedDrawForm.description!);
				}
				formData.append('prize', parsedDrawForm.prize);
				formData.append('display', parsedDrawForm.display);
				formData.append('type', parsedDrawForm.type);

				await api
					.post<Draw, { data: string }>(`/draw`, formData, getHeadersAuthorizationWithMultipart(user?.token!))
					.then((res) => {
						toast.success('Sorteio criado com sucesso!');
						setContentModalQuest(res.data);
						return modalQuest.onOpen();
					})
					.catch((err) => toast.error(err.response.data.message));
			} else {
				await api
					.post<Draw, { data: string }>(`/draw`, parsedDrawForm, getHeadersAuthorization(user?.token!))
					.then((res) => {
						toast.success('Sorteio criado com sucesso!');
						setContentModalQuest(res.data);
						return modalQuest.onOpen();
					})
					.catch((err) => toast.error(err.response.data.message));
			}
		} catch (err) {
			getZodErrors(err);
		}
	}

	async function handleEditDraw(e: FormEvent) {
		e.preventDefault();
		const editDrawForm: EditDrawForm = {
			title: title?.trim()!,
			description: description?.trim()!,
			files: files ? files : null,
			display: display!,
			prize: prize!,
			type: typeDraw!,
		};
		try {
			const parsedEditedDrawForm = await EditDrawFormSchema.parseAsync(editDrawForm);

			if (parsedEditedDrawForm.files && parsedEditedDrawForm.files?.length > 0) {
				const formData = new FormData();

				parsedEditedDrawForm.files.map((file) => {
					return formData.append('files', file!);
				});
				formData.append('title', parsedEditedDrawForm.title!);
				if (parsedEditedDrawForm.description) {
					formData.append('description', parsedEditedDrawForm.description!);
				}
				formData.append('prize', parsedEditedDrawForm.prize!);
				formData.append('display', parsedEditedDrawForm.display!);
				formData.append('type', parsedEditedDrawForm.type!);

				await api
					.patch<Draw, { data: string }>(`/draw/${id}`, formData, getHeadersAuthorizationWithMultipart(user?.token!))
					.then(async (res) => {
						toast.success('Sorteio editado com sucesso!');
						setContentModalQuest(res.data);
						await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
						return modalQuest.onOpen();
					})
					.catch((err) => toast.error(err.response.data.message));
			} else {
				await api
					.patch<Draw, { data: string }>(`/draw/${id}`, parsedEditedDrawForm, getHeadersAuthorization(user?.token!))
					.then(async (res) => {
						toast.success('Sorteio editado com sucesso!');
						setContentModalQuest(res.data);
						await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
						return modalQuest.onOpen();
					})
					.catch((err) => toast.error(err.response.data.message));
			}
		} catch (err) {
			getZodErrors(err);
		}
	}

	function disableButton() {
		const hasEmptyFields = !title || !description || !prize || !display || !typeDraw;
		if (Draw && id) {
			const notMakeAnyChange =
				title === Draw.title && description === Draw.description && prize === Draw.prize && display === Draw.display && typeDraw === Draw.type;
			if ((notMakeAnyChange && !hasChanges) || hasEmptyFields) {
				return true;
			}
			return false;
		}
		return hasEmptyFields;
	}

	function renderForms() {
		return (
			<motion.form
				onSubmit={id && Draw ? handleEditDraw : handleCreateDraw}
				variants={SlideInFromLeft}
				initial="initial"
				animate="animate"
				exit={{ x: 75 }}
				transition={{ duration: 0.7 }}
				className="flex flex-1 flex-col space-y-8 bg-background pb-4 md:max-w-2xl"
			>
				<Header title={Draw ? Draw.title : 'Configurações'} description="Defina aqui as configurações do sorteio" showBreadCrumb />
				<NewDrawConfigurationsForm
					draw_id={id!}
					files={files}
					setFiles={setFiles}
					onDropFiles={onDropFiles}
					removeFile={removeFile}
					description={description}
					setDescription={setDescription}
					display={display}
					setDisplay={setDisplay}
					prize={prize}
					setPrize={setPrize}
					title={title}
					setTitle={setTitle}
					typeDraw={typeDraw}
					setTypeDraw={setTypeDraw}
					className="flex flex-col space-y-8 "
				/>

				<Button disabled={disableButton()} className="self-end" type="submit">
					{id && Draw ? 'Finalizar edição' : 'Salvar'}
				</Button>
			</motion.form>
		);
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Aside className="max-w-64">
					<CreateDrawAsideActions />
				</Template.Aside>
				<Template.Page>
					<Template.Nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon" className="md:hidden">
									<LucideMenu size={20} />
								</Button>
							</SheetTrigger>
							<SheetContent side="left" className="flex flex-col">
								<SheetHeader>
									<SheetTitle>Pormade Sorteador</SheetTitle>
								</SheetHeader>
								<CreateDrawAsideActions />
							</SheetContent>
						</Sheet>
					</Template.Nav>
					<Template.Content>{renderForms()}</Template.Content>
				</Template.Page>
			</Template.Root>

			<Modal
				backdrop="blur"
				shouldBlockScroll
				size={'3xl'}
				isOpen={modalQuest.isOpen}
				onOpenChange={modalQuest.onOpenChange}
				classNames={{
					base: 'bg-background',
					backdrop: 'bg-gradient-to-t from-background/50 to-background/70 ',
					closeButton: `z-50`,
				}}
			>
				<ModalContent className="rounded-md  bg-background p-4">
					<ModalHeader className="relative overflow-hidden p-1 text-lg">
						<h1>Deseja configurar como as pessoas irão participar?</h1>
					</ModalHeader>
					<ModalBody></ModalBody>
					<ModalFooter>
						<Button variant={'link'} onClick={() => modalQuest.onClose()}>
							Deixar pra depois
						</Button>
						<Button onClick={() => navigate(`/new-draw/participation-type/${contentModalQuest!}`)}>Quero!</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Suspense>
	);
}
