import modelBox from '@/assets/images/modelBoxDraw.png';
import modelWheel from '@/assets/images/modelWheelDraw.png';
import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { Textarea } from '@/components/ui/textarea';
import { SlideInFromLeft, SlideInFromLeftItem } from '@/constants/animations/slide-in-from-left';
import { DrawDisplay } from '@/constants/draw/draw-display';
import { DrawType } from '@/constants/draw/draw-types';
import exportData from '@/functions/export-data';
import { getDrawUrl } from '@/functions/get-draw-url';
import { getFormattedDatesDifference } from '@/functions/get-formatted-difference-between-dates';
import { getDraw } from '@/functions/tanstack-query/draws/get-draw';
import { useAuth } from '@/hooks/use-auth';
import api from '@/lib/api';
import { Checkbox, Image } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
import { motion } from 'framer-motion';
import { LucideAlertTriangle, LucideDownload, LucideEye, LucideEyeOff, LucideMenu, LucidePen } from 'lucide-react';
import { Suspense, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { twMerge } from 'tailwind-merge';
import DrawAsideActions from './components/aside-actions';
import DrawFiles from './components/draw-files';

dayjs.extend(relativeTime).locale(ptBR);

export default function Draw() {
	const { id } = useParams();
	const { user } = useAuth();
	const navigate = useNavigate();

	const { data: Draw } = useQuery({
		queryKey: ['get-draw-id', id],
		queryFn: () => getDraw(user?.token!, id!),
		enabled: !!user?.token && !!id,
	});

	const handleDownloadDrawReport = async (draw_id: string, draw_title: string) => {
		await api
			.get(`/draw/report-draw-pdf/${draw_id}`, {
				headers: {
					Authorization: `Bearer ${user?.token!}`,
				},
				responseType: 'blob',
			})
			.then(async (res) => {
				toast.success('Seu relatório está baixando!');
				await exportData(res.data, `Relatório Sorteio - ${draw_title}.pdf`, 'application/pdf');
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.response.data.message);
			});
	};

	const prizeIsMoney = !/[^\d]/.test(Draw?.prize!);

	function renderTypeDrawAuxMessage(type: string) {
		switch (type) {
			case DrawType.questions:
				return 'Você definiu esse sorteio como um questionário';
			case DrawType.list:
				return 'Você definiu esse sorteio como uma lista';
			default:
				return 'Você definiu esse sorteio como campo de texto';
		}
	}

	function renderStatusMessage() {
		if (Draw?.drawed_at) {
			return (
				<h1>
					<Link to={`/draw/result/${id}`} className="text-base font-normal text-primary underline underline-offset-2">
						Sorteado {dayjs().to(Draw.drawed_at) + ' - ' + dayjs(Draw.drawed_at).format('DD/MM/YYYY')}
					</Link>
				</h1>
			);
		} else if (Draw?.winners && Draw?.end_date) {
			return (
				<h1>
					<Link
						to={getDrawUrl(Draw?.type!, Draw?.id!)}
						className="flex items-center gap-1.5 text-base font-normal text-destructive underline underline-offset-2"
					>
						Sorteio não salvo! <LucideAlertTriangle className="stroke-1" />
					</Link>
				</h1>
			);
		} else {
			return (
				<h1>
					<Link to={getDrawUrl(Draw?.type!, Draw?.id!)} className="text-base font-normal text-amber-500 underline underline-offset-2">
						Ainda não sorteado
					</Link>
				</h1>
			);
		}
	}

	function renderList() {
		return (
			<motion.div variants={SlideInFromLeft} initial="initial" animate="animate" className="flex flex-col gap-4">
				<motion.h1 variants={SlideInFromLeftItem} className="text-xl">
					Sua lista:
				</motion.h1>
				<motion.div variants={SlideInFromLeftItem}>
					<Textarea className="min-h-36" value={Draw?.list?.list.join(', ')} readOnly />
				</motion.div>
			</motion.div>
		);
	}

	const [showAnswers, setShowAnswers] = useState(true);

	function renderQuestionary() {
		return (
			<div className="flex flex-col gap-4">
				{Draw && Draw.minimum_hits !== 0 && <h1>Mínimo de acertos: {Draw.minimum_hits}</h1>}
				{Draw &&
					Draw.questions?.map((question, questionIndex) => {
						const cardClassName = twMerge('relative my-4 w-full rounded-lg border p-4');

						return (
							<Card key={'question' + questionIndex} id={`question-${question.id}`} className={cardClassName}>
								{Draw.questions?.length !== 1 && <div className="absolute -top-4 left-4 bg-background px-2">{questionIndex + 1}.</div>}
								<CardHeader className="mb-2 gap-2 p-1 ">
									<h1 className="w-full overflow-hidden text-ellipsis text-base">{question.question}</h1>
								</CardHeader>
								<CardContent className="px-0">
									{question.answers &&
										question.answers.map((answer, answerIndex) => {
											const correctAnswerClassName = 'ring-primary';
											const incorrectAnswerClassName = 'ring-input';

											return (
												<div key={'answer' + answerIndex} className="mb-2 flex items-center gap-2">
													<Input
														type="text"
														value={answer.answer}
														className={twMerge(
															answer.correct && showAnswers ? correctAnswerClassName : incorrectAnswerClassName,
															' border-none ring-1 ring-offset-0 focus-visible:ring-input',
														)}
														placeholder={`Resposta ${answerIndex + 1}`}
														readOnly
													/>
													<Checkbox isSelected={answer.correct! && showAnswers} />
												</div>
											);
										})}
								</CardContent>
							</Card>
						);
					})}
			</div>
		);
	}

	function renderTextField() {
		return (
			<Label className="w-full">
				{Draw?.text?.label}
				<Textarea className="min-h-36" value={Draw?.text?.text} readOnly placeholder={Draw?.text?.text} />
				{Draw?.text?.minimum_words && <span className=" self-end text-xs font-normal">mínimo de {Draw.text.minimum_words} palavras</span>}
			</Label>
		);
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Aside className="max-w-64">
					<DrawAsideActions />
				</Template.Aside>
				<Template.Page>
					<Template.Nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon" className="md:hidden">
									<LucideMenu size={20} />
								</Button>
							</SheetTrigger>
							<SheetContent side="left" className="flex flex-col">
								<SheetHeader>
									<SheetTitle>Pormade Sorteador</SheetTitle>
								</SheetHeader>
								<DrawAsideActions />
							</SheetContent>
						</Sheet>
					</Template.Nav>
					<Template.Content className="space-y-4">
						<Header title={Draw?.title!} description={`Dados do sorteio - ${id}`} />
						<h1 className="flex items-center gap-1.5 text-lg">
							{renderTypeDrawAuxMessage(Draw?.type!)}
							<Button variant={'outline'} size={'icon'} onClick={() => navigate(`/new-draw/${id}`)}>
								<LucidePen size={18} />
							</Button>
						</h1>

						<Button className="w-full p-0 sm:w-auto" variant={`link`} onClick={() => handleDownloadDrawReport(id!, Draw?.title!)}>
							Baixar relatorio
							<LucideDownload size={18} />
						</Button>

						<p className="text-pretty text-lg font-normal text-muted-foreground">
							<span className="text-accent-foreground">Descrição: </span>
							{Draw?.description}
						</p>

						<h1>Display:</h1>
						{Draw?.display === DrawDisplay.box ? (
							<Image isZoomed width={250} height={200} alt="modelBox" src={modelBox} />
						) : (
							<Image isZoomed width={250} height={200} alt="modelWheel" src={modelWheel} />
						)}

						<h1 className=" text-lg">
							Prêmio: <code className="font-normal text-primary">{prizeIsMoney ? `R$ ${Draw?.prize}` : Draw?.prize}</code>
						</h1>

						<h1 className=" text-lg">
							Participação:{' '}
							<code className="font-normal text-muted-foreground">
								{Draw?.type === DrawType.questions
									? 'Usuário responde um Questionário'
									: Draw?.type === DrawType.text
										? 'Usuário faz um Comentário'
										: 'Sorteio pede uma Lista'}
							</code>
						</h1>

						{Draw && Draw?.winners! > 0 && (
							<h1 className="text-base font-normal text-primary">
								{Draw.winners} {Draw.winners === 1 ? 'ganhador' : 'ganhadores!'}
							</h1>
						)}

						{renderStatusMessage()}

						{Draw?.released_at && Draw?.end_date && (
							<h1 className="text-base font-normal text-muted-foreground">
								Liberado durante {getFormattedDatesDifference(Draw?.released_at, Draw?.end_date)} -{' '}
								{dayjs(Draw?.end_date).format('DD/MM/YYYY')}
							</h1>
						)}

						<div className=" flex flex-col text-muted-foreground sm:flex-row sm:gap-4">
							<span className="text-sm font-normal">
								Criado {dayjs().to(Draw?.created_at)} - {dayjs(Draw?.created_at).format('DD/MM/YYYY')}
							</span>

							{Draw?.updated_at && (
								<span className="text-sm font-normal">
									Ultima edição {dayjs().to(Draw?.updated_at)} - {dayjs(Draw?.updated_at).format('DD/MM/YYYY')}
								</span>
							)}
						</div>

						<div className="flex flex-col items-center gap-2 pb-8 sm:flex-row">
							<Button variant={'emphasis'} onClick={() => navigate(`/draw/result/${id}`)}>
								Clique aqui para ver os ganhadores
							</Button>
							<Button variant={'emphasis'} onClick={() => navigate(`/draw/attendees/${id}`)}>
								Clique aqui para ver os participantes
							</Button>
						</div>

						{Draw?.type === DrawType.questions && (
							<Button variant={'emphasis'} onClick={() => setShowAnswers((old) => !old)}>
								{showAnswers ? 'Ocultar respostas' : 'Mostrar respostas'}
								{showAnswers ? <LucideEyeOff size={18} /> : <LucideEye size={18} />}
							</Button>
						)}

						{Draw?.files && Draw?.files.length > 0 && (
							<div className="flex flex-col gap-2">
								<h1>Arquivos inseridos:</h1>
								<DrawFiles Draw={Draw} />
							</div>
						)}

						<h1 className="text-2xl font-semibold">{Draw?.title}</h1>
						<h1 className="text-base font-normal">{Draw?.description}</h1>

						{Draw?.type === DrawType.questions ? renderQuestionary() : Draw?.type === DrawType.text ? renderTextField() : renderList()}
					</Template.Content>
				</Template.Page>
			</Template.Root>
		</Suspense>
	);
}
