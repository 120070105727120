import { DrawType } from '@/constants/draw/draw-types';

export function renderTypeDrawAuxMessage(type: string) {
	switch (type) {
		case DrawType.questions:
			return <h1>Você definiu esse sorteio como um questionário</h1>;
		case DrawType.list:
			return <h1>Você definiu esse sorteio como uma lista</h1>;
		default:
			return <h1>Você definiu esse sorteio como campo de texto</h1>;
	}
}
