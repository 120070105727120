import devtoolsDetect from 'devtools-detect';
import { useEffect, useState } from 'react';

export function useDevToolsStatus() {
	const [isDevToolsOpen, setIsDevToolsOpen] = useState(devtoolsDetect.isOpen);

	useEffect(() => {
		const handleChange = (event: any) => {
			setIsDevToolsOpen(event.detail.isOpen);
			if (event.detail.isOpen) {
				window.close();
			}
		};

		const checkDevTools = () => {
			if (devtoolsDetect.isOpen) {
				setIsDevToolsOpen(true);
				window.close();
			} else {
				setIsDevToolsOpen(false);
			}
		};

		const intervalId = setInterval(checkDevTools, 1000);

		window.addEventListener('devtoolschange', handleChange);

		return () => {
			clearInterval(intervalId);
			window.removeEventListener('devtoolschange', handleChange);
		};
	}, []);

	return isDevToolsOpen;
}
