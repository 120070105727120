import { AnimatedText } from '@/components/animated-text';
import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideInFromLeft, SlideInFromLeftItem } from '@/constants/animations/slide-in-from-left';
import { DrawDisplay } from '@/constants/draw/draw-display';
import { DrawReleaseTimePreset } from '@/constants/draw/draw-release-time-preset';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { getDrawAttendees } from '@/functions/tanstack-query/draws/get-attendees';
import { getDraw } from '@/functions/tanstack-query/draws/get-draw';
import { getDrawWinners } from '@/functions/tanstack-query/draws/get-draw-winners';
import { useAuth } from '@/hooks/use-auth';
import useWindowSize from '@/hooks/use-window-size';
import api from '@/lib/api';
import { Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from '@nextui-org/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isBefore } from 'date-fns';
import { motion } from 'framer-motion';
import {
	LucideAlertTriangle,
	LucideChevronLeft,
	LucideClock,
	LucideHome,
	LucideMoon,
	LucidePartyPopper,
	LucideSave,
	LucideSettings,
	LucideSun,
} from 'lucide-react';
import { Suspense, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { twMerge } from 'tailwind-merge';
import ReOpenDraw from '../draw/components/reopen-draw';
import SpinningWheel from '../draw/components/spinning-wheel/spinning-wheel';
import Winners from '../draw/draw-result/components/winners';
import { DrawAttendee } from '../draw/utils/interfaces';

export default function DrawQuestions() {
	const { id } = useParams();
	const { user } = useAuth();
	const { setTheme, theme } = useTheme();
	const { width, height } = useWindowSize();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [searchParams, setSearchParams] = useSearchParams();

	const modalWinner = useDisclosure();
	const modalAlreadyDrawed = useDisclosure();
	const modalReOpenDraw = useDisclosure();

	const [winner, setWinner] = useState<DrawAttendee>({} as DrawAttendee);
	const [isSorting, setIsSorting] = useState(false);
	const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

	const drawCount = searchParams.get('draw-count') ?? 0;

	const handleChangeParams = (paramName: string, newValue: string) => {
		setSearchParams((oldSearchParams) => {
			const params = new URLSearchParams(oldSearchParams);
			params.set(paramName, newValue);
			return params;
		});
	};

	async function saveDrawCountInURL() {
		const currentValue = Number(searchParams.get('draw-count') ?? 0);
		const newValue = currentValue + 1;

		handleChangeParams('draw-count', String(newValue));
	}

	const { data: Draw } = useQuery({
		queryKey: ['get-draw-id', id],
		queryFn: () => getDraw(user?.token!, id!),
		enabled: !!user?.token && !!id,
	});

	useEffect(() => {
		if (Draw && Draw.end_date && isBefore(new Date(), Draw.end_date)) {
			navigate(`/draw/release/${id}`);
		}
	}, [Draw]);

	const { data: Attendees } = useQuery({
		queryKey: ['get-draw-attendees', id, Draw?.minimum_hits!],
		queryFn: () => getDrawAttendees(user?.token!, id!, Draw?.minimum_hits!),
		enabled: !!user?.token && !!id && !!Draw,
	});

	const { data: DrawWinners } = useQuery({
		queryKey: ['get-draw-winners', id],
		queryFn: () => getDrawWinners(user?.token!, id!),
		enabled: !!user?.token && !!id && !!Draw,
	});

	const prizeIsMoney = !/[^\d]/.test(Draw?.prize!);

	useEffect(() => {
		if (Draw && Draw.end_date && isBefore(new Date(), Draw.end_date)) {
			navigate(`/draw/release/${id}`);
		}
	}, [Draw]);

	const getRandomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

	const initialDelay = 60;
	const limit = 50;

	const [count, setCount] = useState(limit);
	const [delay, setDelay] = useState(initialDelay);
	const [progress, setProgress] = useState(0);

	const shouldRandomizeName = async () => {
		if (count < limit) {
			await getRandomAttendee();

			setCount(count + 1);
			switch (count) {
				case limit * 0.5:
					setDelay(delay * 2);
					break;
				case limit * 0.7:
					setDelay(delay * 1.5);
					break;
				case limit * 0.8:
					setDelay(delay * 1.2);
					break;
				default:
					break;
			}
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			setProgress((oldProgress) => Math.min(oldProgress + 3, 100));
			shouldRandomizeName();
		}, delay);

		if (count >= limit) {
			clearInterval(intervalId);
			setProgress(0);
		}

		return () => clearInterval(intervalId);
	}, [count, delay, shouldRandomizeName]);

	function rotate() {
		if (isSorting) return;

		const wheel = document.querySelector('.wheel') as HTMLElement | null;

		let newDeg = Math.ceil(Math.random() * 3600);
		if (wheel) {
			wheel.style.transform = 'rotate(' + newDeg + 'deg)';
			newDeg += Math.ceil(Math.random() * 3600);
		}
	}

	const verifyIfWinnerAlreadyExists = async (winners: DrawAttendee[], currentWinner: DrawAttendee) => {
		if (!winners) return false;
		const alreadyDrawed = winners.some((winner) => winner.id_person === currentWinner.id_person);

		if (!alreadyDrawed) currentWinner.winner = true;

		return alreadyDrawed;
	};

	const storeWinner = (winner: DrawAttendee, delay: number) => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}
		let timeout = setTimeout(async () => {
			setIsSorting(false);
			const newWinner: DrawAttendee = winner;
			await saveDrawCountInURL();

			const alreadyDrawed = await verifyIfWinnerAlreadyExists(DrawWinners!, newWinner);
			if (alreadyDrawed) return modalAlreadyDrawed.onOpen();

			await api
				.patch(`/draw/${id}/winner/${newWinner.id_person}`, null, getHeadersAuthorization(user?.token!))
				.then(async () => {
					toast.success('Vencedor salvo com sucesso!');
					return await queryClient.invalidateQueries({ queryKey: ['get-draw-winners'] });
				})
				.catch((err) => toast.error(err.response.data.message));

			let hasWin: boolean = true;
			if (Draw?.minimum_hits && newWinner.hits !== null) {
				hasWin = Draw.minimum_hits <= newWinner.hits;
			}
			setWinner({ ...newWinner, winner: hasWin });
			modalWinner.onOpen();
		}, delay);

		setSearchTimeout(timeout);
	};

	const getRandomAttendee = async () => {
		setIsSorting(true);
		setCount(0);
		setWinner({} as DrawAttendee);

		if (Attendees && Attendees.length > 0) {
			setDelay(initialDelay);

			const winner_position = getRandomInteger(0, Attendees.length - 1);
			const winner: DrawAttendee = Attendees[winner_position];

			setWinner({ ...winner, winner_position });
			storeWinner(winner, 500);
		}
	};

	const handleClickSpinning = async () => {
		if (Attendees && Attendees.length <= 1) return toast.warning('Não há itens suficientes para sortear.');

		rotate();
		getRandomAttendee();
	};

	async function handleSaveDraw() {
		await api
			.patch(`/draw/finish-draw/${id}`, null, getHeadersAuthorization(user?.token!))
			.then(async () => {
				toast.success('Sorteio finalizado com sucesso!');

				return await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
			})
			.catch((err) => toast.error(err.response.data.message));
	}

	const renderSettingsButton = () => {
		return (
			<DropdownMenu>
				<DropdownMenuTrigger className="absolute bottom-4 left-4">
					<Button variant={'emphasis'} className=" h-12 w-12 rounded-full p-0">
						<LucideSettings size={28} className="stroke-1 transition-all duration-500 group-hover:rotate-180" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start" alignOffset={4}>
					<DropdownMenuGroup>
						<DropdownMenuItem className="justify-between gap-2" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
							Tema <LucideSun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
							<LucideMoon className="h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
						</DropdownMenuItem>
						<DropdownMenuItem className="justify-between gap-2" onClick={modalReOpenDraw.onOpen}>
							Reabrir sorteio <LucideClock className="h-[1.2rem] w-[1.2rem] rotate-0 transition-all dark:-rotate-90 " />
						</DropdownMenuItem>
					</DropdownMenuGroup>
				</DropdownMenuContent>
			</DropdownMenu>
		);
	};

	const renderDrawDisplay = () => {
		if (!Draw) return;
		if (Draw.display === DrawDisplay.wheel) {
			return (
				<div className="my-8 flex h-full flex-col items-center justify-center gap-4">
					<SpinningWheel
						spinText={winner && winner.winner_position !== undefined ? String(winner.winner_position) : 'Girar'}
						isSorting={isSorting}
						progress={progress}
						onClick={handleClickSpinning}
					/>
				</div>
			);
		}
		return (
			<div className="my-8 flex h-full flex-col items-center justify-center gap-4">
				<div className="relative flex w-full items-center gap-2 sm:w-[80%]">
					<div className="flex h-24 w-full items-center justify-center overflow-hidden rounded-lg border-[#121212] bg-background p-4 shadow-inner-custom-light dark:border dark:shadow-inner-custom-dark">
						<h1 className="line-clamp-1 text-center text-2xl capitalize">{winner.person?.name}</h1>
					</div>
				</div>

				<Button className="w-full sm:w-auto" disabled={isSorting} size={'lg'} onClick={handleClickSpinning}>
					Sortear
					<LucidePartyPopper size={18} />
				</Button>
			</div>
		);
	};

	if (Draw?.drawed_at) navigate(`/draw/result/${id}`);

	return (
		<Suspense fallback={<LoadingPage />}>
			{winner && winner.winner && !isSorting && <Confetti numberOfPieces={250} recycle={false} width={width} height={height} />}
			<Template.Root>
				<Template.Page>
					<Template.Content>
						<Carousel>
							<CarouselContent className="h-full p-0">
								<CarouselItem className="h-full">
									<motion.div
										variants={SlideInFromLeft}
										initial="initial"
										animate="animate"
										exit={{ x: 75 }}
										transition={{ duration: 0.7 }}
										className="h-full space-y-4 overflow-hidden p-1"
									>
										<motion.div variants={SlideInFromLeftItem}>
											<Header title={Draw?.title! ?? 'Sorteio'} description={`sorteios realizados: ${drawCount}`} />
										</motion.div>
										<div className="flex flex-col gap-2">
											<Button variant={'outline'} className="h-9 w-9 p-0 hover:w-14" onClick={() => navigate('/dashboard')}>
												<LucideChevronLeft
													size={18}
													className="absolute translate-x-4 scale-0 transition-all duration-300 group-hover:-translate-x-3 group-hover:scale-100"
												/>
												<LucideHome size={18} className="transition-all duration-300 group-hover:translate-x-2" />
											</Button>
											{Attendees && (
												<motion.h1
													variants={SlideInFromLeftItem}
													className="flex items-center gap-2 text-sm font-normal text-secondary-700"
												>
													Total de participantes: {Attendees.length}
												</motion.h1>
											)}
											{Attendees && Attendees.length <= 1 && (
												<div className="flex flex-col gap-2">
													<motion.h1
														variants={SlideInFromLeftItem}
														className="flex items-center gap-2 text-sm font-normal text-destructive"
													>
														Não há participantes o suficiente para sortear <LucideAlertTriangle size={18} />
													</motion.h1>
													<motion.h1
														onClick={() => modalReOpenDraw.onOpen()}
														variants={SlideInFromLeftItem}
														className="cursor-pointer font-normal text-amber-500 underline underline-offset-2"
													>
														Liberar novamente
													</motion.h1>
												</div>
											)}
										</div>

										<motion.div variants={SlideInFromLeftItem}>{renderDrawDisplay()}</motion.div>

										{DrawWinners && DrawWinners.length > 0 && (
											<motion.div
												variants={SlideInFromLeftItem}
												className={twMerge(
													Draw?.display === DrawDisplay.wheel ? 'justify-center' : 'justify-center pt-8 md:justify-end',
													'flex flex-col items-center gap-2 sm:flex-row',
												)}
											>
												<CarouselNext className="w-full sm:w-auto" disabled={isSorting} variant={'emphasis'}>
													Ganhadores
												</CarouselNext>
												<Button className="w-full sm:w-auto" disabled={isSorting} onClick={() => handleSaveDraw()}>
													Salvar sorteio <LucideSave size={18} />
												</Button>
											</motion.div>
										)}
									</motion.div>
								</CarouselItem>
								<CarouselItem>
									<motion.div
										variants={SlideInFromLeft}
										initial="initial"
										animate="animate"
										exit={{ x: 75 }}
										transition={{ duration: 0.7 }}
										className="h-full space-y-4 overflow-hidden p-1"
									>
										<motion.div variants={SlideInFromLeftItem}>
											<Header title={Draw?.title! ?? 'Sorteio'} description={`sorteios realizados: ${drawCount}`} />
										</motion.div>
										<CarouselPrevious>Voltar ao sorteio</CarouselPrevious>
										<Winners Draw={Draw!} Winners={DrawWinners!} />
									</motion.div>
								</CarouselItem>
							</CarouselContent>
						</Carousel>

						{renderSettingsButton()}
					</Template.Content>
				</Template.Page>

				<Modal
					backdrop="opaque"
					shouldBlockScroll
					classNames={{
						base: 'bg-background',
						backdrop: 'bg-gradient-to-t from-background/30 to-background/50 ',
						closeButton: `z-50`,
					}}
					size={'3xl'}
					isOpen={modalWinner.isOpen}
					onOpenChange={modalWinner.onOpenChange}
				>
					<ModalContent className="rounded-md bg-background p-4">
						<ModalHeader>
							<h1 className="mx-auto text-2xl font-semibold capitalize">{winner.person?.name}</h1>
						</ModalHeader>
						<ModalBody>
							{!winner.winner ? (
								<AnimatedText texts={['Infelizmente', 'não', 'foi', 'dessa', 'vez']} className="text-xl" />
							) : (
								<AnimatedText
									texts={[
										'Parabéns',
										'você',
										'ganhou',
										<code className="text-primary">{prizeIsMoney ? `R$${Draw?.prize!}` : Draw?.prize!}</code>,
									]}
									className="text-xl"
								/>
							)}
							<AnimatedText
								texts={['Acertou', winner.hits === 0 ? 'nenhuma' : <h1 className="text-primary">{winner.hits}</h1>]}
								className="text-xl"
							/>
						</ModalBody>
					</ModalContent>
				</Modal>

				<Modal
					backdrop="blur"
					shouldBlockScroll
					classNames={{
						base: 'bg-background',
						backdrop: 'bg-gradient-to-t from-background/50 to-background/70 ',
						closeButton: `z-50`,
					}}
					size={'3xl'}
					isOpen={modalAlreadyDrawed.isOpen}
					onOpenChange={modalAlreadyDrawed.onOpenChange}
				>
					<ModalContent className="rounded-md bg-background p-4">
						<ModalHeader className="text-center">
							<h1 className="mx-auto text-center text-2xl">{winner.person?.name} já foi sorteado!</h1>
						</ModalHeader>
					</ModalContent>
				</Modal>

				<Modal
					backdrop="blur"
					shouldBlockScroll
					classNames={{
						base: 'bg-background',
						backdrop: 'bg-gradient-to-t from-background/50 to-background/70 ',
						closeButton: `z-50`,
					}}
					size={'3xl'}
					isOpen={modalReOpenDraw.isOpen}
					onOpenChange={modalReOpenDraw.onOpenChange}
				>
					<ModalContent className="rounded-md bg-background p-4">
						<ModalHeader>
							<h1 className="text-xl">Reabrir sorteio</h1>
						</ModalHeader>
						<ModalBody>
							<ReOpenDraw draw_id={id!} hide_this_options={[DrawReleaseTimePreset.immediately]} />
						</ModalBody>
					</ModalContent>
				</Modal>
			</Template.Root>
		</Suspense>
	);
}
