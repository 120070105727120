import { Button } from '@/components/ui/button';
import { DrawReleaseTimePreset } from '@/constants/draw/draw-release-time-preset';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { useAuth } from '@/hooks/use-auth';
import api from '@/lib/api';
import { Radio, RadioGroup } from '@nextui-org/react';
import { useQueryClient } from '@tanstack/react-query';
import { LucideRocket } from 'lucide-react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { Clock } from '../../release-draw/components/clock';

export default function ReOpenDraw({ draw_id, hide_this_options }: { draw_id: string; hide_this_options?: string[] }) {
	const { user } = useAuth();
	const queryClient = useQueryClient();

	const [searchParams, setSearchParams] = useSearchParams();

	const timePreset = searchParams.get('timePreset') ?? '';

	const updateURLParams = (key: string, value: string) => {
		setSearchParams((oldParams) => {
			const newParams = new URLSearchParams(oldParams.toString());
			newParams.set(key, value);
			return newParams;
		});
	};

	function resetTimeFields() {
		if (timePreset === DrawReleaseTimePreset.days) {
			setHoursInput('');
			setMinutesInput('');
		} else if (timePreset === DrawReleaseTimePreset.hours) {
			setDaysInput('');
			setMinutesInput('');
		} else if (timePreset === DrawReleaseTimePreset.minutes) {
			setDaysInput('');
			setHoursInput('');
		}
	}

	useEffect(() => {
		resetTimeFields();
	}, [timePreset]);

	const [totalTimeForAnswersInSeconds, setTotalTimeForAnswersInSeconds] = useState<number>(0);

	const [daysInput, setDaysInput] = useState('');
	const [hoursInput, setHoursInput] = useState('');
	const [minutesInput, setMinutesInput] = useState('');

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === DrawReleaseTimePreset.days) {
			setDaysInput(String(Math.min(Number(value), 30)));
		} else if (name === DrawReleaseTimePreset.hours) {
			setHoursInput(String(Math.min(Number(value), 23)));
		} else if (name === DrawReleaseTimePreset.minutes) {
			setMinutesInput(String(Math.min(Number(value), 59)));
		}
	};

	useEffect(() => {
		let totalSeconds = 0;
		if (daysInput !== '') {
			totalSeconds += parseInt(daysInput) * 24 * 60 * 60;
		}
		if (hoursInput !== '') {
			totalSeconds += parseInt(hoursInput) * 60 * 60;
		}
		if (minutesInput !== '') {
			totalSeconds += parseInt(minutesInput) * 60;
		}
		setTotalTimeForAnswersInSeconds(totalSeconds);
	}, [daysInput, hoursInput, minutesInput]);

	function disableReleaseButton() {
		if (!timePreset) return true;
		if (timePreset === DrawReleaseTimePreset.days && Number(daysInput) <= 0) return true;
		if (timePreset === DrawReleaseTimePreset.hours && Number(hoursInput) <= 0) return true;
		if (timePreset === DrawReleaseTimePreset.minutes && Number(minutesInput) <= 0) return true;
		return false;
	}

	function generateReleaseDate(): Promise<string> {
		return new Promise((resolve, reject) => {
			const currentDate = new Date();

			let releaseDate;

			if (timePreset !== DrawReleaseTimePreset.immediately) {
				releaseDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate() + Number(daysInput)!,
					currentDate.getHours() + Number(hoursInput)!,
					currentDate.getMinutes() + Number(minutesInput)!,
					currentDate.getSeconds(),
				);
			} else {
				releaseDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate(),
					currentDate.getHours(),
					currentDate.getMinutes(),
					currentDate.getSeconds() + 1,
				);
			}

			if (!isNaN(releaseDate.getTime())) {
				resolve(releaseDate.toISOString());
			} else {
				reject(new Error('Data inválida.'));
			}
		});
	}

	async function releaseDraw() {
		const releaseDate = await generateReleaseDate();

		await api
			.patch(`/draw/active/${draw_id}`, { end_date: releaseDate }, getHeadersAuthorization(user?.token!))
			.then(() => {
				toast.success(`Sorteio liberado com sucesso!`);
				return queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
			})
			.catch((err) => toast.error(err.response.data.message));
	}

	return (
		<div className="max-h-[450px] space-y-2 overflow-y-auto">
			<RadioGroup value={timePreset} onValueChange={(value) => updateURLParams('timePreset', value)}>
				Por quanto tempo?
				<Radio
					classNames={{ label: 'font-normal' }}
					value={DrawReleaseTimePreset.minutes}
					isDisabled={hide_this_options?.includes(DrawReleaseTimePreset.minutes)}
				>
					Minutos
				</Radio>
				<Radio
					classNames={{ label: 'font-normal' }}
					value={DrawReleaseTimePreset.hours}
					isDisabled={hide_this_options?.includes(DrawReleaseTimePreset.hours)}
				>
					Horas
				</Radio>
				<Radio
					classNames={{ label: 'font-normal' }}
					value={DrawReleaseTimePreset.days}
					isDisabled={hide_this_options?.includes(DrawReleaseTimePreset.days)}
				>
					Dias
				</Radio>
				<Radio
					classNames={{ label: 'font-normal' }}
					value={DrawReleaseTimePreset.immediately}
					isDisabled={hide_this_options?.includes(DrawReleaseTimePreset.immediately)}
				>
					Imediatamente
				</Radio>
			</RadioGroup>
			{timePreset !== DrawReleaseTimePreset.immediately && (
				<Clock
					minutesInput={minutesInput}
					hoursInput={hoursInput}
					daysInput={daysInput}
					handleInputChange={handleInputChange}
					totalTimeForAnswersInSeconds={totalTimeForAnswersInSeconds}
					timePreset={timePreset}
					hasStartedDraw={false}
					hasTimePreset
				/>
			)}

			<div className="flex py-16 data-[hide=true]:hidden" data-hide={!timePreset}>
				<Button disabled={disableReleaseButton()} onClick={async () => await releaseDraw()} className=" mx-auto sm:min-w-56">
					Liberar <LucideRocket size={18} />
				</Button>
			</div>
		</div>
	);
}
