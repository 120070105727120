import LoadingPage from '@/components/loading-page';
import Background404 from '@/components/ui/background-404';
import { Button } from '@/components/ui/button';
import { useTheme } from '@/components/ui/theme-provider';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotAccessConsolePage() {
	const navigate = useNavigate();
	const { theme, setTheme } = useTheme();

	useEffect(() => {
		if (theme === 'light') setTheme('dark');
	}, []);
	return (
		<Suspense fallback={<LoadingPage />}>
			<Background404>
				<div className="z-50 flex flex-col items-center gap-4 ">
					<h1 className="text-lg">Por questões de segurança, não permitimos acesso ao console do navegador. Desative-o para continuar</h1>
					<Button variant={'secondary'} size={'lg'} onClick={() => navigate(-1)}>
						Voltar onde eu estava
					</Button>
				</div>
			</Background404>
		</Suspense>
	);
}
