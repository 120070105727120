import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { ChangeEvent, Dispatch, HTMLAttributes, SetStateAction } from 'react';
import { twMerge } from 'tailwind-merge';

interface NewTextFieldFormProps extends HTMLAttributes<HTMLDivElement> {
	label: string;
	setLabel: Dispatch<SetStateAction<string>>;
	placeholder: string;
	setPlaceholder: Dispatch<SetStateAction<string>>;
	minimumWords: number | null;
	setMinimumWords: Dispatch<SetStateAction<number | null>>;
}
export default function NewTextFieldForm({
	label,
	setLabel,
	placeholder,
	setPlaceholder,
	minimumWords,
	setMinimumWords,
	className,
	...rest
}: NewTextFieldFormProps) {
	return (
		<div className={twMerge('flex flex-col gap-6', className)} {...rest}>
			<h1 className=" text-sm font-normal text-warning">Cuidado com caracteres especiais! Se tiver, não será salvo.</h1>
			<h1 className="text-sm font-medium">Usuário irá fazer um comentário / texto</h1>
			<Label htmlFor="labelField">
				Rótulo
				<Input
					placeholder="Mensagem que irá acima do campo de texto (Rótulo)"
					id="labelField"
					name="labelField"
					value={label}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
				/>
				<span className="text-sm font-normal">Mensagem que irá acima do campo de texto (Rótulo)</span>
			</Label>

			<Label htmlFor="textAreaField">
				{label && label !== '' ? label : 'Comentário'}
				<Textarea
					id="textAreaField"
					placeholder="Escreva aqui algum texto auxiliar para o campo de texto"
					name="textAreaField"
					value={placeholder}
					onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setPlaceholder(e.target.value)}
				/>
				<span className="text-sm font-normal">Escreva aqui algum texto auxiliar para o campo de texto</span>
			</Label>

			<Label>
				Mínimo de palavras (opcional)
				<Input type="number" value={minimumWords!} onChange={(e: ChangeEvent<HTMLInputElement>) => setMinimumWords(Number(e.target.value)!)} />
			</Label>
		</div>
	);
}
