import LoadingPage from '@/components/loading-page';
import { useAuth } from '@/hooks/use-auth';
import { useSessionExpiration } from '@/hooks/use-session-expiration';
import Login from '@/pages/login';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export const PrivateRoutes = () => {
	const { signed, isLoading, refreshToken, user } = useAuth();
	const { isSessionExpiring, timeRemaining } = useSessionExpiration(user?.token!);

	const modalIsSessionExpiring = useDisclosure();
	useEffect(() => {
		if (isSessionExpiring) return modalIsSessionExpiring.onOpen();
	}, [isSessionExpiring]);

	async function handleRefreshAndCloseModal() {
		await refreshToken(user?.token!);
		modalIsSessionExpiring.onClose();
		return setTimeout(() => {
			return window.location.reload();
		}, 500);
	}
	if (isLoading) return <LoadingPage />;
	if (!signed) return <Login />;

	return (
		<Suspense fallback={<LoadingPage />}>
			<Outlet />
			<Modal backdrop="blur" isOpen={modalIsSessionExpiring.isOpen} onOpenChange={modalIsSessionExpiring.onOpenChange}>
				<ModalContent>
					<ModalHeader>
						<h1>Sua sessão irá expirar</h1>
					</ModalHeader>
					<ModalBody>
						<p className="text-pretty">Tempo restante: {timeRemaining} segundos</p>
					</ModalBody>
					<ModalFooter>
						<Button onClick={handleRefreshAndCloseModal}>Manter conectado</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Suspense>
	);
};
