import axios from 'axios';

export const BaseURL = import.meta.env.VITE_BASE_URL;
export const FrontBaseURL = import.meta.env.VITE_FRONT_BASE_URL;

const api = axios.create({
	baseURL: BaseURL,
});

export default api;
