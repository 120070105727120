import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/use-auth';
import { Divider } from '@nextui-org/react';
import { LucideChevronLeft, LucideDoorClosed, LucideDoorOpen, LucideHome, LucideUser, LucideUsers } from 'lucide-react';
import { HTMLAttributes } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { version } from '../../package.json';

interface AsideProps extends HTMLAttributes<HTMLElement> {
	showHomeButton?: boolean;
}
export default function Aside({ children, className, showHomeButton = true, ...rest }: AsideProps) {
	const { signOut } = useAuth();
	const navigate = useNavigate();
	const local = useLocation().pathname;

	return (
		<aside className={twMerge('flex h-full flex-col justify-between gap-2', className)} {...rest}>
			{showHomeButton ? (
				<div className="flex flex-1 flex-col gap-3">
					<Button variant={'outline'} className="h-9 w-9 p-0 hover:w-14" onClick={() => navigate('/dashboard')}>
						<LucideChevronLeft
							size={18}
							className="absolute translate-x-4 scale-0 transition-all duration-300 group-hover:-translate-x-3 group-hover:scale-100"
						/>
						<LucideHome size={18} className="transition-all duration-300 group-hover:translate-x-2" />
					</Button>

					<Divider className="w-full" />
					{children}

					<Button onClick={() => navigate('/users')} variant={local.includes('users') ? 'secondary' : 'outline'} className="justify-between">
						<h1 className="line-clamp-1">Usuários</h1>
						<LucideUsers
							size={18}
							className="translate-x-0 scale-100 transition-all duration-300 group-hover:translate-x-4 group-hover:scale-0"
						/>
						<LucideUser
							size={18}
							className="absolute right-4 -translate-x-4 scale-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100"
						/>
					</Button>
				</div>
			) : (
				<div className="flex flex-1 flex-col gap-3">
					<Button onClick={() => navigate('/users')} variant={local.includes('users') ? 'secondary' : 'outline'} className="justify-between">
						<h1 className="line-clamp-1">Usuários</h1>
						<LucideUsers
							size={18}
							className="translate-x-0 scale-100 transition-all duration-300 group-hover:translate-x-4 group-hover:scale-0"
						/>
						<LucideUser
							size={18}
							className="absolute right-4 -translate-x-4 scale-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100"
						/>
					</Button>

					<Divider className="w-full" />

					{children}
				</div>
			)}

			<Button className="relative justify-between " onClick={signOut} variant={'outline'}>
				Sair
				<LucideDoorOpen className="absolute right-4 scale-0 transition-all duration-300 group-hover:scale-100" size={16} />
				<LucideDoorClosed className="absolute right-4 transition-all duration-300 group-hover:scale-0" size={16} />
			</Button>
			<h6 className="mx-auto text-xs font-light">{version}</h6>
		</aside>
	);
}
