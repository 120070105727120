import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useAuth } from './use-auth';

interface DecodedToken {
	exp: number;
}

interface UseSessionExpirationProps {
	isSessionExpiring: boolean;
	timeRemaining: number;
}

export const useSessionExpiration = (token: string | null): UseSessionExpirationProps => {
	const [isSessionExpiring, setIsSessionExpiring] = useState(false);
	const { signOut, maxAge } = useAuth();
	const [timeRemaining, setTimeRemaining] = useState(maxAge ?? 0);

	useEffect(() => {
		if (!token) {
			return;
		}

		const decodedToken: DecodedToken = jwtDecode(token);

		const calculateTimeRemaining = () => {
			const currentTime = Math.floor(Date.now() / 1000);
			const expirationTime = decodedToken.exp;
			const timeDifference = expirationTime - currentTime;

			if (timeDifference <= 5) {
				setIsSessionExpiring(true);
				setTimeRemaining(0);
				signOut();
			} else {
				setIsSessionExpiring(timeDifference <= 60);
				setTimeRemaining(timeDifference);
			}
		};

		calculateTimeRemaining();

		const intervalId = setInterval(calculateTimeRemaining, 1000);

		return () => clearInterval(intervalId);
	}, [token, signOut, maxAge]);

	return { isSessionExpiring, timeRemaining: timeRemaining! };
};
