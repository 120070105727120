import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime).locale(ptBR);

export function getMonthText(monthIndex: number) {
	const now = dayjs();

	const dateInput = dayjs().month(monthIndex - 1);

	if (dateInput.isSame(now, 'month')) {
		return 'Nesse mês';
	}

	if (dateInput.isSame(now.subtract(1, 'month'), 'month')) {
		return 'há um mês';
	}

	return dateInput.format('MMMM');
}
