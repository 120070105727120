import { Variants } from 'framer-motion';

export const SlideFromTop: Variants = {
	initial: {
		y: -75,
		opacity: 0,
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: {
			ease: 'easeIn',
			delayChildren: 0.4,
			staggerChildren: 0.2,
		},
	},
	exit: {
		y: 75,
		opacity: 0,
		transition: {
			ease: 'easeOut',
			delayChildren: 0.4,
			staggerChildren: 0.2,
		},
	},
};

export const SlideFromTopItem = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
};
