import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { ArrowDownIcon, ArrowUpIcon, CaretSortIcon, EyeNoneIcon } from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import { HTMLAttributes, ReactNode } from 'react';

interface UsersDataTableColumnHeaderProps<TData, TValue> extends HTMLAttributes<HTMLDivElement> {
	column: Column<TData, TValue>;
	title: string;
	icon?: ReactNode;
}

export function UsersDataTableColumnHeader<TData, TValue>({ column, title, icon, className }: UsersDataTableColumnHeaderProps<TData, TValue>) {
	return (
		<div className={cn('flex items-center space-x-2', className)}>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button variant="ghost" size="sm" className=" h-8 data-[state=open]:bg-accent">
						<span>{title}</span>
						{column.getCanSort() ? (
							column.getIsSorted() === 'desc' ? (
								<ArrowDownIcon className="ml-2 h-4 w-4" />
							) : column.getIsSorted() === 'asc' ? (
								<ArrowUpIcon className="ml-2 h-4 w-4" />
							) : (
								<CaretSortIcon className="ml-2 h-4 w-4" />
							)
						) : (
							icon && icon
						)}
					</Button>
				</DropdownMenuTrigger>
				{!column.getCanSort() && !column.getCanHide() ? null : (
					<DropdownMenuContent align="start">
						{column.getCanSort() && (
							<>
								<DropdownMenuItem onClick={() => column.toggleSorting(false)}>
									<ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
									Asc
								</DropdownMenuItem>
								<DropdownMenuItem onClick={() => column.toggleSorting(true)}>
									<ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
									Desc
								</DropdownMenuItem>
								<DropdownMenuSeparator />
							</>
						)}
						{column.getCanHide() && (
							<DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
								<EyeNoneIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
								Hide
							</DropdownMenuItem>
						)}
					</DropdownMenuContent>
				)}
			</DropdownMenu>
		</div>
	);
}
