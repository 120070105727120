import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import api from '@/lib/api';
import { DrawAttendee } from '@/pages/draw/utils/interfaces';

export async function getDrawWinners(token: string, draw_id: string, search?: string): Promise<DrawAttendee[]> {
	let params = `?`;
	if (search) params += `search=${search}&`;

	const res = await api.get(`/draw/winners-by-draw/${draw_id}${params}`, getHeadersAuthorization(token));
	return res.data;
}
