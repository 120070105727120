import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { SlideInFromLeft } from '@/constants/animations/slide-in-from-left';
import { motion } from 'framer-motion';
import { LucideMenu } from 'lucide-react';
import { Suspense } from 'react';
import UsersAsideActions from './components/aside-actions';
import TableUsers from './components/table';
import { UsersTableColumns } from './components/table/components/users-columns';

export default function Users() {
	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Aside className="max-w-64">
					<UsersAsideActions />
				</Template.Aside>
				<Template.Page>
					<Template.Nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon" className="md:hidden">
									<LucideMenu size={20} />
								</Button>
							</SheetTrigger>
							<SheetContent side="left" className="flex flex-col">
								<SheetHeader>
									<SheetTitle>Pormade Sorteador</SheetTitle>
								</SheetHeader>
								<UsersAsideActions />
							</SheetContent>
						</Sheet>
					</Template.Nav>
					<Template.Content>
						<motion.div
							variants={SlideInFromLeft}
							initial="initial"
							animate="animate"
							exit={{ x: 75 }}
							transition={{ duration: 0.7 }}
							className="flex flex-1 flex-col space-y-8 bg-background pb-4 "
						>
							<Header title="Usuários" description="Gerenciamento dos usuários" showBreadCrumb />

							<TableUsers columns={UsersTableColumns} />
						</motion.div>
					</Template.Content>
				</Template.Page>
			</Template.Root>
		</Suspense>
	);
}
