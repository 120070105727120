import { Image } from '@nextui-org/react';
import { twMerge } from 'tailwind-merge';

export function renderPreview(preview: string) {
	const extension = preview.split('.').pop()?.toLowerCase();
	let type = '';

	if (extension) {
		if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg+xml', 'webp'].includes(extension)) {
			type = 'image/*';
		} else if (['mp4', 'webm', 'ogg', 'mkv'].includes(extension)) {
			type = 'video/*';
		}
	}

	if (type === 'image/*') return <Image src={preview} isZoomed className={twMerge('w-full object-contain')} />;

	if (type === 'video/*') return <video src={preview} className={twMerge('w-full object-contain')} controls />;
}
