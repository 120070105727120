import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import api from '@/lib/api';
import { DrawAttendee } from '@/pages/draw/utils/interfaces';

export async function getDrawAttendees(token: string, draw_id: string, minimum_hits?: number, maximum_hits?: number, search?: string): Promise<DrawAttendee[]> {
	let params = ``;

	if (minimum_hits) params += `&minimum_hits=${minimum_hits}`;
	if (maximum_hits) params += `&maximum_hits=${maximum_hits}`;
	if (search) params += `&search=${search}`;

	const res = await api.get(`/draw/person-by-draw/${draw_id}?${params}`, getHeadersAuthorization(token));
	return res.data;
}
