import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import Background404 from '@/components/ui/background-404';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useTheme } from '@/components/ui/theme-provider';
import { cleanPhoneNumber } from '@/functions/clean-phone-number';
import { formatCPF } from '@/functions/format-cpf';
import { getZodErrors } from '@/functions/get-zod-errors';
import useDebounce from '@/hooks/use-debounce';
import api from '@/lib/api';
import { NewUserFormSchema, UserForm } from '@/pages/users/components/table/components/users-data-table-toolbar';
import { Checkbox, CircularProgress, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import { LucideCheckCircle } from 'lucide-react';
import { ChangeEvent, Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import ModalTermsOfUse from '../components/modal-terms-of-use';

export default function CreateUser() {
	const { theme, setTheme } = useTheme();
	const navigate = useNavigate();

	const modalSeeTerms = useDisclosure();
	const modalEditUserData = useDisclosure();
	const modalIsLoading = useDisclosure();

	const [isLoadingUserData, setIsLoadingUserData] = useState(false);

	useEffect(() => {
		if (theme === 'light') setTheme('dark');
	}, []);

	useEffect(() => {
		if (isLoadingUserData) return modalIsLoading.onOpen();
		return modalIsLoading.onClose();
	}, [isLoadingUserData]);

	const initialUserDataState: UserForm = {
		name: '',
		cpf: '',
		phone: '',
		accept_terms: true,
		id: '',
	};

	const [userData, setUserData] = useState<UserForm>(initialUserDataState);
	const [notFoundUser, setNotFoundUser] = useState(true);
	const debouncedCpf = useDebounce(userData.cpf, 1250);

	const fillUserData = async () => {
		setIsLoadingUserData(true);
		await api
			.get(`/person/${debouncedCpf}`)
			.then(async (res) => {
				setIsLoadingUserData(false);

				setNotFoundUser(false);
				return setUserData({
					cpf: debouncedCpf,
					name: res.data.name,
					phone: res.data.phone,
					id: res.data.id,
					accept_terms: true,
				});
			})
			.catch(() => {
				return setNotFoundUser(true);
			})
			.finally(() => {
				setIsLoadingUserData(false);
			});
	};

	useEffect(() => {
		if (debouncedCpf?.length === 11) {
			fillUserData();
		}
		if (debouncedCpf?.length !== 60 && debouncedCpf?.length !== 11) {
			setUserData({
				cpf: debouncedCpf,
				accept_terms: initialUserDataState.accept_terms,
				name: initialUserDataState.name,
				phone: initialUserDataState.phone,
			});
			setNotFoundUser(true);
		}
		return;
	}, [debouncedCpf]);

	const handleVerifyUserAndCreate = async (user_data: UserForm, is_editing: boolean) => {
		try {
			setIsLoadingUserData(true);
			const { id, ...userDataWithoutId } = user_data;

			const parsedUserForm = await NewUserFormSchema.parseAsync(userDataWithoutId);
			if (notFoundUser) {
				return await api
					.post(`/person`, parsedUserForm)
					.then(() => {
						toast.success(`Usuário criado com sucesso!`);
						setNotFoundUser(false);
						return setTimeout(async () => {
							return await fillUserData();
						}, 1500);
					})
					.catch((err) => toast.error(err.response.data.message))
					.finally(() => setIsLoadingUserData(false));
			}
			if (is_editing) {
				return await api
					.patch(`/person`, parsedUserForm)
					.then(() => {
						toast.success(`Usuário editado com sucesso!`);
						setNotFoundUser(false);
						return setTimeout(async () => {
							return await fillUserData();
						}, 1500);
					})
					.catch((err) => toast.error(err.response.data.message))
					.finally(() => setIsLoadingUserData(false));
			}
		} catch (error) {
			getZodErrors(error);
		}
	};

	function renderUserForm() {
		return (
			<div className=" flex w-full flex-col items-center justify-center gap-4 text-left sm:w-[75%]">
				<Label htmlFor="cpf" className="w-full">
					CPF
					<Input
						id="cpf"
						placeholder="Apenas números"
						max={11}
						value={formatCPF(userData.cpf)}
						required
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const formattedCPF = e.target.value.replace(/\D/g, '').slice(0, 11);
							setUserData({ ...userData, cpf: formattedCPF });
						}}
					/>
					<span className="text-sm font-normal text-muted-foreground">Para garantirmos confiabilidade dos dados!</span>
				</Label>
				{notFoundUser && (
					<div className="flex w-full flex-col items-center justify-between gap-4 ">
						<Label htmlFor="name" className="w-full">
							Nome completo
							<Input
								id="name"
								placeholder="Insira um nome..."
								autoFocus
								value={userData.name}
								required
								disabled={!notFoundUser || (notFoundUser && debouncedCpf.length === 0)}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setUserData({ ...userData, name: e.target.value })}
							/>
						</Label>
						<Label htmlFor="phone" className="w-full">
							Nº Telefone
							<Input
								id="phone"
								placeholder="-- - --------"
								value={cleanPhoneNumber(userData.phone!)}
								required
								disabled={!notFoundUser || (notFoundUser && debouncedCpf.length === 0)}
								max={11}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setUserData({ ...userData, phone: cleanPhoneNumber(e.target.value!) ?? null })
								}
							/>
							<span className="text-sm font-normal text-muted-foreground">É por onde entraremos em contato com o ganhador!</span>
						</Label>
						<div className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
							<div className="flex items-center gap-1">
								<Checkbox
									isSelected={userData.accept_terms!}
									onValueChange={(value) => setUserData({ ...userData, accept_terms: value })}
								/>
								<h1
									onClick={modalSeeTerms.onOpen}
									className="cursor-pointer text-sm font-normal text-muted-foreground underline underline-offset-1"
								>
									Concordo com os termos de uso
								</h1>
							</div>

							{isLoadingUserData ? (
								<CircularProgress className="justify-end self-end" />
							) : (
								<Button
									disabled={
										!userData.accept_terms ||
										!userData?.cpf ||
										!userData?.name ||
										!userData?.phone ||
										!userData.phone.length ||
										userData.phone.length !== 11
									}
									onClick={() => handleVerifyUserAndCreate(userData, false)}
									className="w-full sm:w-auto"
								>
									{userData.accept_terms ? 'Quero participar!' : 'Aceite os termos'}
								</Button>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}

	function renderUserData() {
		if (notFoundUser) return null;
		return (
			<Card className="flex flex-col items-center gap-4 border-none bg-transparent">
				<CardHeader className="p-0">
					<h1 className="flex items-center gap-2 text-2xl">
						Você já está cadastrado! <LucideCheckCircle className="text-primary" />
					</h1>
				</CardHeader>
				<CardContent>
					<Card className="bg-black">
						<CardHeader className="flex-col gap-2 text-start">
							<h1 className="text-2xl">{userData.name}</h1>
							<span className="font-normal text-muted-foreground">inscrição: {userData.id}</span>
						</CardHeader>
						<CardContent className="text-lg font-normal">
							Para ver os sorteios em que participou,{' '}
							<code
								className="cursor-pointer font-normal text-primary underline underline-offset-1"
								onClick={() => navigate(`/my-draws/${userData.id}/${userData.cpf}`)}
							>
								clique aqui
							</code>
						</CardContent>
						<CardFooter className="justify-start">
							<Button variant="emphasis" onClick={modalEditUserData.onOpen}>
								Editar meus dados
							</Button>
						</CardFooter>
					</Card>
				</CardContent>
			</Card>
		);
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Background404>
					<Template.Page>
						<Template.Content className="relative z-50 flex h-full w-full flex-col items-center justify-center">
							{!notFoundUser ? (
								renderUserData()
							) : (
								<>
									<div className="mb-2 w-full border-b pb-2 text-2xl sm:w-[75%]">
										<h1>Cadastre-se antecipadamente para agilizar sua participação em todos os sorteios!</h1>
										<span className="text-lg font-normal text-muted-foreground">
											Se já for cadastrado, insira seu cpf e confira todas as suas participações!
										</span>
									</div>
									{renderUserForm()}
								</>
							)}
						</Template.Content>
					</Template.Page>
				</Background404>
			</Template.Root>

			<Modal
				backdrop="blur"
				hideCloseButton
				isDismissable={false}
				isKeyboardDismissDisabled
				shouldBlockScroll
				isOpen={modalIsLoading.isOpen}
				onOpenChange={modalIsLoading.onOpenChange}
			>
				<ModalContent className=" bg-background">
					<ModalHeader>
						<h1 className="text-center">{isLoadingUserData && 'Verificando a autenticidade dos dados...'}</h1>
					</ModalHeader>
					<ModalBody className="my-6 space-y-4">
						<CircularProgress className="mx-auto" />
					</ModalBody>
				</ModalContent>
			</Modal>

			<Modal backdrop="blur" shouldBlockScroll isOpen={modalEditUserData.isOpen} onOpenChange={modalEditUserData.onOpenChange}>
				<ModalContent className=" bg-background">
					<ModalHeader>Editar dados</ModalHeader>
					<ModalBody className="">
						<Label htmlFor="cpf" className="w-full">
							CPF
							<Input id="cpf" placeholder="Apenas números" max={11} value={formatCPF(userData.cpf)} disabled readOnly />
						</Label>

						<Label htmlFor="name" className="w-full">
							Nome
							<Input
								id="name"
								placeholder="Insira um nome..."
								autoFocus
								value={userData.name}
								required
								onChange={(e: ChangeEvent<HTMLInputElement>) => setUserData({ ...userData, name: e.target.value })}
							/>
						</Label>
						<Label htmlFor="phone" className="w-full">
							Nº Telefone
							<Input
								id="phone"
								placeholder="-- - --------"
								value={cleanPhoneNumber(userData.phone!)}
								required
								max={11}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setUserData({ ...userData, phone: cleanPhoneNumber(e.target.value!) ?? null })
								}
							/>
						</Label>
					</ModalBody>
					<ModalFooter>
						{isLoadingUserData ? (
							<CircularProgress className="self-end" />
						) : (
							<Button
								disabled={!userData.name.trim() || !userData.phone.length || userData.phone.length !== 11}
								onClick={() => handleVerifyUserAndCreate(userData, true)}
							>
								Finalizar
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>

			<ModalTermsOfUse isOpen={modalSeeTerms.isOpen} onOpenChange={modalSeeTerms.onOpenChange} />
		</Suspense>
	);
}
