import modelBox from '@/assets/images/modelBoxDraw.png';
import modelWheel from '@/assets/images/modelWheelDraw.png';
import { DragAndDrop } from '@/components/drag-and-drop';
import Header from '@/components/header';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { Image, Radio, RadioGroup, Tab, Tabs } from '@nextui-org/react';
import { LucideCoins, LucideGift } from 'lucide-react';
import { ChangeEvent, Dispatch, HTMLAttributes, SetStateAction, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DrawDisplay, DrawType } from '../../utils/interfaces';

interface ConfigurationsFormProps extends HTMLAttributes<HTMLDivElement> {
	draw_id?: string;
	title?: string;
	setTitle: Dispatch<SetStateAction<string | undefined>>;
	description?: string;
	setDescription: Dispatch<SetStateAction<string | undefined>>;
	files: File[] | null;
	onDropFiles(file: File[]): void;
	removeFile(file: File): void;
	setFiles: Dispatch<SetStateAction<File[] | null>>;
	prize?: string;
	setPrize: Dispatch<SetStateAction<string | undefined>>;
	display?: DrawDisplay;
	setDisplay: Dispatch<SetStateAction<DrawDisplay>>;
	typeDraw?: DrawType;
	setTypeDraw: Dispatch<SetStateAction<DrawType | undefined>>;
}
export default function NewDrawConfigurationsForm({
	draw_id,
	description,
	display,
	files,
	onDropFiles,
	prize,
	removeFile,
	setDescription,
	setDisplay,
	setFiles,
	setPrize,
	setTitle,
	title,
	typeDraw,
	setTypeDraw,
	className,
	...rest
}: ConfigurationsFormProps) {
	const prizeIsMoney = !/[^\d]/.test(prize!);

	const [selectedTabKey, setSelectedTabKey] = useState('money');

	const onChangeSelectedTabKey = () => {
		if (prize) {
			if (prizeIsMoney) {
				return setSelectedTabKey('money');
			} else {
				return setSelectedTabKey('item');
			}
		}
		return setSelectedTabKey((old) => (old === 'money' ? 'item' : 'money'));
	};

	useEffect(() => {
		onChangeSelectedTabKey();
	}, [prizeIsMoney]);

	return (
		<div className={twMerge('', className)} {...rest}>
			<Label htmlFor="title">
				Título
				<Input
					id="title"
					name="title"
					placeholder="Titulo do sorteio"
					value={title}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
					required
				/>
			</Label>

			<Label htmlFor="description">
				Descrição
				<Textarea
					id="description"
					name="description"
					placeholder="Descricao do sorteio"
					className="min-h-36"
					value={description}
					onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
				/>
			</Label>

			<Label htmlFor="dropzone-file">
				<h1 className="flex gap-2">
					Imagem do sorteio <span className="font-light text-muted-foreground">(opcional)</span>
				</h1>
			</Label>
			<DragAndDrop mainType="Imagem" multiple files={files} maxFiles={5} onDropFiles={onDropFiles} removeFile={removeFile} />

			<Label>
				Premio
				<Tabs
					selectedKey={selectedTabKey}
					onSelectionChange={onChangeSelectedTabKey}
					variant="bordered"
					color="primary"
					classNames={{ tabList: `border-1 ` }}
					aria-label="Options"
				>
					<Tab
						key="money"
						title={
							<div className="flex items-center gap-2">
								<LucideCoins size={18} /> <h1 className="text-sm font-medium">Dinheiro</h1>
							</div>
						}
					>
						<Label htmlFor="value">
							Valor
							<Input
								placeholder="Valor do sorteio"
								type="text"
								min={1}
								name="value"
								id="value"
								autoComplete="value"
								required
								value={prize}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setPrize(String(e.target.value))}
							/>
						</Label>
					</Tab>
					<Tab
						key="item"
						title={
							<div className="flex items-center gap-2">
								<LucideGift size={18} />
								<h1 className="text-sm font-medium">Outro</h1>
							</div>
						}
					>
						<Label htmlFor="otherPrize">
							Outro premio
							<Textarea
								placeholder="Descreva o premio do sorteio"
								name="otherPrize"
								id="otherPrize"
								autoComplete="otherPrize"
								required
								value={prize}
								onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setPrize(e.target.value)}
							/>
						</Label>
					</Tab>
				</Tabs>
			</Label>

			<Header title="Aparencia" description="Defina aqui a aparencia do sorteio" />
			<Label htmlFor="display">
				Display
				<RadioGroup
					id="display"
					name="display"
					defaultValue="box"
					classNames={{ wrapper: `flex-row` }}
					value={display}
					onValueChange={(value: string) => setDisplay(value as DrawDisplay)}
				>
					<Radio value="box" description="Caixa">
						<Image isZoomed width={250} height={200} alt="modelBox" src={modelBox} />
					</Radio>
					<Radio value="wheel" description="Roda">
						<Image isZoomed width={250} height={200} alt="modelWheel" src={modelWheel} />
					</Radio>
				</RadioGroup>
			</Label>

			<Header title="Tipo" description="Defina aqui o tipo do sorteio" />
			<Select defaultValue="aux" autoComplete="true" value={typeDraw} onValueChange={(value: string) => setTypeDraw(value as DrawType)} required>
				<SelectTrigger>
					<SelectValue placeholder="Selecione um tipo de sorteio" />
				</SelectTrigger>
				<SelectContent>
					<SelectItem disabled value="aux">
						Defina um tipo de sorteio
					</SelectItem>
					<SelectItem value="questions">Questionario</SelectItem>
					<SelectItem value="text">Campo de texto</SelectItem>
					<SelectItem value="list">Lista de pessoas</SelectItem>
				</SelectContent>
			</Select>
		</div>
	);
}
