import LoadingPage from '@/components/loading-page';
import SocialMediaButtons from '@/components/social-media-buttons';
import Background404 from '@/components/ui/background-404';
import { Button } from '@/components/ui/button';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { motion } from 'framer-motion';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function DrawHasEnded() {
	const { theme, setTheme } = useTheme();
	const navigate = useNavigate();

	useEffect(() => {
		if (theme === 'light') setTheme('dark');
	}, []);
	return (
		<Suspense fallback={<LoadingPage />}>
			<Background404>
				<div className="relative z-50 flex h-full w-full flex-col items-center justify-center gap-4">
					<Button className="absolute right-4 top-4" onClick={() => navigate('/create-user')}>
						Quero me cadastrar
					</Button>
					<motion.div variants={SlideFromTop} initial="initial" animate="animate" className="flex flex-col gap-2 ">
						<motion.h1 variants={SlideFromTopItem} className="text-xl sm:text-3xl">
							O prazo para participar já acabou.
						</motion.h1>
						<motion.h1 variants={SlideFromTopItem} className="text-base font-normal">
							Acompanhe-nos nas redes para ficar por dentro dos próximos.
						</motion.h1>
					</motion.div>
					<SocialMediaButtons />
				</div>
			</Background404>
		</Suspense>
	);
}
