import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { SlideInFromLeft } from '@/constants/animations/slide-in-from-left';
import { DrawType } from '@/constants/draw/draw-types';
import { renderTypeDrawAuxMessage } from '@/functions/render-type-draw-message';
import { getDraw } from '@/functions/tanstack-query/draws/get-draw';
import { getDrawWinners } from '@/functions/tanstack-query/draws/get-draw-winners';
import { useAuth } from '@/hooks/use-auth';
import useDebounce from '@/hooks/use-debounce';
import { useQuery } from '@tanstack/react-query';
import { isBefore } from 'date-fns';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
import { motion } from 'framer-motion';
import { LucideMenu, LucideSearch } from 'lucide-react';
import { ChangeEvent, Suspense, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DrawResultAsideActions from './components/aside-actions';
import Winners from './components/winners';

dayjs.extend(relativeTime).locale(ptBR);

export default function DrawResult() {
	const { id } = useParams();
	const { user } = useAuth();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const winners_search_key = 'sw';
	const search = searchParams.get(winners_search_key) ?? '';
	const searchWDelay = useDebounce(search, 500);

	const { data: Draw } = useQuery({
		queryKey: ['get-draw-id', id],
		queryFn: () => getDraw(user?.token!, id!),
		enabled: !!user?.token && !!id,
	});

	const { data: DrawWinners } = useQuery({
		queryKey: ['get-draw-winners', id, searchWDelay],
		queryFn: () => getDrawWinners(user?.token!, id!, searchWDelay),
		enabled: !!user?.token && !!id && !!Draw,
	});

	async function handleSearch(e: ChangeEvent<HTMLInputElement>) {
		updateURLParams(winners_search_key, e.target.value);
	}

	const updateURLParams = (key: string, value: string) => {
		setSearchParams((oldParams) => {
			const newParams = new URLSearchParams(oldParams.toString());
			newParams.set(key, value);
			return newParams;
		});
	};

	useEffect(() => {
		if (Draw && Draw.end_date && isBefore(new Date(), Draw.end_date)) {
			navigate(`/draw/release/${id}`);
		}
		if (Draw && !Draw.drawed_at) {
			const redirectUrl =
				Draw.type === DrawType.list
					? `/draw/list/${Draw.id}`
					: Draw.type === DrawType.questions
						? `/draw/questions/${Draw.id}`
						: `/draw/text/${Draw.id}`;

			navigate(redirectUrl);
		}
	}, [Draw]);

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Aside className="max-w-64">
					<DrawResultAsideActions />
				</Template.Aside>
				<Template.Page>
					<Template.Nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon" className="md:hidden">
									<LucideMenu size={20} />
								</Button>
							</SheetTrigger>
							<SheetContent side="left" className="flex flex-col">
								<SheetHeader>
									<SheetTitle>Pormade Sorteador</SheetTitle>
								</SheetHeader>
								<DrawResultAsideActions />
							</SheetContent>
						</Sheet>
					</Template.Nav>
					<Template.Content>
						<motion.div
							variants={SlideInFromLeft}
							initial="initial"
							animate="animate"
							exit={{ x: 75 }}
							transition={{ duration: 0.7 }}
							className="flex flex-1 flex-col space-y-8 bg-background pb-4 "
						>
							<Header
								title={Draw?.title!}
								description={`Sorteado ${dayjs().to(Draw?.drawed_at) + ' - ' + dayjs(Draw?.drawed_at).format('DD/MM/YYYY')}`}
							/>

							{renderTypeDrawAuxMessage(Draw?.type!)}

							<h1 className="text-2xl">
								Sorteio do tipo{' '}
								<code className="text-primary">
									{Draw?.type === DrawType.list ? 'Lista' : Draw?.type === DrawType.questions ? 'Questionário' : 'Campo de texto'}
								</code>
							</h1>

							<Label htmlFor="search" className="relative">
								<LucideSearch size={18} className="absolute left-2.5 top-2" />
								<Input
									value={search}
									id="search"
									name="search"
									className="pl-8"
									placeholder="Pesquise ganhadores aqui"
									onChange={handleSearch}
								/>
							</Label>

							<Winners Draw={Draw!} Winners={DrawWinners!} />
						</motion.div>
					</Template.Content>
				</Template.Page>
			</Template.Root>
		</Suspense>
	);
}
