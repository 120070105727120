import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { readableSize } from '@/functions/readable-size';
import { Image } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { LucideTrash, LucideUpload } from 'lucide-react';
import { DropzoneState, useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';
import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';

export interface DragAndDropProps {
	formatAccepted?: { [key: string]: string[] };
	maxFiles?: number;
	mainType: string;
	files: File[] | null;
	multiple: boolean;
	onDropFiles(files: File[]): void;
	removeFile(file: File): void;

	showPreview?: boolean;
	classNameHasImage?: string;
	classNameWithoutImage?: string;
	classNameMessageMaxFiles?: string;
}

export function DragAndDrop({
	maxFiles,
	formatAccepted,
	mainType,
	files,
	multiple = false,
	onDropFiles,
	removeFile,
	classNameHasImage,
	classNameWithoutImage,
	classNameMessageMaxFiles,
}: DragAndDropProps) {
	const acceptThis = formatAccepted ?? {
		'image/*': ['.jpeg', '.jpg', '.png', '.webp'],
		'video/*': ['.mp4', '.mkv'],
	};

	const dropzone = useDropzone({
		onDrop: onDropFiles,
		accept: acceptThis,
		maxFiles: maxFiles ?? 1,
		multiple: multiple,
	});

	return (
		<motion.div variants={SlideFromTop} initial="initial" animate="animate">
			<InputDragAndDrop mainType={mainType} dropzone={dropzone} classNameWithoutImage={classNameWithoutImage} />

			{files && files.length !== 0 && (
				<ScrollArea className={twMerge(files.length > 4 ? 'h-[450px]' : 'h-[350px]', 'pr-3')}>
					{files.map((file) => {
						return (
							<motion.div
								key={file.name}
								variants={SlideFromTopItem}
								className={`${classNameHasImage} my-2 flex h-16 items-center justify-between gap-1.5  rounded-md bg-accent p-4 text-sm font-normal text-muted-foreground hover:bg-accent/80`}
							>
								{file.type === 'video/mp4' || file.type === 'video/mkv' ? (
									<video src={URL.createObjectURL(file)} className="h-12 w-40 rounded-md object-cover" />
								) : (
									<Image isZoomed src={URL.createObjectURL(file)} className="h-12 w-40 rounded-md object-cover" />
								)}
								<div className="flex flex-col items-start">
									<h1 className="line-clamp-1">{file.name}</h1>
									<h1 className="line-clamp-1 font-normal">{readableSize(file.size)}</h1>
								</div>
								<Button variant={'destructive'} onClick={() => removeFile(file)} className="min-h-9 min-w-9 p-0">
									<LucideTrash size={20} />
								</Button>
							</motion.div>
						);
					})}
				</ScrollArea>
			)}

			{maxFiles && (
				<motion.code
					variants={SlideFromTopItem}
					className={`${classNameMessageMaxFiles} mx-auto my-3 text-sm font-normal text-muted-foreground`}
				>{`Máximo de arquivos permitidos: ${maxFiles}`}</motion.code>
			)}
		</motion.div>
	);
}

export interface InputDragAndDropProps {
	dropzone: DropzoneState;
	mainType: string;

	classNameWithoutImage?: string;
}

export const InputDragAndDrop = ({ dropzone, ...props }: InputDragAndDropProps) => {
	const { getRootProps, getInputProps, isDragActive, isDragReject } = dropzone;

	return (
		<div
			{...getRootProps()}
			className={`${
				isDragActive ? 'border-primary text-primary' : 'border-muted text-muted-foreground'
			} group m-2 mx-auto h-52 w-full rounded-lg border-2 border-dashed transition-all duration-100 ${props.classNameWithoutImage}`}
		>
			<label onClick={(e) => e.preventDefault()} htmlFor="dropzone-file" className="cursor-pointer">
				<div className="flex h-full w-full flex-col items-center justify-center gap-2 rounded-md transition-all duration-100 group-hover:bg-muted/60">
					<LucideUpload size={36} />
					{isDragReject && (
						<p className="font-normal ">
							<span className="font-normal">Alguns arquivos serão rejeitados</span> (formato incompatível)
						</p>
					)}
					{isDragActive && !isDragReject ? (
						<code className="font-normal">Solte aqui</code>
					) : (
						<div className="flex flex-col items-center justify-center">
							<h1 className="font-normal ">
								<span className="font-normal text-muted-foreground">Clique para enviar</span> ou arraste até aqui
							</h1>
							<p className="font-normal text-muted-foreground">({props.mainType})</p>
						</div>
					)}
				</div>
			</label>
			<input {...getInputProps()} id="dropzone-file" name="dropzone" hidden />
		</div>
	);
};
