import { PormadeDrawDesktopDark } from '@/components/logos/pormade-draw-desktop-dark';
import { PormadeDrawDesktopLight } from '@/components/logos/pormade-draw-desktop-light';
import { PormadeDrawMobileDark } from '@/components/logos/pormade-draw-mobile-dark';
import { PormadeDrawMobileLight } from '@/components/logos/pormade-draw-mobile-light';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { LoginForm } from '@/contexts/auth-context';
import { useAuth } from '@/hooks/use-auth';
import useWindowSize from '@/hooks/use-window-size';
import { CircularProgress } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { LucideEye, LucideEyeOff } from 'lucide-react';
import { ChangeEvent, FormEvent, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import { version } from '../../../package.json';

export default function Login() {
	const { signIn, isLoading } = useAuth();
	const { theme } = useTheme();
	const { width, height } = useWindowSize();
	const navigate = useNavigate();

	const [user, setuser] = useState('');
	const [password, setPassword] = useState('');

	async function handleSubmit(e: FormEvent) {
		e.preventDefault();

		let dataForm: LoginForm = {
			user: user,
			password: password,
		};

		return await signIn(dataForm).then((res) => {
			if (res) {
				return navigate('/Dashboard');
			}
		});
	}

	const [isVisible, setIsVisible] = useState(false);

	function alterVisibility(event: any) {
		event.preventDefault();

		var input = document.querySelector('#password');

		if (input?.getAttribute('type') === 'password') {
			setIsVisible(true);
			input.setAttribute('type', 'text');
		} else {
			setIsVisible(false);
			input?.setAttribute('type', 'password');
		}
	}

	function renderAdminForm() {
		return (
			<motion.form
				variants={SlideFromTop}
				initial="initial"
				animate="animate"
				exit={{ x: 75 }}
				transition={{ duration: 0.7 }}
				onSubmit={handleSubmit}
				className=" flex w-full flex-col gap-4 text-center lg:p-4"
			>
				<motion.div variants={SlideFromTopItem}>
					<Label htmlFor="user" className="flex w-full flex-col gap-2 text-start font-medium  sm:gap-1 md:text-sm lg:text-base">
						Digite seu usuário
						<Input
							id="user"
							className="h-12 transition-all duration-150"
							autoComplete="off"
							type="text"
							name="userarea"
							placeholder="Digite seu usuário"
							value={user}
							onChange={(e: ChangeEvent<HTMLInputElement>) => setuser(e.target.value)}
						/>
					</Label>
				</motion.div>
				<motion.div variants={SlideFromTopItem}>
					<Label htmlFor="password" className="relative  flex w-full flex-col gap-2 text-start font-medium sm:gap-1 md:text-sm lg:text-base">
						Senha
						<div className="relative flex items-center ">
							<Input
								id="password"
								className="h-12 transition-all duration-150 "
								type="password"
								name="passwordarea"
								autoComplete="off"
								placeholder="Digite sua senha"
								maxLength={25}
								size={25}
								value={password}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
							/>
							<Button
								type="button"
								variant={'ghost'}
								onClick={alterVisibility}
								className="absolute right-2 flex items-center justify-center"
							>
								<LucideEyeOff data-hide={isVisible} className="data-[hide=true]:hidden" size={14} />
								<LucideEye data-hide={!isVisible} className="data-[hide=true]:hidden" size={14} />
							</Button>
						</div>
					</Label>
				</motion.div>

				<motion.div variants={SlideFromTopItem}>
					{isLoading ? (
						<CircularProgress className="mx-auto" />
					) : (
						<Button type="submit" className=" flex h-12 w-full items-center justify-center">
							Acessar
						</Button>
					)}
				</motion.div>
				<motion.h6 variants={SlideFromTopItem} className="mx-auto text-xs font-light">
					{version}
				</motion.h6>
			</motion.form>
		);
	}

	return (
		<main className="fixed h-full w-full bg-background ">
			<Confetti numberOfPieces={100} recycle={false} width={width} height={height} />
			<div className="absolute top-[25%] flex h-full w-full flex-col items-center justify-start p-3 lg:top-0 lg:flex-row lg:p-8">
				{theme === 'dark' ? (
					<div className="flex w-full items-end justify-center p-4 lg:items-center">
						<PormadeDrawDesktopDark className="hidden lg:block" />
						<PormadeDrawMobileDark className="block lg:hidden" />
					</div>
				) : (
					<div className="flex w-full items-end justify-center p-4 lg:items-center">
						<PormadeDrawDesktopLight className="hidden lg:block" />
						<PormadeDrawMobileLight className="block lg:hidden" />
					</div>
				)}
				<div className="flex h-full w-full items-start p-4 lg:items-center">{renderAdminForm()}</div>
			</div>
		</main>
	);
}
