import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideInFromLeft, SlideInFromLeftItem } from '@/constants/animations/slide-in-from-left';
import { getFormattedDatesDifference } from '@/functions/get-formatted-difference-between-dates';
import { DrawMonth, DrawYear, QueryGetAllPaginatedDraws, getAllPaginatedDraws } from '@/functions/tanstack-query/draws/get-all-paginated-draws';
import { getAttendeesReport } from '@/functions/tanstack-query/draws/get-attendees-report';
import { getDrawReport } from '@/functions/tanstack-query/draws/get-draw-report';
import { useAuth } from '@/hooks/use-auth';
import useMediaQuery from '@/hooks/use-media-query';
import { CircularProgress } from '@nextui-org/react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
import { motion } from 'framer-motion';
import {
	LucideBanknote,
	LucideCoins,
	LucideFilter,
	LucideGem,
	LucideGift,
	LucideMenu,
	LucidePartyPopper,
	LucidePieChart,
	LucideSparkles,
	LucideUser,
	LucideUsers,
} from 'lucide-react';
import { Fragment, ReactNode, Suspense, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { ClassNameValue, twMerge } from 'tailwind-merge';
import DrawCard from '../draw/components/draw-card';
import { Draw } from '../draw/utils/interfaces';
import DashboardAsideActions from './components/aside-actions';

dayjs.extend(relativeTime).locale(ptBR);

interface CardProps {
	header: ReactNode;
	content: ReactNode;
}
interface SectionProps {
	wrapperClassName: ClassNameValue;
	children: CardProps[];
}

interface DateRange {
	from: string;
	to: string;
}

interface ChartData {
	month: string;
	value: number;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#61FF7E', '#E05038'];

export default function Dashboard() {
	const { user } = useAuth();
	const { theme } = useTheme();
	const isMobile = useMediaQuery({ breakpoint: 640 });

	const initialQuantity: number = 30;

	const defaultSelectedRange = {
		from: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
		to: dayjs().add(1, 'day').format('YYYY-MM-DD'),
	};

	const defaultSelectedAttendeesReportRange = {
		from: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
		to: dayjs().add(1, 'day').format('YYYY-MM-DD'),
	};

	const page = 1;
	const [quantity] = useState<number>(initialQuantity);
	const [range, setRange] = useState<DateRange | undefined>(defaultSelectedRange);
	const [rangeAttendeesReport, setRangeAttendeesReport] = useState<DateRange | undefined>(defaultSelectedAttendeesReportRange);

	const { data: Report } = useQuery({
		queryKey: ['get-draw-report', range?.from, range?.to],
		queryFn: () => getDrawReport(user?.token!, range?.from!, range?.to!),
		enabled: !!user?.token,
	});

	const [chartData, setChartData] = useState<ChartData[] | null>(null);
	const { data: ReportLastAttendees } = useQuery({
		queryKey: ['get-attendees-report', rangeAttendeesReport?.from, rangeAttendeesReport?.to],
		queryFn: () => getAttendeesReport(user?.token!, rangeAttendeesReport?.from!, rangeAttendeesReport?.to!),
		enabled: !!user?.token,
	});

	useEffect(() => {
		if (!ReportLastAttendees) return;
		setChartData(
			ReportLastAttendees.map((report) => ({ month: dayjs(report.month).add(1, 'day').format('MMMM-YYYY').toString(), value: report.participants })),
		);
	}, [ReportLastAttendees]);

	const {
		data: Draws,
		status,
		isLoading,
		hasNextPage,
		error,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['get-all-draws', page, quantity, range],
		queryFn: ({ pageParam }) => getAllPaginatedDraws(user?.token!, pageParam, quantity, undefined, range?.from!, range?.to!),
		initialPageParam: page,
		getNextPageParam: ({ data }) => {
			const nextPage = data.total_pages > data.current_page ? data.current_page + 1 : undefined;
			return nextPage;
		},
		enabled: !!user?.token,
	});

	const { ref, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage && !isLoading && !error) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isLoading, error, fetchNextPage]);

	const renderDraws = useMemo(() => {
		if (status === 'pending') {
			return <CircularProgress className="mx-auto" />;
		} else if (status === 'error') {
			return (
				<h1 className="mx-auto my-2 flex w-full items-center justify-center gap-1.5 text-center text-lg font-medium text-muted-foreground">
					Falha ao carregar dados -{' '}
					<span className="text-sm underline" onClick={() => window.location.reload()}>
						Recarregar
					</span>{' '}
				</h1>
			);
		} else {
			return (
				<div className="mb-10 space-y-4 overflow-x-hidden pr-3">
					{Draws &&
						Draws.pages.map((page: QueryGetAllPaginatedDraws, index: number) => {
							return (
								<Fragment key={index}>
									{page.data.results && page.data.results.length > 0 ? (
										page.data.results.map((year: DrawYear, yearIndex: number) => (
											<div key={yearIndex + 'year'} className="flex flex-col gap-2">
												{year.months.map((month: DrawMonth, monthIndex: number) => (
													<motion.div
														variants={SlideInFromLeft}
														initial="initial"
														animate="animate"
														key={monthIndex + 'month'}
														className="space-y-2"
													>
														<div className=" space-y-3">
															{month.draws.map((draw: Draw, drawIndex: number) => {
																const prizeIsMoney = !/[^\d]/.test(draw.prize);
																return (
																	<motion.div key={drawIndex + 'draw'} variants={SlideInFromLeftItem}>
																		<DrawCard draw={draw} prizeIsMoney={prizeIsMoney} />
																	</motion.div>
																);
															})}
														</div>
													</motion.div>
												))}
											</div>
										))
									) : (
										<div className="mt-4 flex w-full justify-center text-2xl text-muted-foreground">
											<h1 className="mx-auto font-normal">Sem mais registros</h1>
										</div>
									)}
								</Fragment>
							);
						})}
					<div ref={ref} id="intersectionObserver" />
				</div>
			);
		}
	}, [status, Draws]);

	const [activeChartIndex, setActiveChartIndex] = useState(0);

	const renderActiveShape = (props: any) => {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		return (
			<g>
				<Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius! + 4}
					outerRadius={outerRadius! + 8}
					fill={fill}
				/>
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} />
				<circle cx={ex} cy={ey} r={2} fill={fill} />
				<text
					x={ex + (cos >= 0 ? 1 : -1) * 12}
					y={ey}
					textAnchor={textAnchor}
					fill={theme === 'dark' ? 'white' : 'black'}
				>{`${value} participantes`}</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill={fill}>
					{payload.month}
				</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={34} textAnchor={textAnchor} fill={theme === 'dark' ? 'white' : 'black'}>
					{`( ${(percent * 100).toFixed(2)}%)`}
				</text>
			</g>
		);
	};

	const cardsDashboard: SectionProps[] = [
		{
			wrapperClassName: 'grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4',
			children: [
				{
					header: (
						<CardHeader className="items-center justify-between text-base ">
							<CardTitle>Sorteios realizados</CardTitle>
							<CardDescription>
								<LucideGift
									size={20}
									className="translate-x-0 scale-100 text-primary transition-all duration-300 group-hover:translate-x-4 group-hover:scale-0"
								/>
								<LucideGem
									size={20}
									className="absolute right-5 top-6 -translate-x-4 scale-0 text-primary transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100"
								/>
							</CardDescription>
						</CardHeader>
					),
					content: (
						<CardContent>
							<CardTitle className="text-2xl">{Report?.draws_held ?? '0'} Sorteios</CardTitle>
							<CardDescription>{getFormattedDatesDifference(range?.from!, range?.to!, true)}</CardDescription>
						</CardContent>
					),
				},
				{
					header: (
						<CardHeader className="items-center justify-between text-base ">
							<CardTitle>Valor total sorteado</CardTitle>
							<CardDescription>
								<LucideCoins
									size={20}
									className="translate-x-0 scale-100 text-primary transition-all duration-300 group-hover:translate-x-4 group-hover:scale-0"
								/>
								<LucideBanknote
									size={20}
									className="absolute right-5 top-6 -translate-x-4 scale-0 text-primary transition-all duration-300 group-hover:translate-x-0 group-hover:rotate-[175deg] group-hover:scale-100"
								/>
							</CardDescription>
						</CardHeader>
					),
					content: (
						<CardContent>
							<CardTitle className="text-2xl">R$ {Report?.total_amount_drawn ?? '0'},00</CardTitle>
							<CardDescription>{getFormattedDatesDifference(range?.from!, range?.to!, true)}</CardDescription>
						</CardContent>
					),
				},
				{
					header: (
						<CardHeader className="items-center justify-between text-base ">
							<CardTitle>№ Participantes</CardTitle>
							<CardDescription>
								<LucideUsers
									size={20}
									className="absolute right-5 top-6 -translate-x-4 scale-0 text-primary transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100"
								/>
								<LucideUser
									size={20}
									className="translate-x-0 scale-100 text-primary transition-all duration-300 group-hover:translate-x-4 group-hover:scale-0"
								/>
							</CardDescription>
						</CardHeader>
					),
					content: (
						<CardContent>
							<CardTitle className="text-2xl">{Report?.participants ?? '0'} Pessoas</CardTitle>
							<CardDescription>{getFormattedDatesDifference(range?.from!, range?.to!, true)}</CardDescription>
						</CardContent>
					),
				},
				{
					header: (
						<CardHeader className="items-center justify-between text-base ">
							<CardTitle>№ Pessoas sorteadas</CardTitle>
							<CardDescription>
								<LucidePartyPopper
									size={20}
									className="translate-x-0 scale-100 text-primary transition-all duration-300 group-hover:translate-x-4 group-hover:scale-0"
								/>
								<LucideSparkles
									size={20}
									className="absolute right-5 top-6 -translate-x-4 scale-0 text-primary transition-all duration-300 group-hover:translate-x-0 group-hover:rotate-[280deg] group-hover:scale-100"
								/>
							</CardDescription>
						</CardHeader>
					),
					content: (
						<CardContent>
							<CardTitle className="text-2xl">{Report?.winners ?? '0'} Pessoas</CardTitle>
							<CardDescription>{getFormattedDatesDifference(range?.from!, range?.to!, true)}</CardDescription>
						</CardContent>
					),
				},
			],
		},
		{
			wrapperClassName: 'grid grid-cols-1 gap-3 xl:grid-cols-2',
			children: [
				{
					header: (
						<CardHeader className="justify-between text-left text-base ">
							<div className="flex flex-col gap-2 text-left">
								<CardTitle className="flex items-center gap-2">
									Participacao total{' '}
									<DropdownMenu>
										<DropdownMenuTrigger>
											<Button size={'icon'} variant={'outline'}>
												<LucideFilter size={18} />
											</Button>
										</DropdownMenuTrigger>
										<DropdownMenuContent>
											<DropdownMenuGroup className="max-h-[150px] overflow-y-auto">
												{Array.from([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]).map((item) => {
													return (
														<DropdownMenuItem
															key={item + 'PieChartDropdownMenuItem'}
															onClick={() =>
																setRangeAttendeesReport(
																	(old) =>
																		old && {
																			...old,
																			from: dayjs()
																				.subtract(item + 1, 'month')
																				.format('YYYY-MM-DD'),
																		},
																)
															}
														>
															Há {item + 1} {item + 1 === 1 ? 'Mês' : 'Meses'}
														</DropdownMenuItem>
													);
												})}
											</DropdownMenuGroup>
										</DropdownMenuContent>
									</DropdownMenu>
								</CardTitle>
							</div>
							<LucidePieChart
								size={20}
								className="rotate-0 scale-100 text-primary transition-all duration-300 group-hover:rotate-[270deg] "
							/>
						</CardHeader>
					),
					content: (
						<CardContent>
							<div className="h-[400px] w-full overflow-auto">
								<ResponsiveContainer width={!isMobile ? '100%' : 600} height={400}>
									<PieChart>
										<Pie
											activeIndex={activeChartIndex}
											activeShape={renderActiveShape}
											data={chartData!}
											cx="50%"
											cy="50%"
											innerRadius={60}
											outerRadius={80}
											dataKey="value"
											onMouseEnter={(_, index) => setActiveChartIndex(index)}
										>
											{chartData &&
												chartData.map((_, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
										</Pie>
									</PieChart>
								</ResponsiveContainer>
							</div>

							<CardDescription>{getFormattedDatesDifference(rangeAttendeesReport?.from!, rangeAttendeesReport?.to!)}</CardDescription>
						</CardContent>
					),
				},
				{
					header: (
						<CardHeader className=" flex-col text-left text-base ">
							<CardTitle>Ultimos sorteios c/ participantes</CardTitle>
						</CardHeader>
					),
					content: (
						<CardContent>
							<ScrollArea className=" h-[450px]">{renderDraws}</ScrollArea>
							<CardDescription className="mt-2">{getFormattedDatesDifference(range?.from!, range?.to!, true)}</CardDescription>
						</CardContent>
					),
				},
			],
		},
	];

	function renderCardsDashboard() {
		return cardsDashboard.map((section, index) => (
			<Fragment key={index}>
				<div className={twMerge('', section.wrapperClassName)}>
					{section.children.map((card, cardIndex) => (
						<Card key={cardIndex} className="group relative flex-1">
							{card.header}
							{card.content}
						</Card>
					))}
				</div>
			</Fragment>
		));
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Aside className="max-w-64">
					<DashboardAsideActions />
				</Template.Aside>
				<Template.Page>
					<Template.Nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon" className="md:hidden">
									<LucideMenu size={20} />
								</Button>
							</SheetTrigger>
							<SheetContent side="left" className="flex flex-col">
								<SheetHeader>
									<SheetTitle>Pormade Sorteador</SheetTitle>
								</SheetHeader>
								<DashboardAsideActions />
							</SheetContent>
						</Sheet>
					</Template.Nav>
					<Template.Content>
						<motion.div
							variants={SlideInFromLeft}
							initial="initial"
							animate="animate"
							exit={{ x: 75 }}
							transition={{ duration: 0.7 }}
							className="flex flex-1 flex-col justify-between gap-3"
						>
							<div className="flex items-center justify-start gap-2">
								<h1 className="text-lg font-semibold md:text-3xl">Dashboard</h1>
								<DropdownMenu>
									<DropdownMenuTrigger>
										<Button size={'icon'} variant={'outline'}>
											<LucideFilter size={18} />
										</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent>
										<DropdownMenuGroup>
											<DropdownMenuItem
												onClick={() =>
													setRange((old) => old && { ...old, from: dayjs().subtract(1, 'days').format('YYYY-MM-DD') })
												}
											>
												Há 1 Dia
											</DropdownMenuItem>
											<DropdownMenuItem
												onClick={() =>
													setRange((old) => old && { ...old, from: dayjs().subtract(7, 'days').format('YYYY-MM-DD') })
												}
											>
												Há 7 Dias
											</DropdownMenuItem>
											<DropdownMenuItem
												onClick={() =>
													setRange((old) => old && { ...old, from: dayjs().subtract(15, 'days').format('YYYY-MM-DD') })
												}
											>
												Há 15 Dias
											</DropdownMenuItem>

											<DropdownMenuSub>
												<DropdownMenuSubTrigger className="font-normal">Outros</DropdownMenuSubTrigger>
												<DropdownMenuPortal>
													<DropdownMenuSubContent className="ml-2">
														<DropdownMenuGroup className="max-h-[150px] overflow-y-auto">
															{Array.from([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]).map((item) => {
																return (
																	<DropdownMenuItem
																		key={item + 'DashboardDropdownMenuItem'}
																		onClick={() =>
																			setRange(
																				(old) =>
																					old && {
																						...old,
																						from: dayjs()
																							.subtract(item + 1, 'month')
																							.format('YYYY-MM-DD'),
																					},
																			)
																		}
																	>
																		Há {item + 1} {item + 1 === 1 ? 'Mês' : 'Meses'}
																	</DropdownMenuItem>
																);
															})}
														</DropdownMenuGroup>
													</DropdownMenuSubContent>
												</DropdownMenuPortal>
											</DropdownMenuSub>
										</DropdownMenuGroup>
									</DropdownMenuContent>
								</DropdownMenu>
							</div>
							{renderCardsDashboard()}
						</motion.div>
					</Template.Content>
				</Template.Page>
			</Template.Root>
		</Suspense>
	);
}
