import { Variants } from 'framer-motion';

export const SlideInFromLeft: Variants = {
	initial: { opacity: 1, x: -75 },
	animate: {
		opacity: 1,
		x: 0,
		transition: {
			ease: 'easeIn',
			delayChildren: 0.4,
			staggerChildren: 0.2,
		},
	},
	exit: { opacity: 0, x: 75 },
};

export const SlideInFromLeftItem = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
};
