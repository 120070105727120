import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CardFooter } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideInFromLeft, SlideInFromLeftItem } from '@/constants/animations/slide-in-from-left';
import exportData from '@/functions/export-data';
import { getDrawUrl } from '@/functions/get-draw-url';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { getMonthText } from '@/functions/get-month-text';
import { DrawMonth, DrawYear, QueryGetAllPaginatedDraws, getAllPaginatedDraws } from '@/functions/tanstack-query/draws/get-all-paginated-draws';
import { useAuth } from '@/hooks/use-auth';
import useDebounce from '@/hooks/use-debounce';
import api from '@/lib/api';
import { CircularProgress, Divider, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { QRCode } from 'antd';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { motion } from 'framer-motion';
import {
	LucideAlertTriangle,
	LucideClipboard,
	LucideDownload,
	LucideGift,
	LucideMenu,
	LucideMinus,
	LucidePen,
	LucidePlus,
	LucidePlusCircle,
	LucideQrCode,
	LucideRocket,
	LucideSearch,
	LucideTrash,
} from 'lucide-react';
import { ChangeEvent, Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import DrawCard from '../components/draw-card';
import { Draw } from '../utils/interfaces';
import DrawManagementAsideActions from './components/aside-actions';

dayjs.extend(relativeTime).locale(ptBR);
dayjs.extend(duration);

export default function DrawManagement() {
	const queryClient = useQueryClient();
	const { user } = useAuth();
	const { theme } = useTheme();
	const initialQuantity: number = 30;

	const [page, setPage] = useState<number>(1);
	const [quantity, setQuantity] = useState<number>(initialQuantity);

	const [searchParams, setSearchParams] = useSearchParams();

	const draw_search_key = 'sd';
	const search = searchParams.get(draw_search_key) ?? '';
	const searchWDelay = useDebounce(search, 500);

	async function handleSearch(e: ChangeEvent<HTMLInputElement>) {
		updateURLParams(draw_search_key, e.target.value);
	}

	const updateURLParams = (key: string, value: string) => {
		setSearchParams((oldParams) => {
			const newParams = new URLSearchParams(oldParams.toString());
			newParams.set(key, value);
			return newParams;
		});
	};

	useEffect(() => {
		if (searchWDelay) {
			setPage(1);
			setQuantity(initialQuantity);
		}
	}, [searchWDelay]);
	const {
		data: Draws,
		status,
		isLoading,
		hasNextPage,
		error,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['get-all-draws', page, quantity, searchWDelay],
		queryFn: ({ pageParam }) => getAllPaginatedDraws(user?.token!, pageParam, quantity, searchWDelay),
		initialPageParam: page,
		getNextPageParam: ({ data }) => {
			const nextPage = data.total_pages > data.current_page ? data.current_page + 1 : undefined;
			return nextPage;
		},
		enabled: !!user?.token,
	});

	const { ref, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage && !isLoading && !error) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isLoading, error, fetchNextPage]);

	async function deleteDraw(draw_id: string) {
		await api
			.delete(`/draw/${draw_id}`, getHeadersAuthorization(user?.token!))
			.then(() => {
				return toast.success(`Sorteio apagado com sucesso!`);
			})
			.catch((err) => toast.error(err.response.data.message));
	}

	const { mutateAsync: handleDeleteDraw } = useMutation(
		{
			mutationFn: async (draw_id: string) => {
				await deleteDraw(draw_id);
			},
			onSuccess: async () => {
				return await queryClient.invalidateQueries({ queryKey: ['get-all-draws'] });
			},
		},
		queryClient,
	);

	const navigate = useNavigate();
	const modalShareLink = useDisclosure();

	const [contentModalShareLink, setContentModalShareLink] = useState<{ link: string; title: string } | null>(null);

	const handleOpenModalShareLink = async (draw: Draw) => {
		await api
			.get(`/draw/link/${draw.id}`, getHeadersAuthorization(user?.token!))
			.then((res) => {
				setContentModalShareLink({ title: draw.title, link: res.data.link });
				return modalShareLink.onOpen();
			})
			.catch((err) => toast.error(err.response.data.message));
	};

	const handleDownloadDrawReport = async (draw_id: string, draw_title: string) => {
		await api
			.get(`/draw/report-draw-pdf/${draw_id}`, {
				headers: {
					Authorization: `Bearer ${user?.token!}`,
				},
				responseType: 'blob',
			})
			.then(async (res) => {
				toast.success('Seu relatório está baixando!');
				await exportData(res.data, `Relatório Sorteio - ${draw_title}`, 'application/pdf');
			})
			.catch((err) => toast.error(err.response.data.message));
	};

	const [sizeQrCode, setQrCodeSize] = useState<number>(200);

	const increaseSizeQrCode = () => {
		setQrCodeSize((prevSize) => {
			const newSize = prevSize + 10;
			if (newSize > 300) {
				return 300;
			}
			return newSize;
		});
	};

	const declineSizeQrCode = () => {
		setQrCodeSize((prevSize) => {
			const newSize = prevSize - 10;
			if (newSize < 48) {
				return 48;
			}
			return newSize;
		});
	};

	const downloadQRCode = () => {
		const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
		if (canvas) {
			const url = canvas.toDataURL();
			const a = document.createElement('a');
			a.download = 'QRCode.png';
			a.href = url;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};

	function renderDrawButton(draw: Draw) {
		if (draw.drawed_at) {
			return (
				<Button className="w-full lg:w-auto" variant={`emphasis`} onClick={() => navigate(`/draw/result/${draw.id}`)}>
					Ver resultados
					<LucideGift size={18} />
				</Button>
			);
		}
		if (draw.winners && draw.end_date && !draw.drawed_at) {
			return (
				<Button className="w-full lg:w-auto" variant={`destructive`} onClick={() => navigate(getDrawUrl(draw.type, draw.id))}>
					Salvar
					<LucideAlertTriangle size={18} />
				</Button>
			);
		}
		if (draw.end_date) {
			return (
				<Button className="w-full lg:w-auto" variant={`default`} onClick={() => navigate(getDrawUrl(draw.type, draw.id))}>
					Sortear
					<LucideGift size={18} />
				</Button>
			);
		}
		return (
			<Button className="w-full lg:w-auto" variant={`default`} onClick={() => navigate(`/draw/release/${draw.id}`)}>
				Liberar Sorteio
				<LucideRocket size={18} />
			</Button>
		);
	}

	const renderDraws = useMemo(() => {
		if (status === 'pending') {
			return <CircularProgress className="mx-auto" />;
		} else if (status === 'error') {
			return (
				<h1 className="mx-auto my-2 flex w-full items-center justify-center gap-1.5 text-center text-lg font-medium text-muted-foreground">
					Falha ao carregar dados -{' '}
					<span className="text-sm underline" onClick={() => window.location.reload()}>
						Recarregar
					</span>{' '}
				</h1>
			);
		} else {
			return (
				<div className="mb-10 space-y-4 overflow-x-hidden">
					{Draws &&
						Draws.pages.map((page: QueryGetAllPaginatedDraws, index) => {
							return (
								<Fragment key={index}>
									{page.data.results && page.data.results.length > 0 ? (
										page.data.results.map((year: DrawYear, yearIndex: number) => (
											<div key={yearIndex + 'year'} className="flex flex-col gap-2">
												<h1 className="flex items-center gap-4 text-xl font-medium">
													<Divider className="flex-1" />
													{year.year}
												</h1>
												{year.months.map((month: DrawMonth, monthIndex: number) => (
													<motion.div
														variants={SlideInFromLeft}
														initial="initial"
														animate="animate"
														key={monthIndex + 'month'}
														className="space-y-2"
													>
														<motion.h2
															variants={SlideInFromLeftItem}
															className=" flex items-center gap-4 rounded-bl-md border-l pl-3 text-lg font-normal capitalize"
														>
															<Badge variant={'secondary'} className="translate-y-3 font-normal">
																{getMonthText(Number(month.month))}
															</Badge>
														</motion.h2>
														<div className="translate-y-3 space-y-3 pl-3">
															{month.draws.map((draw: Draw, drawIndex: number) => {
																const prizeIsMoney = !/[^\d]/.test(draw.prize);
																return (
																	<motion.div key={drawIndex + 'draw'} variants={SlideInFromLeftItem}>
																		<DrawCard draw={draw} prizeIsMoney={prizeIsMoney}>
																			<CardFooter className="flex-col justify-between gap-6 lg:flex-row lg:gap-2">
																				<div className="flex w-full flex-col items-center gap-2 lg:flex-row">
																					{renderDrawButton(draw)}

																					<Button
																						className="w-full lg:w-auto"
																						variant={`emphasis`}
																						onClick={() => navigate(`/new-draw/${draw.id}`)}
																					>
																						Editar
																						<LucidePen size={18} />
																					</Button>
																					<Button
																						className="w-full lg:w-auto"
																						variant={`outline`}
																						onClick={() => handleOpenModalShareLink(draw)}
																					>
																						Compartilhar link
																						<LucideQrCode size={18} />
																					</Button>
																					<Button
																						className="w-full lg:w-auto"
																						variant={`link`}
																						onClick={() =>
																							handleDownloadDrawReport(draw.id, draw.title)
																						}
																					>
																						Baixar relatorio
																						<LucideDownload size={18} />
																					</Button>
																				</div>
																				<Button
																					className="w-full lg:w-auto"
																					variant={`destructive`}
																					onClick={() => handleDeleteDraw(draw.id)}
																				>
																					<span className="block lg:hidden">Excluir</span>
																					<LucideTrash size={18} />
																				</Button>
																			</CardFooter>
																		</DrawCard>
																	</motion.div>
																);
															})}
														</div>
													</motion.div>
												))}
											</div>
										))
									) : (
										<div className="mt-4 flex w-full justify-center text-2xl text-muted-foreground">
											<h1 className="mx-auto font-normal">Sem mais registros</h1>
										</div>
									)}
								</Fragment>
							);
						})}
					<div ref={ref} id="intersectionObserver" />
				</div>
			);
		}
	}, [status, Draws]);

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Aside className="max-w-64">
					<DrawManagementAsideActions />
				</Template.Aside>
				<Template.Page>
					<Template.Nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon" className="md:hidden">
									<LucideMenu size={20} />
								</Button>
							</SheetTrigger>
							<SheetContent side="left" className="flex flex-col">
								<SheetHeader>
									<SheetTitle>Pormade Sorteador</SheetTitle>
								</SheetHeader>
								<DrawManagementAsideActions />
							</SheetContent>
						</Sheet>
					</Template.Nav>
					<Template.Content>
						<motion.div
							variants={SlideInFromLeft}
							initial="initial"
							animate="animate"
							exit={{ x: 75 }}
							transition={{ duration: 0.7 }}
							className="flex flex-1 flex-col space-y-8 bg-background pb-4 "
						>
							<Header title="Gerenciamento de sorteios" description="Sorteios dos ultimos 30 dias, ou todos" showBreadCrumb />

							<div className=" flex w-full items-center gap-2 sm:max-w-xl">
								<Label htmlFor="search" className="relative flex  flex-1 items-center ">
									<LucideSearch size={16} className="absolute left-4 top-2.5" />
									<Input
										id="search"
										value={search ?? ''}
										onChange={handleSearch}
										placeholder="Pesquise sorteios aqui"
										className=" pl-12"
									/>
								</Label>
								<Button onClick={() => navigate(`/new-draw`)} variant={`emphasis`}>
									Novo sorteio{' '}
									<LucidePlusCircle
										size={18}
										className="scale-100 transition-all duration-300 group-hover:-rotate-180 group-hover:scale-110"
									/>
								</Button>
							</div>

							{renderDraws}
						</motion.div>
					</Template.Content>
				</Template.Page>
			</Template.Root>

			<Modal
				backdrop="blur"
				shouldBlockScroll
				isOpen={modalShareLink.isOpen}
				onOpenChange={modalShareLink.onOpenChange}
				classNames={{ backdrop: 'blur-sm', base: 'bg-background border' }}
			>
				<ModalContent>
					<ModalHeader>Compartilhar Link</ModalHeader>
					<ModalBody className="flex flex-col gap-4">
						<div id="myqrcode">
							<QRCode
								includeMargin={true}
								rootClassName="mx-auto"
								icon={'/dices.svg'}
								iconSize={sizeQrCode / 4}
								size={sizeQrCode}
								color={theme === 'dark' ? 'white' : 'black'}
								value={contentModalShareLink?.link ?? '-'}
							/>
						</div>
						<div className="flex items-center justify-center gap-2">
							<Button variant={`link`} onClick={() => downloadQRCode()}>
								Download <LucideDownload size={18} />
							</Button>
							<Button variant={`outline`} disabled={sizeQrCode <= 160} onClick={declineSizeQrCode}>
								<LucideMinus size={10} />
							</Button>
							<Button variant={`outline`} disabled={sizeQrCode >= 300} onClick={increaseSizeQrCode}>
								<LucidePlus size={10} />
							</Button>
						</div>
					</ModalBody>
					<ModalFooter>
						<Input id="link" value={contentModalShareLink?.link ?? ''} readOnly />

						<Button
							variant={'secondary'}
							size={'sm'}
							onClick={() => {
								copy(contentModalShareLink?.link!);
								return toast.success(`Link de Inscrição copiado para área de transferência`, {
									position: 'top-right',
								});
							}}
						>
							<LucideClipboard size={14} />
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Suspense>
	);
}
