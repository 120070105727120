import LoadingPage from '@/components/loading-page';
import Background404 from '@/components/ui/background-404';
import { Button } from '@/components/ui/button';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LostPage() {
	const navigate = useNavigate();

	return (
		<Suspense fallback={<LoadingPage />}>
			<Background404>
				<div className="z-50 flex flex-col items-center gap-4">
					<img src={'/404.svg?url'} alt="404" width={600} />
					<div className="flex items-center gap-4">
						<Button size={'lg'} onClick={() => navigate('/Dashboard')}>
							Início
						</Button>
						<Button variant={'secondary'} size={'lg'} onClick={() => navigate(-1)}>
							Voltar onde eu estava
						</Button>
					</div>
				</div>
			</Background404>
		</Suspense>
	);
}
