import Aside from '@/components/aside';
import { Button } from '@/components/ui/button';
import { LucideList, LucideListCollapse, LucidePlusCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function DashboardAsideActions() {
	const navigate = useNavigate();
	return (
		<Aside showHomeButton={false}>
			<div className="flex flex-col gap-3">
				<Button className="justify-between" onClick={() => navigate(`/new-draw`)} variant={'outline'}>
					<h1 className="line-clamp-1">Novo sorteio</h1>
					<LucidePlusCircle className="transition-all duration-300 group-hover:rotate-180 group-hover:scale-110" size={16} />
				</Button>
				<Button onClick={() => navigate('/draw-management')} variant={'outline'} className="justify-between">
					<h1 className="line-clamp-1">Meus sorteios</h1>
					<LucideList size={18} className="translate-x-0 scale-100 transition-all duration-300 group-hover:translate-x-4 group-hover:scale-0" />
					<LucideListCollapse
						size={18}
						className="absolute right-4 -translate-x-4 scale-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100"
					/>
				</Button>
			</div>
		</Aside>
	);
}
