import Aside from '@/components/aside';
import { Button } from '@/components/ui/button';
import { Divider } from '@nextui-org/react';
import { LucideLayoutList, LucideList, LucideListCollapse, LucideListTodo, LucideSettings, LucideSettings2 } from 'lucide-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function CreateDrawAsideActions() {
	const { id } = useParams();

	const local = useLocation().pathname;

	const navigate = useNavigate();

	return (
		<Aside>
			<div className="flex flex-col gap-3">
				<Button
					onClick={() => (id ? navigate(`/new-draw/${id}`) : navigate(`/new-draw`))}
					variant={!local.includes('participation-type') ? 'secondary' : 'outline'}
					className="justify-between"
				>
					<h1 className="line-clamp-1">Configurações</h1>
					<LucideSettings2 size={18} className="scale-100 transition-all duration-300 group-hover:-rotate-180 group-hover:scale-0" />
					<LucideSettings
						size={18}
						className="absolute right-4 scale-0 transition-all duration-300 group-hover:-rotate-180 group-hover:scale-100"
					/>
				</Button>

				<Button
					onClick={() => (id ? navigate(`/new-draw/participation-type/${id}`) : navigate(`/new-draw/participation-type`))}
					variant={local.includes('participation-type') ? 'secondary' : 'outline'}
					className="justify-between"
				>
					<h1 className="line-clamp-1">Participação</h1>
					<LucideListTodo
						size={18}
						className="translate-x-0 scale-100 transition-all duration-300 group-hover:-translate-x-4 group-hover:scale-0"
					/>
					<LucideLayoutList
						size={16}
						className="absolute right-4 translate-x-4 scale-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100"
					/>
				</Button>

				<Divider />

				<Button onClick={() => navigate('/draw-management')} variant={'outline'} className="justify-between">
					<h1 className="line-clamp-1">Meus sorteios</h1>
					<LucideList size={18} className="translate-x-0 scale-100 transition-all duration-300 group-hover:translate-x-4 group-hover:scale-0" />
					<LucideListCollapse
						size={18}
						className="absolute right-4 -translate-x-4 scale-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100"
					/>
				</Button>
			</div>
		</Aside>
	);
}
