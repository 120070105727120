import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import api from '@/lib/api';

export interface DrawReport {
	draws_held: number;
	total_amount_drawn: number;
	participants: number;
	winners: number;
}
export async function getDrawReport(token: string, initial_date: string, final_date: string): Promise<DrawReport> {
	const res = await api.get(`/draw/report/${initial_date}/${final_date}`, getHeadersAuthorization(token));
	return res.data;
}
