import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { ClassNameValue, twMerge } from 'tailwind-merge';
export function AnimatedText({ texts, className }: { texts: string[] | ReactNode[]; className?: ClassNameValue }) {
	return (
		<motion.div
			variants={SlideFromTop}
			initial="initial"
			animate="animate"
			className={twMerge(`flex items-center justify-center gap-1 whitespace-nowrap`)}
		>
			{texts.map((text, index) => (
				<motion.span key={index} variants={SlideFromTopItem} className={twMerge(className, 'break-words')}>
					{text}
				</motion.span>
			))}
		</motion.div>
	);
}
