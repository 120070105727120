import { Button } from '@/components/ui/button';
import { CircularProgress } from '@nextui-org/react';
import { CSSProperties, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import './spinningWheel.css';

interface SpinningWheelProps extends HTMLAttributes<HTMLDivElement> {
	onClick: () => void;
	isSorting: boolean;
	progress: number;
	spinText: string | number;
}
export default function SpinningWheel({ onClick, isSorting, progress, spinText, ...rest }: SpinningWheelProps) {
	return (
		<div className="containerWheel relative flex h-[250px] w-[250px] items-center justify-center bg-transparent md:h-[350px] md:w-[350px]" {...rest}>
			<CircularProgress
				value={progress}
				aria-label="Loading..."
				classNames={{
					svg: ' md:h-[450px] md:w-[450px] h-[320px] w-[320px]',
					indicator: 'stroke-1 stroke-primary',
					track: 'stroke-transparent stroke-1',
				}}
			/>
			<Button
				disabled={isSorting}
				onClick={onClick}
				className={twMerge(
					'absolute left-[50%] top-[50%] z-50  h-20 w-20 -translate-x-[50%] -translate-y-[50%] rounded-full bg-background  text-lg font-bold uppercase text-black shadow-lg shadow-black hover:bg-background disabled:bg-background disabled:text-muted-foreground disabled:opacity-80 disabled:shadow-none dark:text-white',
				)}
			>
				<div
					data-is-sorting={isSorting}
					className="marker absolute -top-[25px] left-[50%] h-8 w-6 -translate-x-[50%] bg-background text-primary-foreground transition-all duration-200 data-[is-sorting=true]:bg-background data-[is-sorting=true]:text-background "
				>
					{''}
				</div>
				{spinText}
			</Button>
			<div
				data-is-sorting={isSorting}
				className="wheel absolute left-0 top-0 h-[100%] w-[100%] overflow-hidden rounded-full border-4 border-background bg-background "
			>
				{Array.from([1, 2, 3, 4, 5, 6, 7, 8]).map((item) => {
					return (
						<div
							key={item}
							style={{ '--i': item } as CSSProperties}
							data-is-sorting={isSorting}
							className="absolute flex h-[50%] w-[50%] items-center justify-center bg-primary/80 transition-all duration-200 data-[is-sorting=true]:animate-pulse data-[is-sorting=true]:bg-primary dark:bg-primary-foreground"
						></div>
					);
				})}
			</div>
		</div>
	);
}
