import { getAllUsers } from '@/functions/tanstack-query/users/get-all-users';
import { useAuth } from '@/hooks/use-auth';
import { User } from '@/interfaces/user';
import { CircularProgress } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { UsersDataTable } from './components/users-data-table';

interface TableUsersProps {
	columns: ColumnDef<User>[];
	showTableViewOptions?: boolean;
	showResize?: boolean;
}

export default function TableUsers({ columns, showTableViewOptions = true, showResize = true }: TableUsersProps) {
	const { user } = useAuth();

	const {
		data: Users,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['get-all-users'],
		queryFn: () => getAllUsers(user?.token!),
		enabled: !!user?.token,
	});

	if (isLoading) return <CircularProgress className="mx-auto" aria-label="loading..." />;
	if (error)
		return (
			<h1 className="mx-auto flex w-full items-center gap-1.5 text-center text-lg font-medium text-muted-foreground">
				Falha ao carregar dados -{' '}
				<span className="text-sm underline" onClick={() => window.location.reload()}>
					Recarregar
				</span>{' '}
			</h1>
		);

	return <UsersDataTable showResize={showResize} columns={columns} data={Users?.results ?? []} showTableViewOptions={showTableViewOptions} />;
}
