import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react';

export default function ModalTermsOfUse({ isOpen, onOpenChange }: { isOpen: boolean; onOpenChange: (isOpen: boolean) => void }) {
	return (
		<Modal size="3xl" backdrop="blur" shouldBlockScroll isOpen={isOpen} onOpenChange={onOpenChange}>
			<ModalContent className=" bg-background">
				<ModalHeader>Termos de Uso</ModalHeader>
				<ModalBody className="scrollbarPrimary max-h-[500px] space-y-4 overflow-auto">
					<p>
						Ao utilizar o software Pormade Sorteador, você concorda e autoriza o uso do seu CPF (Cadastro de Pessoas Físicas) como meio de
						identificação único para acesso e participação em sorteios cadastrados na plataforma.
					</p>
					<div className="space-y-2">
						<h3 className="font-semibold">Uso do CPF:</h3>
						<ul className="list-inside list-disc">
							<li className="font-normal">
								<span className="font-semibold">Identificação:</span> O CPF será utilizado exclusivamente para identificação do usuário
								no sistema Pormade Sorteador. Ele será criptografado e armazenado de forma segura em nosso banco de dados.
							</li>
							<li className="font-normal">
								<span className="font-semibold">Acesso a Sorteios:</span> Seu CPF será utilizado para garantir sua participação em
								sorteios cadastrados na plataforma Pormade Sorteador. Ele será usado para verificar sua inscrição e presença nos
								sorteios.
							</li>
							<li className="font-normal">
								<span className="font-semibold">Segurança dos Dados:</span> Garantimos a segurança e confidencialidade dos seus dados.
								Seu CPF será tratado com total confidencialidade e não será compartilhado com terceiros sem o seu consentimento.
							</li>
						</ul>
					</div>
					<div className="space-y-2">
						<h3 className="font-semibold">Aceitação dos Termos:</h3>
						<p className="font-normal">
							Ao utilizar o software Pormade Sorteador e fornecer seu CPF, você concorda com estes termos de uso e autoriza o uso do seu
							CPF como meio de identificação único no sistema.
						</p>
					</div>
					<div className="space-y-2">
						<h3 className="font-semibold">Participação nos Sorteios:</h3>
						<p className="font-normal">
							Para garantir sua participação nos sorteios, é importante fornecer um número de telefone válido. Esse número será utilizado
							para entrarmos em contato com você caso seja um dos ganhadores.
						</p>
					</div>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
