import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideInFromLeft } from '@/constants/animations/slide-in-from-left';
import { DrawReleaseTimePreset } from '@/constants/draw/draw-release-time-preset';
import { DrawType } from '@/constants/draw/draw-types';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { renderTypeDrawAuxMessage } from '@/functions/render-type-draw-message';
import { getDrawAttendees } from '@/functions/tanstack-query/draws/get-attendees';
import { getDraw } from '@/functions/tanstack-query/draws/get-draw';
import { useAuth } from '@/hooks/use-auth';
import api, { FrontBaseURL } from '@/lib/api';
import { Modal, ModalBody, ModalContent, ModalHeader, Radio, RadioGroup, useDisclosure } from '@nextui-org/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { QRCode } from 'antd';
import copy from 'copy-to-clipboard';
import { motion } from 'framer-motion';
import { LucideClipboard, LucideEye, LucideEyeOff, LucideMenu, LucideRocket } from 'lucide-react';
import { ChangeEvent, Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import ReleaseDrawAsideActions from './components/aside-actions';
import { Clock } from './components/clock';

export default function ReleaseDraw() {
	const { id } = useParams();
	const { theme } = useTheme();
	const { user } = useAuth();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const modalFinishDraw = useDisclosure();

	const [searchParams, setSearchParams] = useSearchParams();

	const timePreset = searchParams.get('timePreset') ?? '';

	const updateURLParams = (key: string, value: string) => {
		setSearchParams((oldParams) => {
			const newParams = new URLSearchParams(oldParams.toString());
			newParams.set(key, value);
			return newParams;
		});
	};

	function resetTimeFields() {
		if (timePreset === DrawReleaseTimePreset.days) {
			setHoursInput('');
			setMinutesInput('');
		} else if (timePreset === DrawReleaseTimePreset.hours) {
			setDaysInput('');
			setMinutesInput('');
		} else if (timePreset === DrawReleaseTimePreset.minutes) {
			setDaysInput('');
			setHoursInput('');
		}
	}

	useEffect(() => {
		resetTimeFields();
	}, [timePreset]);

	const { data: Draw } = useQuery({
		queryKey: ['get-draw-id', id],
		queryFn: () => getDraw(user?.token!, id!),
		enabled: !!user?.token && !!id,
	});

	const [watchAttendees, setWatchAttendees] = useState(false);

	const { data: DrawAttendees } = useQuery({
		queryKey: ['get-draw-attendees', id],
		queryFn: () => getDrawAttendees(user?.token!, id!),
		enabled: !!user?.token && !!id && !!Draw && watchAttendees,
		refetchInterval: 1000,
	});

	useEffect(() => {
		if (Draw && Draw.drawed_at) {
			toast.warning('Esse sorteio já foi finalizado, você será redirecionado para a página de resultado.');
			return navigate(`/draw/result/${id}`);
		}
	}, [Draw]);

	const [timeIsOver, setTimeIsOver] = useState(false);
	const [hasStartedDraw, setHasStartedDraw] = useState(false);

	const [totalTimeForAnswersInSeconds, setTotalTimeForAnswersInSeconds] = useState<number>(0);

	const [daysInput, setDaysInput] = useState('');
	const [hoursInput, setHoursInput] = useState('');
	const [minutesInput, setMinutesInput] = useState('');

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === DrawReleaseTimePreset.days) {
			setDaysInput(String(Math.min(Number(value), 30)));
		} else if (name === DrawReleaseTimePreset.hours) {
			setHoursInput(String(Math.min(Number(value), 23)));
		} else if (name === DrawReleaseTimePreset.minutes) {
			setMinutesInput(String(Math.min(Number(value), 59)));
		}
	};

	useEffect(() => {
		let totalSeconds = 0;
		if (daysInput !== '') {
			totalSeconds += parseInt(daysInput) * 24 * 60 * 60;
		}
		if (hoursInput !== '') {
			totalSeconds += parseInt(hoursInput) * 60 * 60;
		}
		if (minutesInput !== '') {
			totalSeconds += parseInt(minutesInput) * 60;
		}
		setTotalTimeForAnswersInSeconds(totalSeconds);
	}, [daysInput, hoursInput, minutesInput]);

	const calcRestantTimeForAnswer = () => {
		if (Draw && Draw.end_date) {
			const currentTime = new Date();
			const finalTime = new Date(Draw.end_date);
			const diffInMilliseconds = finalTime.getTime() - currentTime.getTime();

			if (diffInMilliseconds <= 0) {
				const redirectUrl =
					Draw.type === DrawType.list
						? `/draw/list/${Draw.id}`
						: Draw.type === DrawType.questions
							? `/draw/questions/${Draw.id}`
							: `/draw/text/${Draw.id}`;

				setTimeIsOver(true);
				setTotalTimeForAnswersInSeconds(0);
				setTimeout(() => {
					navigate(redirectUrl);
				}, 1000);
				return;
			}

			const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
			setTotalTimeForAnswersInSeconds(diffInSeconds);
		}
	};

	useEffect(() => {
		if (Draw && Draw.end_date) {
			setHasStartedDraw(true);
			updateURLParams('timePreset', DrawReleaseTimePreset.days);
			const intervalId = setInterval(calcRestantTimeForAnswer, 1000);
			return () => clearInterval(intervalId);
		} else {
			setHasStartedDraw(false);
		}
		return;
	}, [Draw]);

	function disableReleaseButton() {
		if (!timePreset) return true;
		if (timePreset === DrawReleaseTimePreset.days && Number(daysInput) <= 0) return true;
		if (timePreset === DrawReleaseTimePreset.hours && Number(hoursInput) <= 0) return true;
		if (timePreset === DrawReleaseTimePreset.minutes && Number(minutesInput) <= 0) return true;
		return false;
	}

	function generateReleaseDate(): Promise<string> {
		return new Promise((resolve, reject) => {
			const currentDate = new Date();

			let releaseDate;

			if (timePreset !== DrawReleaseTimePreset.immediately) {
				releaseDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate() + Number(daysInput)!,
					currentDate.getHours() + Number(hoursInput)!,
					currentDate.getMinutes() + Number(minutesInput)!,
					currentDate.getSeconds(),
				);
			} else {
				releaseDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate(),
					currentDate.getHours(),
					currentDate.getMinutes(),
					currentDate.getSeconds() + 1,
				);
			}

			if (!isNaN(releaseDate.getTime())) {
				resolve(releaseDate.toISOString());
			} else {
				reject(new Error('Data inválida.'));
			}
		});
	}

	async function releaseDraw() {
		if (!Draw) return toast.error('Erro ao liberar, recarregue e tente novamente!');

		const releaseDate = await generateReleaseDate();

		if (Draw.type === DrawType.questions && (!Draw.questions || Draw.questions.length <= 0))
			return toast.error('Seu sorteio é do tipo Questionário e não possui questões!');

		if (Draw.type === DrawType.text && !Draw.text?.text)
			return toast.error('Seu sorteio é do tipo Campo de texto e não possui um texto auxiliar para o usuário!');

		await api
			.patch(`/draw/active/${id}`, { end_date: releaseDate }, getHeadersAuthorization(user?.token!))
			.then(() => {
				toast.success(`Sorteio liberado com sucesso!`);
				return queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
			})
			.catch((err) => toast.error(err.response.data.message));
	}

	async function finishDraw() {
		if (!Draw) return toast.error('Erro ao finalizar, recarregue e tente novamente!');

		const currentDate = new Date();

		const immediatelyEndDate = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate(),
			currentDate.getHours(),
			currentDate.getMinutes(),
			currentDate.getSeconds() + 1,
		);

		await api
			.patch(`/draw/active/${id}`, { end_date: immediatelyEndDate }, getHeadersAuthorization(user?.token!))
			.then(() => {
				toast.success(`Sorteio fechado com sucesso!`);
				modalFinishDraw.onClose();
				return queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
			})
			.catch((err) => toast.error(err.response.data.message));
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Aside className="max-w-64">
					<ReleaseDrawAsideActions />
				</Template.Aside>
				<Template.Page>
					<Template.Nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon" className="md:hidden">
									<LucideMenu size={20} />
								</Button>
							</SheetTrigger>
							<SheetContent side="left" className="flex flex-col">
								<SheetHeader>
									<SheetTitle>Pormade Sorteador</SheetTitle>
								</SheetHeader>
								<ReleaseDrawAsideActions />
							</SheetContent>
						</Sheet>
					</Template.Nav>
					<Template.Content>
						<motion.div
							variants={SlideInFromLeft}
							initial="initial"
							animate="animate"
							exit={{ x: 75 }}
							transition={{ duration: 0.7 }}
							className="flex flex-1 flex-col space-y-8 bg-background pb-4 "
						>
							<Header
								title={Draw && Draw.end_date ? Draw.title : `Liberar sorteio ${Draw?.title!}`}
								description={Draw && Draw.end_date ? 'Sorteio já liberado!' : 'Libere aqui para os usuários participarem'}
							/>

							{Draw?.type && renderTypeDrawAuxMessage(Draw.type)}

							{Draw?.type === DrawType.questions && (!Draw?.questions || Draw?.questions.length <= 0) && (
								<span
									className="cursor-pointer text-base font-normal text-danger underline underline-offset-2"
									onClick={() => navigate(`/new-draw/participation-type/${Draw?.id}`)}
								>
									Seu sorteio é do tipo Questionário e ainda não possui questões!
								</span>
							)}
							{Draw?.type === DrawType.text && !Draw?.text?.text && (
								<span
									className="cursor-pointer text-base font-normal text-warning underline underline-offset-2"
									onClick={() => navigate(`/new-draw/participation-type/${Draw?.id}`)}
								>
									Seu sorteio é do tipo Campo de texto e ainda não possui um texto auxiliar para o usuário!
								</span>
							)}

							{!Draw?.end_date && (
								<RadioGroup value={timePreset} onValueChange={(value) => updateURLParams('timePreset', value)}>
									Por quanto tempo?
									<Radio
										classNames={{ label: 'font-normal' }}
										value={DrawReleaseTimePreset.minutes}
										isDisabled={Draw?.type === DrawType.list}
									>
										Minutos
									</Radio>
									<Radio
										classNames={{ label: 'font-normal' }}
										value={DrawReleaseTimePreset.hours}
										isDisabled={Draw?.type === DrawType.list}
									>
										Horas
									</Radio>
									<Radio
										classNames={{ label: 'font-normal' }}
										value={DrawReleaseTimePreset.days}
										isDisabled={Draw?.type === DrawType.list}
									>
										Dias
									</Radio>
									<Radio
										classNames={{ label: 'font-normal' }}
										value={DrawReleaseTimePreset.immediately}
										isDisabled={Draw?.type === DrawType.questions || Draw?.type === DrawType.text}
									>
										Imediatamente
									</Radio>
								</RadioGroup>
							)}
						</motion.div>

						{timePreset !== DrawReleaseTimePreset.immediately && (
							<>
								<Clock
									minutesInput={minutesInput}
									hoursInput={hoursInput}
									daysInput={daysInput}
									handleInputChange={handleInputChange}
									totalTimeForAnswersInSeconds={totalTimeForAnswersInSeconds}
									timePreset={timePreset}
									hasStartedDraw={hasStartedDraw}
									hasTimePreset
								/>
								{Draw?.type !== DrawType.list && (
									<div className="flex flex-col items-center gap-4 pt-14">
										<Button className="mx-auto" variant={'emphasis'} onClick={() => setWatchAttendees((old) => !old)}>
											{watchAttendees ? (
												<h1 className="flex items-center gap-1.5">
													Parar de acompanhar <LucideEyeOff size={18} />
												</h1>
											) : (
												<h1 className="flex items-center gap-1.5">
													Acompanhar participantes <LucideEye size={18} />
												</h1>
											)}
										</Button>
										{DrawAttendees && watchAttendees && (
											<h1 className="mx-auto text-lg text-primary">{DrawAttendees.length} respostas!</h1>
										)}
										<div id="myqrcode">
											<QRCode
												includeMargin={true}
												rootClassName="mx-auto"
												iconSize={260 / 4}
												size={260}
												color={theme === 'dark' ? 'white' : 'black'}
												value={`${FrontBaseURL}/draw/try-my-luck/${id}`}
											/>
										</div>
										<div className="mx-auto flex w-full items-center justify-center gap-2 lg:max-w-80">
											<Input id="link" value={`${FrontBaseURL}/draw/try-my-luck/${id}`} readOnly />

											<Button
												variant={'secondary'}
												size={'sm'}
												onClick={() => {
													copy(`${FrontBaseURL}/draw/try-my-luck/${id}`);
													return toast.success(`Link de Inscrição copiado para área de transferência`, {
														position: 'top-right',
													});
												}}
											>
												<LucideClipboard size={14} />
											</Button>
										</div>
									</div>
								)}
							</>
						)}

						<div className="flex py-16 data-[hide=true]:hidden" data-hide={!timePreset}>
							{hasStartedDraw ? (
								<div className="flex w-full flex-col items-center justify-center gap-4">
									<p className=" select-none font-normal text-primary/70">
										{!timeIsOver
											? 'Sorteio disponível quando acabar o tempo'
											: 'Você será redirecionado para o sorteio, aguarde...'}
									</p>

									<h1
										className="cursor-pointer font-normal text-danger underline underline-offset-2 transition-all duration-250 hover:scale-105"
										onClick={() => modalFinishDraw.onOpen()}
									>
										Encerrar agora
									</h1>
								</div>
							) : (
								<Button disabled={disableReleaseButton()} onClick={async () => await releaseDraw()} className=" mx-auto sm:min-w-56">
									Liberar <LucideRocket size={18} />
								</Button>
							)}
						</div>
					</Template.Content>
				</Template.Page>
			</Template.Root>

			<Modal backdrop="blur" shouldBlockScroll isOpen={modalFinishDraw.isOpen} onOpenChange={modalFinishDraw.onOpenChange}>
				<ModalContent className="bg-background  p-3">
					<ModalHeader className="text-center text-xl">Deseja finalizar esse sorteio agora?</ModalHeader>
					<ModalBody className="flex-row items-center justify-center gap-4">
						<Button variant={'destructive'} onClick={finishDraw}>
							Finalizar agora
						</Button>
						<Button variant={'emphasis'} onClick={() => modalFinishDraw.onClose()}>
							Cancelar
						</Button>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Suspense>
	);
}
