import TemplateAside from './template-aside';
import TemplateContent from './template-content';
import TemplateNav from './template-nav';
import TemplatePage from './template-page';
import TemplateRoot from './template-root';

export const Template = {
	Root: TemplateRoot,
	Content: TemplateContent,
	Aside: TemplateAside,
	Nav: TemplateNav,
	Page: TemplatePage,
};
