import { roles } from '@/constants/roles';
import { token_key } from '@/constants/token-key';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { getZodErrors } from '@/functions/get-zod-errors';
import { CurrentUser } from '@/interfaces/user/current-user';
import { UserFromJWT } from '@/interfaces/user/user-from-jwt';
import api from '@/lib/api';
import { cookies } from '@/lib/clients/cookies-client';
import { jwtDecode } from 'jwt-decode';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { toast } from 'sonner';
import z from 'zod';

export const LoginFormSchema = z.object({
	user: z.string({ required_error: 'Campo usuário é obrigatório!' }),
	password: z.string({ required_error: 'Campo senha é obrigatório!' }),
});

export type LoginForm = z.infer<typeof LoginFormSchema>;

interface AuthContextData {
	signed: boolean;
	user: CurrentUser | null;
	isAbleToViewSensitiveActions: boolean;
	isLoading: boolean;
	signIn({ user, password }: LoginForm): Promise<boolean>;
	signOut(): void;
	refreshToken(actual_token: string): Promise<void>;
	maxAge?: number;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
export default AuthContext;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [user, setUser] = useState<CurrentUser | null>(null);
	const [isAbleToViewSensitiveActions, setIsAbleToViewSensitiveActions] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [maxAge, setMaxAge] = useState<number | undefined>(undefined);

	const timeDifference = (exp: number) => exp - Math.floor(new Date().getTime() / 1000);

	useEffect(() => {
		async function LocalStorageToken() {
			const token = await cookies.get(token_key);
			if (!!token) {
				await refreshToken(token);
			} else {
				setIsLoading(false);
			}
		}
		LocalStorageToken();
	}, []);

	async function defineUserAndStoreToken(token: string) {
		try {
			const { dadosAdicionais, exp, iat, loginId, nome, roles: userRoles, sistema, time }: UserFromJWT = jwtDecode(token);
			const newUser: CurrentUser = { token: token, user: { dadosAdicionais, exp, iat, nome, loginId, roles: userRoles, sistema, time } };

			cookies.set(token_key, newUser.token, { sameSite: 'strict', secure: import.meta.env.PROD, maxAge: timeDifference(newUser.user.exp), path: '/' });
			setMaxAge(timeDifference(newUser.user.exp));
			setUser(newUser);
			setIsAbleToViewSensitiveActions(newUser.user.roles.includes(roles.admin));
		} catch (err) {}
	}

	async function signIn({ user, password }: LoginForm): Promise<boolean> {
		let response: boolean = false;
		try {
			setIsLoading(true);
			const data = {
				user,
				password,
			};

			const parsedLoginForm = await LoginFormSchema.parseAsync(data);

			await api
				.post('/auth/login', parsedLoginForm)
				.then(async (res) => {
					await refreshToken(res.data);
					response = true;
					setIsLoading(false);
				})
				.catch((error) => {
					setIsLoading(false);
					return toast.error(error.response.data.message);
				});
			return response;
		} catch (error) {
			setIsLoading(false);
			getZodErrors(error);
			return response;
		}
	}

	async function refreshToken(actual_token: string) {
		return await api
			.post(`/auth/refresh`, { token: actual_token }, getHeadersAuthorization(actual_token))
			.then(async (res) => {
				await defineUserAndStoreToken(res.data);
			})
			.catch((err) => console.log(err));
	}

	async function signOut() {
		if (user?.token) {
			await api.patch('/auth/remove-token', { token: user.token });
		}
		setUser(null);
		sessionStorage.removeItem('logado');
		cookies.remove(token_key);
	}

	return (
		<AuthContext.Provider value={{ signed: !!user, user, signIn, signOut, isLoading, refreshToken, maxAge, isAbleToViewSensitiveActions }}>
			{children}
		</AuthContext.Provider>
	);
};
