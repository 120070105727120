import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { renderPreview } from '@/functions/render-preview';
import { Draw } from '../../utils/interfaces';
export default function DrawFiles({ Draw }: { Draw: Draw }) {
	return (
		<Carousel className="flex flex-col justify-end">
			<CarouselContent>
				{Draw.files?.map((file, index) => {
					return (
						<CarouselItem key={index + 'file'} className="flex  items-center justify-center p-4">
							{file ? renderPreview(file) : <code>Preview nao disponível</code>}
						</CarouselItem>
					);
				})}
			</CarouselContent>
			{Draw.files && Draw.files.length > 1 && (
				<div className="flex items-center justify-end gap-1.5">
					<CarouselPrevious className="h-9 w-9" />
					<CarouselNext className="h-9 w-9" />
				</div>
			)}
		</Carousel>
	);
}
