import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { motion } from 'framer-motion';
import { LucideInstagram, LucideTwitter, LucideYoutube } from 'lucide-react';
import { Button } from './ui/button';

export default function SocialMediaButtons() {
	return (
		<motion.div variants={SlideFromTop} initial="initial" animate="animate" className="flex w-full items-center justify-center gap-1 ">
			<motion.div variants={SlideFromTopItem}>
				<a href="https://www.youtube.com/@PormadeOficial" target="_blank">
					<Button variant={'ghost'}>
						<LucideYoutube size={24} />
					</Button>
				</a>
			</motion.div>
			<motion.div variants={SlideFromTopItem}>
				<a href="https://www.instagram.com/pormadeoficial/" target="_blank">
					<Button variant={'ghost'}>
						<LucideInstagram size={24} />
					</Button>
				</a>
			</motion.div>
			<motion.div variants={SlideFromTopItem}>
				<a href="https://x.com/pormadeoficial" target="_blank">
					<Button variant={'ghost'}>
						<LucideTwitter size={24} />
					</Button>
				</a>
			</motion.div>
		</motion.div>
	);
}
