import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { DrawDisplay } from '@/constants/draw/draw-display';
import { DrawType } from '@/constants/draw/draw-types';
import { getFormattedDatesDifference } from '@/functions/get-formatted-difference-between-dates';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
import { LucideAlertTriangle, LucideLifeBuoy, LucideRectangleHorizontal } from 'lucide-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Draw } from '../../utils/interfaces';

dayjs.extend(relativeTime).locale(ptBR);

export default function DrawCard({
	draw,
	prizeIsMoney,
	children,
	shouldNavigate = true,
}: {
	draw: Draw;
	prizeIsMoney: boolean;
	children?: ReactNode;
	shouldNavigate?: boolean;
}) {
	const navigate = useNavigate();

	return (
		<Card onDoubleClick={() => (shouldNavigate ? navigate(`/draw/${draw.id}`) : null)}>
			<CardHeader className=" items-center justify-between text-lg">
				<CardTitle className="flex w-full items-center justify-between gap-2">
					<span className="line-clamp-1 text-lg">{draw.title}</span>
					<TooltipProvider>
						<Tooltip delayDuration={20}>
							<TooltipTrigger>{draw.display === DrawDisplay.box ? <LucideRectangleHorizontal /> : <LucideLifeBuoy />}</TooltipTrigger>
							<TooltipContent className="-translate-x-4 border border-input bg-input text-primary hover:bg-input/70 dark:bg-secondary/90 dark:hover:bg-secondary/50">
								{draw.display === DrawDisplay.box ? 'Layout caixa' : 'Layout Roda'}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</CardTitle>
			</CardHeader>
			<CardContent className="flex flex-col gap-2">
				<CardDescription className="overflow-hidden text-ellipsis text-pretty">{draw.description}</CardDescription>

				<CardDescription>
					<span className="text-base">
						Prêmio: <code className="text-primary">{prizeIsMoney ? `R$ ${draw.prize}` : draw.prize}</code>
					</span>
				</CardDescription>

				<CardDescription>
					<span className="text-base">
						Participação:{' '}
						<code className="font-normal text-muted-foreground">
							{draw.type === DrawType.questions
								? 'Usuário responde um Questionário'
								: draw.type === DrawType.text
									? 'Usuário faz um Comentário'
									: 'Sorteio pede uma Lista'}
						</code>
					</span>
				</CardDescription>

				{draw?.minimum_hits! > 0 && (
					<CardDescription>
						<span className="text-base">
							Mínimo de acertos: <code className="font-normal text-muted-foreground">{draw.minimum_hits}</code>
						</span>
					</CardDescription>
				)}

				{draw?.winners! > 0 && (
					<CardDescription>
						<span className="text-base font-normal text-primary">
							{draw.winners} {draw.winners === 1 ? 'ganhador' : 'ganhadores!'}
						</span>
					</CardDescription>
				)}
				{draw.drawed_at ? (
					<CardDescription>
						<span className="text-base font-normal text-primary">
							Sorteado {dayjs().to(draw.drawed_at) + ' - ' + dayjs(draw.drawed_at).format('DD/MM/YYYY')}
						</span>
					</CardDescription>
				) : draw.winners && draw.end_date ? (
					<CardDescription>
						<span className="flex items-center gap-1.5 text-base font-normal text-destructive">
							Sorteio não salvo! <LucideAlertTriangle className="stroke-1" />
						</span>
					</CardDescription>
				) : (
					<CardDescription>
						<span className="text-base font-normal text-warning">Ainda não sorteado</span>
					</CardDescription>
				)}

				{shouldNavigate && draw.type === DrawType.questions && (!draw.questions || draw.questions.length <= 0) && (
					<CardDescription>
						<span
							className="cursor-pointer text-base font-normal text-danger underline underline-offset-2"
							onClick={() => navigate(`/new-draw/participation-type/${draw.id}`)}
						>
							Seu sorteio é do tipo Questionário e ainda não possui questões!
						</span>
					</CardDescription>
				)}
				{shouldNavigate && draw.type === DrawType.text && !draw.text?.text && (
					<CardDescription>
						<span
							className="cursor-pointer text-base font-normal text-warning underline underline-offset-2"
							onClick={() => navigate(`/new-draw/participation-type/${draw.id}`)}
						>
							Seu sorteio é do tipo Campo de texto e ainda não possui um texto auxiliar para o usuário!
						</span>
					</CardDescription>
				)}

				{draw.released_at && draw.end_date && (
					<CardDescription>
						<span className="text-base font-normal">
							Liberado durante {getFormattedDatesDifference(draw.released_at, draw.end_date)} - {dayjs(draw.end_date).format('DD/MM/YYYY')}
						</span>
					</CardDescription>
				)}

				<CardDescription className=" flex flex-col gm:flex-row gm:gap-4">
					<span className="text-sm font-normal">
						Criado {dayjs().to(draw.created_at)} - {dayjs(draw.created_at).format('DD/MM/YYYY')}
					</span>

					{shouldNavigate && draw.updated_at && (
						<span className="text-sm font-normal">
							Ultima edição {dayjs().to(draw.updated_at)} - {dayjs(draw.updated_at).format('DD/MM/YYYY')}
						</span>
					)}
				</CardDescription>
			</CardContent>
			{children}
		</Card>
	);
}
