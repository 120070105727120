import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useTheme } from '@/components/ui/theme-provider';
import { cleanPhoneNumber } from '@/functions/clean-phone-number';
import { formatCPF } from '@/functions/format-cpf';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { getZodErrors } from '@/functions/get-zod-errors';
import { useAuth } from '@/hooks/use-auth';
import api, { FrontBaseURL } from '@/lib/api';
import { CircularProgress, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Table } from '@tanstack/react-table';
import { QRCode } from 'antd';
import copy from 'copy-to-clipboard';
import { LucideClipboard, LucideDownload, LucideMinus, LucidePlus, LucideQrCode, LucideSearch, LucideUserPlus } from 'lucide-react';
import { ChangeEvent, useState } from 'react';
import { toast } from 'sonner';
import { z } from 'zod';
import { UsersDataTableViewOptions } from './users-data-table-view-options';

export const NewUserFormSchema = z.object({
	name: z.string({ required_error: 'Nome é um campo obrigatório.' }).trim(),
	phone: z.string({ required_error: 'Telefone é um campo obrigatório.' }).trim(),
	cpf: z.string({ required_error: 'CPF é um campo obrigatório.' }),
	accept_terms: z.boolean({
		invalid_type_error: 'Confirmação e concorcância dos termos deve ser boolean',
		required_error: 'Confirmação e concorcância dos termos é obrigatório',
	}),
	id: z.string().optional().nullable(),
});

export type UserForm = z.infer<typeof NewUserFormSchema>;

export interface UsersDataTableToolbarProps<TData> {
	table: Table<TData>;
	showTableViewOptions?: boolean;
}

export function UsersDataTableToolbar<TData>({ table, showTableViewOptions = true }: UsersDataTableToolbarProps<TData>) {
	const { user } = useAuth();
	const { theme } = useTheme();
	const modalCreateNewUser = useDisclosure();
	const modalShareSubscriptionLink = useDisclosure();

	const [isCreatingUser, setIsCreatingUser] = useState(false);

	const isFiltered = table.getState().columnFilters.length > 0;

	const initialNewUserDataState: UserForm = {
		name: '',
		cpf: '',
		phone: '',
		accept_terms: true,
	};

	const [newUserData, setNewUserData] = useState<UserForm>(initialNewUserDataState);

	const queryClient = useQueryClient();

	async function createUser(data: UserForm) {
		try {
			setIsCreatingUser(true);
			const parsedUserForm = await NewUserFormSchema.parseAsync(data);

			await api
				.post(`/person`, parsedUserForm, getHeadersAuthorization(user?.token!))
				.then(async () => {
					toast.success('Usuário criado com sucesso!');
					setNewUserData(initialNewUserDataState);
					return modalCreateNewUser.onClose();
				})
				.catch((err) => toast.error(err.response.data.message))
				.finally(() => setIsCreatingUser(false));
		} catch (error) {
			getZodErrors(error);
		}
	}

	const { mutateAsync: handleCreateUser } = useMutation(
		{
			mutationFn: async (newUserData: UserForm) => {
				await createUser(newUserData);
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['get-all-users'] });
			},
		},
		queryClient,
	);

	const [sizeQrCode, setQrCodeSize] = useState<number>(200);

	const increaseSizeQrCode = () => {
		setQrCodeSize((prevSize) => {
			const newSize = prevSize + 10;
			if (newSize > 300) {
				return 300;
			}
			return newSize;
		});
	};

	const declineSizeQrCode = () => {
		setQrCodeSize((prevSize) => {
			const newSize = prevSize - 10;
			if (newSize < 48) {
				return 48;
			}
			return newSize;
		});
	};

	const downloadQRCode = () => {
		const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
		if (canvas) {
			const url = canvas.toDataURL();
			const a = document.createElement('a');
			a.download = 'QRCode.png';
			a.href = url;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};

	return (
		<div className="group mt-4 flex w-full flex-col items-center justify-between gap-1 sm:flex-row">
			<div className="flex w-full flex-col justify-between gap-2  lg:flex-row lg:items-center">
				<div className="inline-flex w-full items-center justify-start gap-2">
					{isFiltered && (
						<Button variant="ghost" onClick={() => table.resetColumnFilters()} className=" place-self-start sm:place-self-center ">
							Apagar
							<Cross2Icon className=" h-4 w-4" />
						</Button>
					)}
					<Label htmlFor="search" className="relative inline-flex w-full items-center">
						<LucideSearch size={20} className="absolute left-2.5 top-2.5" />
						<Input
							id="search"
							placeholder="Buscar Usuários..."
							value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
							onChange={(event: any) => table.getColumn('name')?.setFilterValue(event.target.value)}
							className="min-h-10 w-full pl-10"
						/>
						{showTableViewOptions && <UsersDataTableViewOptions table={table} />}
					</Label>
				</div>
				<div className="flex flex-col items-center justify-end gap-2 sm:flex-row">
					<Button variant={'emphasis'} className="gap-2 self-end text-sm  lg:self-center" onClick={modalCreateNewUser.onOpen}>
						Novo Usuário
						<LucideUserPlus size={20} />
					</Button>
					<Button variant={'ghost'} className="gap-2 self-end text-sm text-primary lg:self-center" onClick={modalShareSubscriptionLink.onOpen}>
						Compartilhar inscrição
						<LucideQrCode size={20} />
					</Button>
				</div>
			</div>

			<Modal
				backdrop="blur"
				shouldBlockScroll
				isOpen={modalCreateNewUser.isOpen}
				onOpenChange={modalCreateNewUser.onOpenChange}
				classNames={{ backdrop: 'blur-sm', base: 'bg-background border' }}
			>
				<ModalContent>
					<ModalHeader>Novo Usuário</ModalHeader>
					<ModalBody>
						<Label htmlFor="name">
							Nome
							<Input
								id="name"
								placeholder="Insira um nome..."
								autoFocus
								value={newUserData.name}
								required
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNewUserData({ ...newUserData, name: e.target.value })}
							/>
						</Label>
						<Label htmlFor="cpf">
							CPF
							<Input
								id="cpf"
								placeholder="Apenas números"
								max={11}
								value={formatCPF(newUserData.cpf)}
								required
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									const formattedCPF = e.target.value.replace(/\D/g, '').slice(0, 11);
									setNewUserData({ ...newUserData, cpf: formattedCPF });
								}}
							/>
						</Label>
						<Label htmlFor="phone" className="flex-1">
							Nº Telefone
							<Input
								id="phone"
								placeholder="-- - --------"
								value={cleanPhoneNumber(newUserData.phone!)}
								required
								max={11}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setNewUserData({ ...newUserData, phone: cleanPhoneNumber(e.target.value!) ?? null })
								}
							/>
						</Label>
					</ModalBody>
					<ModalFooter>
						{isCreatingUser ? (
							<CircularProgress />
						) : (
							<Button disabled={!newUserData.name.trim() || !newUserData.cpf.trim()} onClick={() => handleCreateUser(newUserData)}>
								Concluir
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal
				backdrop="blur"
				shouldBlockScroll
				isOpen={modalShareSubscriptionLink.isOpen}
				onOpenChange={modalShareSubscriptionLink.onOpenChange}
				classNames={{ backdrop: 'blur-sm', base: 'bg-background border' }}
			>
				<ModalContent>
					<ModalHeader>Compartilhar Link</ModalHeader>
					<ModalBody className="flex flex-col gap-4">
						<div id="myqrcode">
							<QRCode
								includeMargin={true}
								rootClassName="mx-auto"
								icon={'/dices.svg'}
								iconSize={sizeQrCode / 4}
								size={sizeQrCode}
								color={theme === 'dark' ? 'white' : 'black'}
								value={`${FrontBaseURL}/create-user`}
							/>
						</div>
						<div className="flex items-center justify-center gap-2">
							<Button variant={`link`} onClick={() => downloadQRCode()}>
								Download <LucideDownload size={18} />
							</Button>
							<Button variant={`outline`} disabled={sizeQrCode <= 160} onClick={declineSizeQrCode}>
								<LucideMinus size={10} />
							</Button>
							<Button variant={`outline`} disabled={sizeQrCode >= 300} onClick={increaseSizeQrCode}>
								<LucidePlus size={10} />
							</Button>
						</div>
					</ModalBody>
					<ModalFooter>
						<Input id="link" value={`${FrontBaseURL}/create-user`} readOnly />

						<Button
							variant={'secondary'}
							size={'sm'}
							onClick={() => {
								copy(`${FrontBaseURL}/create-user`);
								return toast.success(`Link de Inscrição copiado para área de transferência`, {
									position: 'top-right',
								});
							}}
						>
							<LucideClipboard size={14} />
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</div>
	);
}
