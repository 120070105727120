import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import api from '@/lib/api';
import { Draw } from '@/pages/draw/utils/interfaces';

export interface QueryGetAllPaginatedDraws {
	data: {
		current_page: number;
		limit: number;
		total_pages: number;
		total_rows: number;

		results: DrawYear[];
	};
}

export interface DrawYear {
	year: string;
	months: DrawMonth[];
}

export interface DrawMonth {
	month: string;
	draws: Draw[];
}
export async function getAllPaginatedDraws(
	token: string,
	page: number,
	quantity?: number,
	search?: string,
	initial_date?: string,
	final_date?: string,
): Promise<QueryGetAllPaginatedDraws> {
	let params = `?page=${page}`;
	if (quantity) params += `&limit=${quantity}`;
	if (search) params += `&search=${search}`;
	if (initial_date) params += `&initial_date=${initial_date}`;
	if (final_date) params += `&final_date=${final_date}`;

	return await api.get(`/draw${params}`, getHeadersAuthorization(token));
}
