import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Table } from '@tanstack/react-table';
import { LucideChevronLeft, LucideChevronRight, LucideChevronsLeft, LucideChevronsRight } from 'lucide-react';

interface UsersDataTablePaginationProps<TData> {
	table: Table<TData>;
	showResize?: boolean;
}

export function UsersDataTablePagination<TData>({ table, showResize = true }: UsersDataTablePaginationProps<TData>) {
	const dontShowPagination = !table.getCanPreviousPage() && !table.getCanNextPage();

	const rowsSelected = table.getSelectedRowModel().rows.map((row: any) => {
		return row.original;
	});

	return (
		<div className="flex flex-1 flex-col items-center justify-between gap-2 px-1 text-left text-sm text-muted-foreground sm:flex-row">
			<div className="my-2 flex w-full items-center justify-between gap-2">
				{rowsSelected.length > 0 ? (
					<h1>
						{table.getFilteredSelectedRowModel().rows.length} de {table.getFilteredRowModel().rows.length} participante(s) selecionados
					</h1>
				) : (
					<h1>
						Mostrando {table.getRowModel().rows.length} de {table.getFilteredRowModel().rows.length} Usuário(s)
					</h1>
				)}
				<div data-should-hide={!showResize} className="flex items-center space-x-2 data-[should-hide=true]:hidden">
					<p className="text-sm font-medium">Linhas por pág.</p>
					<Select
						value={`${table.getState().pagination.pageSize}`}
						onValueChange={(value) => {
							table.setPageSize(Number(value));
						}}
					>
						<SelectTrigger className="h-8 w-[70px]">
							<SelectValue placeholder={table.getState().pagination.pageSize} />
						</SelectTrigger>
						<SelectContent side="top">
							{[5, 10, 20, 30, 40, 50].map((pageSize) => (
								<SelectItem key={pageSize} value={`${pageSize}`}>
									{pageSize}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
			</div>

			{!dontShowPagination && (
				<div className="flex items-center space-x-6 lg:space-x-8">
					<div className="flex w-[100px] items-center justify-center text-sm font-medium">
						Pág {table.getState().pagination.pageIndex + 1} de {table.getPageCount()}
					</div>
					<div className="flex items-center space-x-2">
						<Button
							variant="outline"
							className="hidden h-8 w-8 p-0 lg:flex"
							onClick={() => table.setPageIndex(0)}
							disabled={!table.getCanPreviousPage()}
						>
							<span className="sr-only">Go to first page</span>
							<LucideChevronsLeft className="h-4 w-4" />
						</Button>
						<Button variant="outline" className="h-8 w-8 p-0" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
							<span className="sr-only">Go to previous page</span>
							<LucideChevronLeft className="h-4 w-4" />
						</Button>
						<Button variant="outline" className="h-8 w-8 p-0" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
							<span className="sr-only">Go to next page</span>
							<LucideChevronRight className="h-4 w-4" />
						</Button>
						<Button
							variant="outline"
							className="hidden h-8 w-8 p-0 lg:flex"
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							disabled={!table.getCanNextPage()}
						>
							<span className="sr-only">Go to last page</span>
							<LucideChevronsRight className="h-4 w-4" />
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}
