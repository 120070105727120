import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import api from '@/lib/api';
import { Checkbox } from '@nextui-org/react';
import { LucideTrash2, LucideXCircle } from 'lucide-react';
import { ChangeEvent, Dispatch, HTMLAttributes, SetStateAction } from 'react';
import { toast } from 'sonner';
import { twMerge } from 'tailwind-merge';
import { QuestionaryForm } from '../../create-user-participation';

interface NewQuestionaryFormProps extends HTMLAttributes<HTMLDivElement> {
	id_draw?: string;
	questions: QuestionaryForm[];
	setQuestions: Dispatch<SetStateAction<QuestionaryForm[]>>;
	minimumHits: number | null;
	setMinimumHits: Dispatch<SetStateAction<number | null>>;
	token?: string;
}
export default function NewQuestionaryForm({
	id_draw,
	questions,
	setQuestions,
	token,
	minimumHits,
	setMinimumHits,
	className,
	...rest
}: NewQuestionaryFormProps) {
	const addQuestion = () => {
		setQuestions([
			...questions,
			{
				question: '',
				id_draw: id_draw!,
				answers: [
					{ answer: '', correct: false },
					{ answer: '', correct: false },
				],
			},
		]);
	};

	const removeQuestion = async (index: number) => {
		const questionToRemove = questions[index];
		setQuestions(questions.filter((_, i) => i !== index));
		if (questionToRemove && questionToRemove.id) {
			await api
				.delete(`/question/${questionToRemove.id}`, getHeadersAuthorization(token!))
				.then(() => toast.success(`Questão ${index + 1} removida com sucesso!`))
				.catch((err) => toast.error(err.respose.data.message));
		}
	};

	const handleQuestionChange = (questionIndex: number, value: string) => {
		const updatedQuestions = [...questions];
		const question = updatedQuestions[questionIndex];

		if (question) {
			question.question = value;
		}
		setQuestions(updatedQuestions);
	};

	const handleAnswerChange = (questionIndex: number, answerIndex: number, value: string) => {
		const updatedQuestions = [...questions];
		const question = updatedQuestions[questionIndex];

		if (question.answers && question.answers[answerIndex]) {
			question.answers[answerIndex].answer = value;
		}
		setQuestions(updatedQuestions);
	};

	const handleCorrectAnswerChange = async (questionIndex: number, answerIndex: number, value: boolean) => {
		const updatedQuestions = [...questions];
		const question = updatedQuestions[questionIndex];

		if (question.answers && question.answers[answerIndex]) {
			question.answers[answerIndex].correct = question.answers[answerIndex].correct ? false : value;
			setQuestions(updatedQuestions);
		}
	};

	const handleAddOption = (questionIndex: number) => {
		const updatedQuestions = [...questions];
		const question = updatedQuestions[questionIndex];

		if (question.answers) {
			question.answers.push({ answer: '', correct: false });
			setQuestions(updatedQuestions);
		}
	};

	const handleRemoveOption = async (questionIndex: number, answerIndex: number) => {
		const updatedQuestions = [...questions];
		const question = updatedQuestions[questionIndex];

		if (question.answers[answerIndex].correct === true) {
			await handleCorrectAnswerChange(questionIndex, answerIndex, false);
		}
		question.answers.splice(answerIndex, 1);
		setQuestions(updatedQuestions);
	};

	return (
		<div className={twMerge('flex flex-col gap-2', className)} {...rest}>
			<h1 className="mb-6 text-sm font-medium">Usuário irá responder um formulário</h1>
			<h1 className="mb-6 text-sm font-normal text-warning">Cuidado com caracteres especiais! Se tiver, não será salvo.</h1>

			<Label htmlFor="minimumHits" className="mb-6">
				Mínimo de acertos (opcional){' '}
				<Input
					id="minimumHits"
					type="number"
					value={minimumHits!}
					min={0}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setMinimumHits(Number(e.target.value)!)}
				/>
			</Label>

			{questions &&
				questions.map((question, questionIndex) => (
					<Card key={questionIndex} className="relative mb-4 rounded-lg border p-4">
						<div className="absolute -top-4 left-4  bg-background px-2">{questionIndex + 1}.</div>
						<CardHeader className="justify-end gap-2 p-1 px-0">
							<Input
								type="text"
								value={question.question}
								onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
								placeholder="Digite sua pergunta"
								className="mb-2 block w-full"
								required
							/>
							<Button size={'icon'} type="button" variant={'destructive'} onClick={() => removeQuestion(questionIndex)}>
								<LucideTrash2 size={16} />
							</Button>
						</CardHeader>
						<CardContent className="px-0">
							{question.answers &&
								question.answers.map((answer, answerIndex) => (
									<div key={answerIndex} className="mb-2 flex items-center gap-2">
										<LucideXCircle
											onClick={() => handleRemoveOption(questionIndex, answerIndex)}
											size={20}
											className="cursor-pointer text-destructive transition-all duration-150 hover:scale-110"
										/>
										<Input
											type="text"
											value={answer.answer}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												handleAnswerChange(questionIndex, answerIndex, e.target.value)
											}
											placeholder={`Resposta ${answerIndex + 1}`}
											required
										/>
										<Checkbox
											isSelected={answer.correct}
											onChange={() => handleCorrectAnswerChange(questionIndex, answerIndex, true)}
										/>
									</div>
								))}
							<Button type="button" variant={'fill'} onClick={() => handleAddOption(questionIndex)}>
								+1 opção
							</Button>
						</CardContent>
					</Card>
				))}
			<div className="flex items-center justify-between">
				<Button type="button" variant={'emphasis'} onClick={addQuestion}>
					Adicionar Questão
				</Button>
				<h1 className="font-normal text-muted-foreground">
					{questions.length > 0 && questions.length} {questions.length === 0 ? '' : questions.length === 1 ? 'questão' : 'questões'}
				</h1>
			</div>
		</div>
	);
}
