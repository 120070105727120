import { NextUIProvider } from '@nextui-org/system';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'sonner';
import { ThemeProvider, useTheme } from './components/ui/theme-provider';
import { AuthProvider } from './contexts/auth-context';
import { StatusConnectionProvider } from './contexts/status-connection-context';
import './index.css';
import Rotas from './routes';

const queryClient = new QueryClient();

function App() {
	const { theme } = useTheme();
	return (
		<QueryClientProvider client={queryClient}>
			<StatusConnectionProvider>
				<ThemeProvider defaultTheme="dark" storageKey="pormade-draw-ui-theme">
					<NextUIProvider>
						<AuthProvider>
							<Rotas />
							<Toaster
								richColors
								theme={theme}
								pauseWhenPageIsHidden
								closeButton
								toastOptions={{ className: 'p-4', classNames: { title: 'font-medium', description: 'font-normal' } }}
							/>
						</AuthProvider>
					</NextUIProvider>
				</ThemeProvider>
			</StatusConnectionProvider>
		</QueryClientProvider>
	);
}

export default App;
