const exportData = async (data: any, fileName: string, Type: string) => {
	const blob = new Blob([data], { type: Type });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
};

export default exportData;
