import { AnimatedText } from '@/components/animated-text';
import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CardFooter } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
import { ThemeToggle } from '@/components/ui/theme-toggle';
import { SlideFromTop } from '@/constants/animations/slide-from-top';
import { SlideInFromLeft, SlideInFromLeftItem } from '@/constants/animations/slide-in-from-left';
import { DrawType } from '@/constants/draw/draw-types';
import { getMonthText } from '@/functions/get-month-text';
import { DrawMonth, DrawYear, QueryGetAllPaginatedDraws } from '@/functions/tanstack-query/draws/get-all-paginated-draws';
import { getDrawAttendeeResponse } from '@/functions/tanstack-query/draws/get-attendee-response';
import { getDrawsByUserId } from '@/functions/tanstack-query/draws/get-draws-by-user-id';
import { getUser } from '@/functions/tanstack-query/users/get-user';
import DrawCard from '@/pages/draw/components/draw-card';
import AttendeeQuestionsResponse from '@/pages/draw/draw-attendees/components/attendee-questions-response';
import { Draw, DrawAttendee } from '@/pages/draw/utils/interfaces';
import { CircularProgress, Divider, Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from '@nextui-org/react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { isAfter } from 'date-fns';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

export default function MyDraws() {
	const { id, cpf } = useParams();
	const modalSeeResponse = useDisclosure();

	const [contentModalSeeResponse, setContentModalSeeResponse] = useState<Draw | null>(null);
	const handleOpenModalSeeResponse = (draw: Draw) => {
		setContentModalSeeResponse(draw);
		return modalSeeResponse.onOpen();
	};

	const initialQuantity: number = 30;

	const [page] = useState<number>(1);
	const [quantity] = useState<number>(initialQuantity);

	const { data: User } = useQuery({
		queryKey: ['get-user', cpf],
		queryFn: () => getUser(cpf!),
		enabled: !!cpf,
	});

	const [selectedDrawId, setSelectedDrawId] = useState<string | null>(null);

	const { data: AttendeeResponse } = useQuery({
		queryKey: ['get-attendee', selectedDrawId, id],
		queryFn: () => getDrawAttendeeResponse(selectedDrawId!, id!),
		enabled: !!selectedDrawId && !!id,
	});

	const {
		data: Draws,
		status,
		isLoading,
		hasNextPage,
		error,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['my-draws', id],
		queryFn: ({ pageParam }) => getDrawsByUserId(id!, pageParam, quantity),
		initialPageParam: page,
		getNextPageParam: ({ data }) => {
			const nextPage = data.total_pages > data.current_page ? data.current_page + 1 : undefined;
			return nextPage;
		},
		enabled: !!id,
	});

	const { ref, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage && !isLoading && !error) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isLoading, error, fetchNextPage]);

	const renderDraws = useMemo(() => {
		if (status === 'pending') {
			return <CircularProgress className="mx-auto" />;
		} else if (status === 'error') {
			return (
				<h1 className="mx-auto my-2 flex w-full items-center justify-center gap-1.5 text-center text-lg font-medium text-muted-foreground">
					Falha ao carregar dados -{' '}
					<span className="text-sm underline" onClick={() => window.location.reload()}>
						Recarregar
					</span>{' '}
				</h1>
			);
		} else {
			return (
				<div className="mb-10 space-y-4 overflow-x-hidden">
					{Draws &&
						Draws.pages.map((page: QueryGetAllPaginatedDraws, index) => {
							return (
								<Fragment key={index}>
									{page.data.results && page.data.results.length > 0 ? (
										page.data.results.map((year: DrawYear, yearIndex: number) => (
											<div key={yearIndex + 'year'} className="flex flex-col gap-2">
												<h1 className="flex items-center gap-4 text-xl font-medium">
													<Divider className="flex-1" />
													{year.year}
												</h1>
												{year.months.map((month: DrawMonth, monthIndex: number) => (
													<motion.div
														variants={SlideInFromLeft}
														initial="initial"
														animate="animate"
														key={monthIndex + 'month'}
														className="space-y-2"
													>
														<motion.h2
															variants={SlideInFromLeftItem}
															className=" flex items-center gap-4 rounded-bl-md border-l pl-3 text-lg font-normal capitalize"
														>
															<Badge variant={'secondary'} className="translate-y-3 font-normal">
																{getMonthText(Number(month.month))}
															</Badge>
														</motion.h2>
														<div className="translate-y-3 space-y-3 pl-3">
															{month.draws.map((draw: Draw, drawIndex: number) => {
																const prizeIsMoney = !/[^\d]/.test(draw.prize);
																return (
																	<motion.div key={drawIndex + 'draw'} variants={SlideInFromLeftItem}>
																		<DrawCard
																			draw={draw}
																			prizeIsMoney={prizeIsMoney}
																			shouldNavigate={false}
																		>
																			<CardFooter>
																				{isAfter(new Date(), draw.end_date!) ? (
																					<Button
																						onClick={() => {
																							setSelectedDrawId(draw.id);
																							handleOpenModalSeeResponse(draw);
																						}}
																					>
																						Ver minha(s) resposta(s)
																					</Button>
																				) : (
																					<h1 className="font-normal">
																						Você poderá ver suas respostas assim que acabar o
																						prazo de participação: {dayjs().to(draw.end_date)}
																					</h1>
																				)}
																			</CardFooter>
																		</DrawCard>
																	</motion.div>
																);
															})}
														</div>
													</motion.div>
												))}
											</div>
										))
									) : (
										<div className="mt-4 flex w-full justify-center text-2xl text-muted-foreground">
											<h1 className="mx-auto font-normal">Ainda não participou de sorteios!</h1>
										</div>
									)}
								</Fragment>
							);
						})}
					<div ref={ref} id="intersectionObserver" />
				</div>
			);
		}
	}, [status, Draws]);

	function renderUserResponse(draw: Draw, attendee: DrawAttendee) {
		if (!attendee) return null;
		if (draw.type === DrawType.questions && attendee.answered_questions) {
			return (
				<ModalBody className="space-y-2">
					<AnimatedText
						texts={['Acertou', attendee.hits === 0 ? 'nenhuma' : <h1 className="text-primary">{attendee.hits}</h1>]}
						className="text-xl font-normal"
					/>
					<ScrollArea className="h-[450px] pr-3">
						<AttendeeQuestionsResponse Attendee={attendee} />
					</ScrollArea>
				</ModalBody>
			);
		}
		if (draw.type === DrawType.text && attendee.text) {
			return (
				<ModalBody>
					<motion.div variants={SlideFromTop} initial="initial" animate="animate">
						<Textarea value={attendee.text!} readOnly className="min-h-36" />
					</motion.div>
				</ModalBody>
			);
		}
		if (draw.type === DrawType.list) return null;
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Page>
					<Template.Content>
						<motion.div
							variants={SlideInFromLeft}
							initial="initial"
							animate="animate"
							exit={{ x: 75 }}
							transition={{ duration: 0.7 }}
							className="flex flex-1 flex-col space-y-8 bg-background pb-4 "
						>
							<Header
								title={`Sorteios que ${User?.name ?? 'você'} participou`}
								description="Confira aqui as respostas dos sorteios que você participou"
							/>
							<ThemeToggle />

							{renderDraws}
						</motion.div>
					</Template.Content>
				</Template.Page>
			</Template.Root>

			<Modal
				backdrop="opaque"
				shouldBlockScroll
				classNames={{
					base: 'bg-background',
					backdrop: 'bg-gradient-to-t from-background/30 to-background/50 ',
					closeButton: `z-50`,
				}}
				size={'3xl'}
				isOpen={modalSeeResponse.isOpen}
				onOpenChange={modalSeeResponse.onOpenChange}
			>
				<ModalContent className="rounded-md bg-background p-4">
					<ModalHeader>
						<h1 className="mx-auto text-2xl font-semibold capitalize">{User?.name}</h1>
					</ModalHeader>
					{renderUserResponse(contentModalSeeResponse!, AttendeeResponse?.[0]!)}
				</ModalContent>
			</Modal>
		</Suspense>
	);
}
