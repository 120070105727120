import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import api from '@/lib/api';

export interface DrawReport {
	month: string;
	participants: number;
}
export async function getAttendeesReport(token: string, initial_date: string, final_date: string): Promise<DrawReport[]> {
	const res = await api.get(`/draw/report-participants/${initial_date}/${final_date}`, getHeadersAuthorization(token));
	return res.data;
}
