import { BreadcrumbResponsive } from '../responsive-breadcrumb';

interface HeaderProps {
	title: string;
	description: string;
	showBreadCrumb?: boolean;
}
export default function Header({ title, description, showBreadCrumb }: HeaderProps) {
	return (
		<div className="flex w-full flex-col gap-2">
			{showBreadCrumb && <BreadcrumbResponsive />}
			<h1 className="line-clamp-2 text-2xl font-semibold">{title} </h1>
			<h3 className=" border-b border-input font-normal text-muted-foreground">{description}</h3>
		</div>
	);
}
