import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface TemplateContentProps extends HTMLAttributes<HTMLDivElement> {}
export default function TemplateContent({ className, children, ...rest }: TemplateContentProps) {
	return (
		<div className={twMerge('h-full w-full space-y-4 p-2 md:p-8', className)} {...rest}>
			{children}
		</div>
	);
}
