import { useEffect, useState } from 'react';

interface useMediaQueryProps {
	breakpoint: number;
}

const useMediaQuery = ({ breakpoint = 640 }: useMediaQueryProps) => {
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	useEffect(() => {
		function handleResize() {
			setIsSmallScreen(window.innerWidth < breakpoint);
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, [breakpoint]);

	return isSmallScreen;
};

export default useMediaQuery;
