import { toast } from 'sonner';
import { z } from 'zod';

export function getZodErrors(error: unknown) {
	if (error instanceof z.ZodError) {
		error.errors.map((error) => {
			return toast.error(error.message);
		});
	} else {
		return toast.error('Ocorreu um erro ao processar os dados.');
	}
}
