import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface TemplateRootProps extends HTMLAttributes<HTMLElement> {}
export default function TemplateRoot({ className, children, ...rest }: TemplateRootProps) {
	return (
		<main className={twMerge('fixed flex min-h-screen w-full overflow-hidden bg-background', className)} {...rest}>
			{children}
		</main>
	);
}
