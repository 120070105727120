import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { User } from '@/interfaces/user';
import { ColumnDef } from '@tanstack/react-table';
import copy from 'copy-to-clipboard';
import { LucideCheckCircle, LucideChevronsUpDown, LucideMessageSquareShare, LucideMoreHorizontal, LucidePhone, LucideSettings } from 'lucide-react';
import { toast } from 'sonner';
import { UsersDataTableColumnHeader } from './users-data-table-columns-header';

export const UsersTableColumns: ColumnDef<User>[] = [
	{
		accessorKey: 'name',
		header: ({ column }) => {
			return <UsersDataTableColumnHeader column={column} title="Nome" className="justify-start" />;
		},
		cell: ({ row }) => {
			return (
				<div className=" flex w-full flex-1 items-center">
					<span className="line-clamp-1 font-medium">{row.original.name}</span>
				</div>
			);
		},
		enableSorting: true,
		enableHiding: true,
	},
	{
		accessorKey: 'phone',
		header: ({ column }) => {
			return <UsersDataTableColumnHeader column={column} title="Nº Telefone" className="justify-center" />;
		},
		cell: ({ row }) => {
			return (
				<div className="flex items-center justify-center">
					<span className="line-clamp-1 font-medium">{row.original.phone ?? '---'}</span>
				</div>
			);
		},
		enableSorting: true,
		enableHiding: true,
	},
	{
		accessorKey: 'cpf',
		header: ({ column }) => {
			return <UsersDataTableColumnHeader column={column} title="Cpf" icon={<LucideChevronsUpDown size={12} />} className=" justify-center" />;
		},
		cell: ({ row }) => {
			return (
				<div className="flex items-center justify-center">
					<span className="line-clamp-1 font-medium">{row.original.cpf}</span>
				</div>
			);
		},
		enableSorting: false,
		enableHiding: true,
		invertSorting: false,
	},
	{
		accessorKey: 'accept_terms',
		header: ({ column }) => {
			return <UsersDataTableColumnHeader column={column} title="Termo de uso" icon={<LucideChevronsUpDown size={12} />} className="justify-center" />;
		},
		cell: () => {
			return (
				<div className="flex items-center justify-center">
					<LucideCheckCircle className="text-primary" size={16} />
				</div>
			);
		},
		enableSorting: false,
		enableHiding: true,
	},
	{
		accessorKey: 'actions',
		header: ({ column }) => {
			return <UsersDataTableColumnHeader column={column} title="" icon={<LucideSettings size={16} />} className="justify-center" />;
		},
		cell: ({ row }) => {
			const handleRedirect = (href: string) => {
				if (href) {
					const newTab = window.open(href, '_blank');
					if (newTab) {
						newTab.focus();
					}
					return null;
				}
				return null;
			};

			return (
				<div className=" flex items-center justify-center">
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="outline" size={'sm'} className="">
								<span className="sr-only">Open menu</span>
								<LucideMoreHorizontal className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							<DropdownMenuLabel className="flex items-center justify-between">
								<h1 className="line-clamp-1 ">{row.original.name}</h1>
							</DropdownMenuLabel>
							<DropdownMenuSeparator />
							<DropdownMenuItem
								className="flex items-center justify-between gap-2"
								onClick={() => {
									handleRedirect(`https://wa.me/+55${row.original.phone}`);
								}}
							>
								Conversar <LucideMessageSquareShare size={16} />
							</DropdownMenuItem>
							<DropdownMenuItem
								className="flex items-center justify-between gap-2"
								onClick={() => {
									copy(`+55${row.original.phone}`);
									toast.success(`Número de ${row.original.name} - ${row.original.phone} copiado com sucesso!`);
								}}
							>
								Copiar número <LucidePhone size={16} />
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			);
		},
		enableSorting: false,
		enableHiding: false,
	},
];
