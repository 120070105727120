import { useConnection } from '@/hooks/use-connection';
import Dashboard from '@/pages/dashboard';
import Draw from '@/pages/draw';
import DrawList from '@/pages/draw-list';
import DrawQuestions from '@/pages/draw-questions';
import DrawText from '@/pages/draw-text';
import CreateDraw from '@/pages/draw/create-draw';
import CreateUserParticipationType from '@/pages/draw/create-draw/create-user-participation';
import DrawAttendees from '@/pages/draw/draw-attendees';
import DrawManagement from '@/pages/draw/draw-management';
import DrawResult from '@/pages/draw/draw-result';
import ReleaseDraw from '@/pages/draw/release-draw';
import Login from '@/pages/login';
import CreateUser from '@/pages/public-pages/create-user';
import MyDraws from '@/pages/public-pages/my-draws';
import TryMyLuck from '@/pages/public-pages/try-my-luck';
import Users from '@/pages/users';
import LostPage from '@/templates/lost';
import NotAccessConsolePage from '@/templates/not-access-console';
import WithoutConnection from '@/templates/without-connection';
import { AnimatePresence } from 'framer-motion';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PrivateRoutes } from './private/private-routes';

export default function Rotas() {
	const { isConnected } = useConnection();

	return (
		<AnimatePresence mode="wait" presenceAffectsLayout onExitComplete={() => null}>
			<Router key={location.pathname}>
				{!isConnected ? (
					<WithoutConnection />
				) : (
					<Routes>
						<Route path="/" element={<Login />} />

						<Route element={<PrivateRoutes />}>
							<Route path="/dashboard" element={<Dashboard />} />

							<Route path="/new-draw" element={<CreateDraw />} />
							<Route path="/new-draw/:id" element={<CreateDraw />} />
							<Route path="/new-draw/participation-type" element={<CreateUserParticipationType />} />
							<Route path="/new-draw/participation-type/:id" element={<CreateUserParticipationType />} />

							<Route path="/draw/:id" element={<Draw />} />

							<Route path="/draw/questions/:id" element={<DrawQuestions />} />
							<Route path="/draw/text/:id" element={<DrawText />} />
							<Route path="/draw/list/:id" element={<DrawList />} />

							<Route path="/draw/release/:id" element={<ReleaseDraw />} />
							<Route path="/draw/result/:id" element={<DrawResult />} />
							<Route path="/draw/attendees/:id" element={<DrawAttendees />} />

							<Route path="/draw-management" element={<DrawManagement />} />

							<Route path="/users" element={<Users />} />
						</Route>

						<Route path="/draw/try-my-luck/:id" element={<TryMyLuck />} />
						<Route path="/create-user" element={<CreateUser />} />
						<Route path="/my-draws/:id/:cpf" element={<MyDraws />} />

						<Route path="/nao-permitimos-acesso-ao-console" element={<NotAccessConsolePage />} />
						<Route path="*" element={<LostPage />} />
					</Routes>
				)}
			</Router>
		</AnimatePresence>
	);
}
