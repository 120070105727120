import Aside from '@/components/aside';
import { Button } from '@/components/ui/button';
import { LucidePlusCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function DrawManagementAsideActions() {
	const navigate = useNavigate();

	return (
		<Aside>
			<div className="flex flex-col gap-3">
				<Button onClick={() => navigate('/new-draw')} variant={'outline'} className="justify-between">
					<h1 className="line-clamp-1">Novo Sorteio</h1>
					<LucidePlusCircle size={18} className="scale-100 transition-all duration-300 group-hover:-rotate-180 group-hover:scale-110" />
				</Button>
			</div>
		</Aside>
	);
}
