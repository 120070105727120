import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { DrawAttendee } from '@/pages/draw/utils/interfaces';
import { Checkbox } from '@nextui-org/react';
import { twMerge } from 'tailwind-merge';

export default function AttendeeQuestionsResponse({ Attendee }: { Attendee: DrawAttendee }) {
	return (
		Attendee &&
		Attendee.answered_questions?.map((question, questionIndex) => {
			const hitQuestion = question.answers.some((answer) => answer.correct === answer.chosen);
			const cardClassName = twMerge(
				'relative my-6 w-full rounded-lg border p-4',
				hitQuestion && 'border-primary',
				!hitQuestion && 'border-destructive',
			);

			const auxText = hitQuestion ? 'Acertou' : 'Errou';
			return (
				<Card key={'question' + questionIndex} id={`question-${question.id}`} className={cardClassName}>
					<div className="absolute -top-4 left-4 bg-background px-2">
						{questionIndex + 1}. <span className="font-normal">{auxText}</span>
					</div>
					<CardHeader className="gap-2 p-1 ">
						<h1 className="mb-2 line-clamp-2 block w-full text-base hover:line-clamp-none">{question.question}</h1>
					</CardHeader>
					<CardContent className="px-0">
						{question.answers &&
							question.answers.map((answer, answerIndex) => {
								const correctAnswerClassName = 'ring-primary';
								const incorrectAnswerClassName = 'ring-input';
								const selectedQuestionClassName = 'ring-primary';

								return (
									<div key={'answer' + answerIndex} className="mb-2 flex items-center gap-2">
										<Input
											type="text"
											value={answer.answer}
											className={twMerge(
												answer.correct && answer.chosen
													? selectedQuestionClassName
													: answer.correct
														? correctAnswerClassName
														: incorrectAnswerClassName,
												' border-none ring-1 ring-offset-0 focus-visible:ring-input',
											)}
											placeholder={`Resposta ${answerIndex + 1}`}
											readOnly
										/>
										<Checkbox isSelected={answer.chosen!} />
									</div>
								);
							})}
					</CardContent>
				</Card>
			);
		})
	);
}
