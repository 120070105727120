import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { useTheme } from '@/components/ui/theme-provider';
import { SlideFromTop, SlideFromTopItem } from '@/constants/animations/slide-from-top';
import { SlideInFromLeft, SlideInFromLeftItem } from '@/constants/animations/slide-in-from-left';
import { DrawDisplay } from '@/constants/draw/draw-display';
import { DrawType } from '@/constants/draw/draw-types';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { getDraw } from '@/functions/tanstack-query/draws/get-draw';
import { useAuth } from '@/hooks/use-auth';
import useWindowSize from '@/hooks/use-window-size';
import api from '@/lib/api';
import { Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from '@nextui-org/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isBefore } from 'date-fns';
import { motion } from 'framer-motion';
import { LucideChevronLeft, LucideHome, LucideListCollapse, LucideMoon, LucidePartyPopper, LucideSave, LucideSettings, LucideSun } from 'lucide-react';
import { ChangeEvent, Suspense, useEffect, useState } from 'react';
import 'react-awesome-button/dist/styles.css';
import Confetti from 'react-confetti';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { twMerge } from 'tailwind-merge';
import SpinningWheel from '../draw/components/spinning-wheel/spinning-wheel';
import { DrawWinnerList, ListSeparator, ViewMode } from '../draw/utils/interfaces';

export default function DrawList() {
	const { id } = useParams();
	const { user } = useAuth();
	const { setTheme, theme } = useTheme();
	const { width, height } = useWindowSize();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [searchParams, setSearchParams] = useSearchParams();

	const modalListWinners = useDisclosure();
	const modalWinner = useDisclosure();
	const modalAlreadyDrawed = useDisclosure();

	const [list, setList] = useState<string>('');
	const [listSeparator, setListSeparator] = useState<ListSeparator>('enter');
	const [viewMode, setViewMode] = useState<ViewMode>('textArea');

	const initialWinnerState = { win: false, winner: undefined, position: undefined };
	const [winner, setWinner] = useState<DrawWinnerList>(initialWinnerState);
	const [winners, setWinners] = useState<DrawWinnerList[]>([]);
	const [isSorting, setIsSorting] = useState(false);
	const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

	const drawCount = searchParams.get('draw-count') ?? 0;

	const handleChangeParams = (paramName: string, newValue: string) => {
		setSearchParams((oldSearchParams) => {
			const params = new URLSearchParams(oldSearchParams);
			params.set(paramName, newValue);
			return params;
		});
	};

	async function saveDrawCountInURL() {
		const currentValue = Number(searchParams.get('draw-count') ?? 0);
		const newValue = currentValue + 1;

		handleChangeParams('draw-count', String(newValue));
	}

	const { data: Draw } = useQuery({
		queryKey: ['get-draw-id', id],
		queryFn: () => getDraw(user?.token!, id!),
		enabled: !!user?.token && !!id,
	});

	const prizeIsMoney = !/[^\d]/.test(Draw?.prize!);

	useEffect(() => {
		if (Draw && Draw.end_date && isBefore(new Date(), Draw.end_date)) {
			navigate(`/draw/release/${id}`);
		}
	}, [Draw]);

	const handleListChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setList(e.target.value);
	};

	const parseList = (list: string, separator: ListSeparator) => {
		return (separator === 'comma' ? list.split(',') : list.split('\n')).map((item) => item.trim()).filter((item) => item !== '');
	};

	const getRandomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

	const initialDelay = 60;
	const limit = 50;

	const [count, setCount] = useState(limit);
	const [delay, setDelay] = useState(initialDelay);
	const [progress, setProgress] = useState(0);

	const shouldRandomizeName = async () => {
		if (count < limit) {
			await getRandomAttendee();

			setCount(count + 1);
			switch (count) {
				case limit * 0.5:
					setDelay(delay * 2);
					break;
				case limit * 0.7:
					setDelay(delay * 1.5);
					break;
				case limit * 0.8:
					setDelay(delay * 1.2);
					break;
				default:
					break;
			}
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			setProgress((oldProgress) => Math.min(oldProgress + 3, 100));
			shouldRandomizeName();
		}, delay);

		if (count >= limit) {
			clearInterval(intervalId);
			setProgress(0);
		}

		return () => clearInterval(intervalId);
	}, [count, delay, shouldRandomizeName]);

	function rotate() {
		if (isSorting) return;

		const wheel = document.querySelector('.wheel') as HTMLElement | null;

		let newDeg = Math.ceil(Math.random() * 3600);
		if (wheel) {
			wheel.style.transform = 'rotate(' + newDeg + 'deg)';
			newDeg += Math.ceil(Math.random() * 3600);
		}
	}

	const verifyIfWinnerAlreadyExists = async (winners: DrawWinnerList[], currentWinner: DrawWinnerList) => {
		const alreadyDrawed = winners.some((winner) => winner.winner === currentWinner.winner);

		if (!alreadyDrawed) currentWinner.win = true;

		return alreadyDrawed;
	};

	const storeWinner = (winner_position: number, winner: string, delay: number) => {
		if (searchTimeout) {
			clearTimeout(searchTimeout);
		}
		let timeout = setTimeout(async () => {
			setIsSorting(false);
			const newWinner = { win: false, winner, position: winner_position + 1 };
			await saveDrawCountInURL();

			const alreadyDrawed = await verifyIfWinnerAlreadyExists(winners, newWinner);

			if (alreadyDrawed) return modalAlreadyDrawed.onOpen();

			setWinner(newWinner);
			setWinners((old) => [...old, newWinner]);
			modalWinner.onOpen();
		}, delay);

		setSearchTimeout(timeout);
	};

	const getRandomAttendee = async () => {
		setIsSorting(true);
		setCount(0);
		setWinner(initialWinnerState);

		const attendees = parseList(list, listSeparator);

		if (attendees && attendees.length > 0) {
			setDelay(initialDelay);

			const winner_position = getRandomInteger(0, attendees.length - 1);
			const winner = attendees[winner_position];

			setWinner({ win: false, winner, position: winner_position + 1 });
			storeWinner(winner_position, winner, 500);
		}
	};

	const handleClickSpinning = async () => {
		const attendees: string[] = parseList(list, listSeparator);
		if (attendees && attendees.length <= 1) return toast.warning('Não há itens suficientes para sortear.');

		rotate();
		getRandomAttendee();
	};

	async function handleSaveDraw() {
		const formattedWinners = winners.map((winner) => winner.winner);
		const formattedList = parseList(list, listSeparator);

		const data = {
			id_draw: id,
			list: formattedList,
			winners: formattedWinners!,
		};
		await api
			.post(`/list`, data, getHeadersAuthorization(user?.token!))
			.then(async () => {
				toast.success('Lista salva com sucesso!');

				return await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
			})
			.catch((err) => toast.error(err.response.data.message));
	}

	const renderSettingsButton = () => {
		return (
			<DropdownMenu>
				<DropdownMenuTrigger className="absolute bottom-4 left-4">
					<Button variant={'emphasis'} className=" h-12 w-12 rounded-full p-0">
						<LucideSettings size={28} className="stroke-1 transition-all duration-500 group-hover:rotate-180" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start" alignOffset={4}>
					<DropdownMenuGroup>
						{viewMode === 'drawDisplay' && <DropdownMenuItem onClick={() => setViewMode('textArea')}>Editar Lista</DropdownMenuItem>}
						<DropdownMenuSub>
							<DropdownMenuSubTrigger className="font-normal">Separador</DropdownMenuSubTrigger>
							<DropdownMenuPortal>
								<DropdownMenuSubContent className="ml-1.5" alignOffset={1}>
									<DropdownMenuRadioGroup value={listSeparator} onValueChange={(value) => setListSeparator(value as ListSeparator)}>
										<DropdownMenuRadioItem value="comma" className="font-normal">
											Vírgula
										</DropdownMenuRadioItem>
										<DropdownMenuRadioItem value="enter" className="font-normal">
											Enter
										</DropdownMenuRadioItem>
									</DropdownMenuRadioGroup>
								</DropdownMenuSubContent>
							</DropdownMenuPortal>
						</DropdownMenuSub>
						<DropdownMenuItem className="justify-between" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
							Tema <LucideSun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
							<LucideMoon className="h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
						</DropdownMenuItem>
					</DropdownMenuGroup>
				</DropdownMenuContent>
			</DropdownMenu>
		);
	};

	const renderListLayout = () => {
		return (
			<div className="h-full w-full space-y-3">
				{viewMode === 'textArea' ? (
					<div className="mx-auto flex w-full flex-col gap-3 md:w-[75%]">
						<Label htmlFor="listField">
							Separador: {listSeparator === 'comma' ? '"Vírgulas"' : '"Enter"'}
							<Textarea
								placeholder={`Insira a lista, separada por ${listSeparator === 'comma' ? '"Vírgulas"' : '"Enter"'}`}
								className="min-h-60 resize-none"
								id="listField"
								value={list}
								required
								onChange={handleListChange}
								autoFocus
							/>
						</Label>
						<Button
							onClick={() =>
								parseList(list, listSeparator).length > 1
									? setViewMode('drawDisplay')
									: toast.warning('Adicione no mínimo 2 elemento para sortear')
							}
							className="self-end"
						>
							Sortear Lista <LucidePartyPopper size={18} />
						</Button>
					</div>
				) : (
					renderDrawDisplay()
				)}
			</div>
		);
	};

	const renderDrawDisplay = () => {
		if (!Draw) return;
		if (Draw.display === DrawDisplay.wheel) {
			return (
				<div className="my-8 flex h-full flex-col items-center justify-center gap-4">
					<SpinningWheel
						spinText={winner && winner.position !== undefined ? String(winner.position) : 'Girar'}
						isSorting={isSorting}
						progress={progress}
						onClick={handleClickSpinning}
					/>
				</div>
			);
		}
		return (
			<div className="my-8 flex h-full flex-col items-center justify-center gap-4">
				<div className="relative flex w-full items-center gap-2 sm:w-[80%]">
					<div className="flex h-24 w-full items-center justify-center  overflow-hidden rounded-lg border-[#121212] bg-background p-4 shadow-inner-custom-light dark:border dark:shadow-inner-custom-dark">
						<h1 className="line-clamp-1 text-center text-2xl capitalize">{winner.winner}</h1>
					</div>
				</div>

				<Button className="w-full sm:w-auto" disabled={isSorting} size={'lg'} onClick={handleClickSpinning}>
					Sortear
					<LucidePartyPopper size={18} />
				</Button>
			</div>
		);
	};

	if (Draw?.drawed_at) navigate(`/draw/result/${id}`);

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Page>
					<Template.Content>
						<motion.div
							variants={SlideInFromLeft}
							initial="initial"
							animate="animate"
							exit={{ x: 75 }}
							transition={{ duration: 0.7 }}
							className="h-full space-y-4 overflow-hidden p-1"
						>
							{winner && winner.win && <Confetti numberOfPieces={250} recycle={false} width={width} height={height} />}
							<motion.div variants={SlideInFromLeftItem}>
								<Header title={Draw?.title! ?? 'Sorteio'} description={`sorteios realizados: ${drawCount}`} />
							</motion.div>

							<Button variant={'outline'} className="h-9 w-9 p-0 hover:w-14" onClick={() => navigate('/dashboard')}>
								<LucideChevronLeft
									size={18}
									className="absolute translate-x-4 scale-0 transition-all duration-300 group-hover:-translate-x-3 group-hover:scale-100"
								/>
								<LucideHome size={18} className="transition-all duration-300 group-hover:translate-x-2" />
							</Button>

							<motion.div variants={SlideInFromLeftItem}>
								{Draw && Draw.type === DrawType.list ? renderListLayout() : renderDrawDisplay()}
							</motion.div>

							{viewMode === 'drawDisplay' && winners.length > 0 && (
								<motion.div
									variants={SlideInFromLeftItem}
									className={twMerge(
										Draw?.display === DrawDisplay.wheel ? 'justify-center' : 'justify-center pt-8 md:justify-end',
										'flex flex-col items-center gap-2 sm:flex-row',
									)}
								>
									<Button className="w-full sm:w-auto" disabled={isSorting} variant={'emphasis'} onClick={modalListWinners.onOpen}>
										Ganhadores <LucideListCollapse size={18} />
									</Button>
									<Button className="w-full sm:w-auto" disabled={isSorting} onClick={() => handleSaveDraw()}>
										Salvar sorteio <LucideSave size={18} />
									</Button>
								</motion.div>
							)}
						</motion.div>
						{renderSettingsButton()}
					</Template.Content>
				</Template.Page>

				<Modal
					backdrop="opaque"
					shouldBlockScroll
					classNames={{
						base: 'bg-background',
						backdrop: 'bg-gradient-to-t from-background/30 to-background/50 ',
						closeButton: `z-50`,
					}}
					size={'3xl'}
					isOpen={modalWinner.isOpen}
					onOpenChange={modalWinner.onOpenChange}
				>
					<ModalContent className="rounded-md bg-background p-4">
						<ModalHeader>
							<h1 className="mx-auto text-2xl font-semibold capitalize">{winner.winner}</h1>
						</ModalHeader>
						<ModalBody>
							<motion.div
								variants={SlideFromTop}
								initial="initial"
								animate="animate"
								className="flex items-center justify-center gap-2 text-pretty text-xl"
							>
								<motion.h1 variants={SlideFromTopItem}>Parabéns, </motion.h1>
								<motion.h1 variants={SlideFromTopItem}>você ganhou </motion.h1>
								<motion.h1 variants={SlideFromTopItem} className="text-primary">
									{prizeIsMoney ? `R$${Draw?.prize!}` : Draw?.prize!}
								</motion.h1>
							</motion.div>
						</ModalBody>
					</ModalContent>
				</Modal>

				<Modal
					backdrop="blur"
					shouldBlockScroll
					classNames={{
						base: 'bg-background',
						backdrop: 'bg-gradient-to-t from-background/50 to-background/70 ',
						closeButton: `z-50`,
					}}
					size={'3xl'}
					isOpen={modalAlreadyDrawed.isOpen}
					onOpenChange={modalAlreadyDrawed.onOpenChange}
				>
					<ModalContent className="rounded-md bg-background p-4">
						<ModalHeader className="text-center">
							<h1 className="mx-auto text-center text-2xl">{winner.winner} já foi sorteado!</h1>
						</ModalHeader>
					</ModalContent>
				</Modal>

				<Modal
					backdrop="blur"
					shouldBlockScroll
					classNames={{
						base: 'bg-background',
						backdrop: 'bg-gradient-to-t from-background/50 to-background/70 ',
						closeButton: `z-50`,
					}}
					size={'3xl'}
					isOpen={modalListWinners.isOpen}
					onOpenChange={modalListWinners.onOpenChange}
				>
					<ModalContent className="rounded-md bg-background p-4">
						<ModalHeader>
							<h1>Ganhadores:</h1>
						</ModalHeader>
						<ModalBody className="flex max-h-[325px] overflow-y-auto ">
							<motion.div variants={SlideFromTop} initial="initial" animate="animate" className="w-full flex-col gap-2 space-y-2">
								{winners &&
									winners.map((winner, index) => {
										return (
											<motion.div
												key={index}
												variants={SlideFromTopItem}
												className="flex h-12 w-full items-center gap-2 rounded-md bg-muted p-4"
											>
												<h1 className="line-clamp-1 text-lg capitalize">{winner.winner}</h1>
											</motion.div>
										);
									})}
							</motion.div>
						</ModalBody>
					</ModalContent>
				</Modal>
			</Template.Root>
		</Suspense>
	);
}
