import Header from '@/components/header';
import LoadingPage from '@/components/loading-page';
import { Template } from '@/components/template';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { CardFooter } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { SlideInFromLeft, SlideInFromLeftItem } from '@/constants/animations/slide-in-from-left';
import { DrawType } from '@/constants/draw/draw-types';
import { getHeadersAuthorization } from '@/functions/get-headers-authorization';
import { getMonthText } from '@/functions/get-month-text';
import { getZodErrors } from '@/functions/get-zod-errors';
import { DrawMonth, DrawYear, QueryGetAllPaginatedDraws, getAllPaginatedDraws } from '@/functions/tanstack-query/draws/get-all-paginated-draws';
import { getDraw } from '@/functions/tanstack-query/draws/get-draw';
import { useAuth } from '@/hooks/use-auth';
import useDebounce from '@/hooks/use-debounce';
import useMediaQuery from '@/hooks/use-media-query';
import api from '@/lib/api';
import {
	CircularProgress,
	Divider,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	Radio,
	RadioGroup,
	Tab,
	Tabs,
	useDisclosure,
} from '@nextui-org/react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { LucideListTodo, LucideMenu, LucideNotepadText, LucidePen, LucidePlusCircle, LucideSearch, LucideUsers2 } from 'lucide-react';
import { ChangeEvent, Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { z } from 'zod';
import { EditDrawFormSchema } from '..';
import DrawCard from '../../components/draw-card';
import { Draw } from '../../utils/interfaces';
import CreateDrawAsideActions from '../components/aside-actions';
import NewQuestionaryForm from '../components/forms/new-questionary-form';
import NewTextFieldForm from '../components/forms/new-text-field-form';

const TextFieldFormSchema = z.object({
	label: z.string({ required_error: 'Rótulo é um campo obrigatório.' }).min(10, { message: 'Rótulo deve ter no mínimo 10 caracteres.' }).trim(),
	text: z
		.string({ required_error: 'Texto auxiliar é um campo obrigatório.' })
		.min(10, { message: 'Texto auxiliar deve ter no mínimo 10 caracteres.' })
		.trim(),
	minimum_words: z.number().optional().nullable(),
	id_draw: z.string({ required_error: 'Sorteio é um campo obrigatório.' }).trim(),
});

const AnswerSchema = z.object({
	id: z.string().optional(),
	answer: z.string({ required_error: 'Opção é um campo obrigatório.' }).trim(),
	chosen: z.boolean().optional().nullable(),
	correct: z.boolean(),
	id_question: z.string().optional(),
});

const QuestionFormSchema = z.object({
	id: z.string().optional(),
	id_draw: z.string().optional(),
	question: z.string({ required_error: 'Pergunta é um campo obrigatório' }).trim(),
	answers: z
		.array(AnswerSchema, { required_error: 'A pergunta deve conter opções de resposta' })
		.min(2, { message: 'Cada pergunta deve ter no mínimo 2 opções' }),
});

type TextFieldForm = z.infer<typeof TextFieldFormSchema>;
export type QuestionaryForm = z.infer<typeof QuestionFormSchema>;

export default function CreateUserParticipationType() {
	const { id } = useParams();
	const { user } = useAuth();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const isMobile = useMediaQuery({ breakpoint: 640 });

	const initialQuantity: number = 30;

	const [page, setPage] = useState<number>(1);
	const [quantity, setQuantity] = useState<number>(initialQuantity);

	const [searchParams, setSearchParams] = useSearchParams();

	const search = searchParams.get('search') ?? '';
	const searchWDelay = useDebounce(search, 500);

	async function handleSearch(e: ChangeEvent<HTMLInputElement>) {
		updateURLParams('search', e.target.value);
	}

	const updateURLParams = (key: string, value: string) => {
		setSearchParams((oldParams) => {
			const newParams = new URLSearchParams(oldParams.toString());
			newParams.set(key, value);
			return newParams;
		});
	};

	useEffect(() => {
		if (searchWDelay) {
			setPage(1);
			setQuantity(initialQuantity);
		}
	}, [searchWDelay]);

	const [selectedDrawId, setSelectedDrawId] = useState<string | undefined>(id ?? undefined);
	const [newDrawType, setNewDrawType] = useState<string | null>(null);

	const handleOpenModalEditDraw = (actualDrawType: string) => {
		setNewDrawType(actualDrawType);
		modalEditDrawType.onOpen();
	};
	const modalEditDrawType = useDisclosure();
	const modalSelectDrawToCreateUserParticipation = useDisclosure();

	const { data: Draw, error } = useQuery({
		queryKey: ['get-draw-id', selectedDrawId],
		queryFn: () => getDraw(user?.token!, selectedDrawId!),
		enabled: !!user?.token && !!selectedDrawId,
		retry: false,
	});

	useEffect(() => {
		if (Draw) {
			if (Draw.questions) {
				setMinimumHits(Draw.minimum_hits);
				setQuestions(JSON.parse(JSON.stringify(Draw.questions)));
			}
			if (Draw.text) {
				setLabel(Draw.text.label);
				setPlaceholder(Draw.text.text);
				setMinimumWords(Draw.text.minimum_words);
			}
		}
		return;
	}, [Draw]);

	const initialQuestionsState: QuestionaryForm[] = [
		{
			question: '',
			id_draw: id! ?? Draw?.id!,
			answers: [
				{
					answer: '',
					correct: false,
				},
				{
					answer: '',
					correct: false,
				},
			],
		},
	];

	const [questions, setQuestions] = useState<QuestionaryForm[]>(initialQuestionsState);
	const [minimumHits, setMinimumHits] = useState<number | null>(null);
	const [label, setLabel] = useState('Comentário');
	const [placeholder, setPlaceholder] = useState('');
	const [minimumWords, setMinimumWords] = useState<number | null>(null);

	async function cleanArrayOfQuestions(newQuestions: QuestionaryForm[], existingQuestions?: QuestionaryForm[]) {
		if (!existingQuestions) return newQuestions;

		const editedQuestions: QuestionaryForm[] = [];

		newQuestions.forEach((newQuestion) => {
			const existingQuestion = existingQuestions.find((existingQ) => existingQ.id === newQuestion.id);

			if (!existingQuestion) {
				editedQuestions.push(newQuestion);
				return;
			}

			if (
				JSON.stringify(existingQuestion.question) !== JSON.stringify(newQuestion.question) ||
				JSON.stringify(existingQuestion.answers) !== JSON.stringify(newQuestion.answers)
			) {
				editedQuestions.push(newQuestion);
			}
		});

		return editedQuestions;
	}

	async function createDrawUserParticipation(data: TextFieldForm | QuestionaryForm[], existingQuestions?: QuestionaryForm[], existingMinimumHits?: number) {
		if (Array.isArray(data)) {
			if (minimumHits !== null && minimumHits !== existingMinimumHits) {
				await handleUpdateMinimumHits(minimumHits);
			}

			const filteredArray = await cleanArrayOfQuestions(data, existingQuestions);

			if (!filteredArray.length) return toast.warning('Você não alterou nenhuma questão ou opção de resposta.');

			filteredArray.forEach(async (question, index) => {
				const indexOfAlteredQuestion = data.findIndex((quest) => quest.id === question.id);

				const { id, ...questionWithoutId } = question;

				if (id) {
					try {
						const parsedQuestionForm = await QuestionFormSchema.parseAsync(questionWithoutId);

						await api
							.patch(`/question/${id}`, parsedQuestionForm, getHeadersAuthorization(user?.token!))
							.then(async () => {
								toast.success(`Questão ${indexOfAlteredQuestion + 1} editada com sucesso!`);
								return await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
							})
							.catch((err) => toast.error(err.response.data.message));
					} catch (err) {
						getZodErrors(err);
					}
				} else {
					try {
						const parsedQuestionForm = await QuestionFormSchema.parseAsync(questionWithoutId);

						await api
							.post('/question', parsedQuestionForm, getHeadersAuthorization(user?.token!))
							.then(async () => {
								toast.success(`Questão ${index + 1} adicionada com sucesso!`);
								return await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
							})
							.catch((err) => toast.error(err.response.data.message));
					} catch (err) {
						getZodErrors(err);
					}
				}
			});
		} else {
			if (Draw && Draw.text) {
				try {
					const parsedTextForm = await TextFieldFormSchema.parseAsync(data);

					return await api
						.patch(`/text/${Draw.text.id}`, parsedTextForm, getHeadersAuthorization(user?.token!))
						.then(async () => {
							toast.success(`Campo de texto editado com sucesso!`);
							return await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
						})
						.catch((err) => toast.error(err.response.data.message));
				} catch (err) {
					getZodErrors(err);
				}
			} else {
				try {
					const parsedTextForm = await TextFieldFormSchema.parseAsync(data);

					return await api
						.post('/text', parsedTextForm, getHeadersAuthorization(user?.token!))
						.then(async () => {
							toast.success(`Campo de texto adicionado com sucesso!`);
							return await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
						})
						.catch((err) => toast.error(err.response.data.message));
				} catch (err) {
					getZodErrors(err);
				}
			}
		}
	}

	const { mutateAsync: handleCreateDrawUserParticipation } = useMutation(
		{
			mutationFn: async () => {
				if (!Draw) return modalSelectDrawToCreateUserParticipation.onOpen();

				let data: TextFieldForm | QuestionaryForm[] | null = null;
				if (Draw.type === DrawType.text) {
					data = {
						label: label,
						text: placeholder,
						minimum_words: minimumWords,
						id_draw: Draw.id,
					};
				} else {
					data = questions;
				}

				await createDrawUserParticipation(data!, Draw.questions!, Draw.minimum_hits!);
			},
		},
		queryClient,
	);

	async function updateDrawType(new_draw_type: string) {
		if (!Draw?.id) return toast.error('Erro ao atualizar dados, recarregue e tente novamente');
		try {
			const parsedEditedDrawForm = await EditDrawFormSchema.parseAsync({ type: new_draw_type });
			await api
				.patch(`/draw/${Draw.id}`, parsedEditedDrawForm, getHeadersAuthorization(user?.token!))
				.then(() => {
					return toast.success('Tipo atualizado com sucesso!');
				})
				.catch((err) => toast.error(err.response.data.message));
		} catch (err) {
			getZodErrors(err);
		}
	}

	const { mutateAsync: handleUpdateDrawType } = useMutation(
		{
			mutationFn: async (new_draw_type: string) => {
				await updateDrawType(new_draw_type);
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
			},
		},
		queryClient,
	);

	async function updateMinimumHits(minimum_hits: number) {
		if (!Draw?.id) return toast.error('Erro ao atualizar dados, recarregue e tente novamente');
		try {
			const parsedEditedDrawForm = await EditDrawFormSchema.parseAsync({ minimum_hits: minimum_hits });
			await api
				.patch(`/draw/${Draw.id}/minimum-hits`, parsedEditedDrawForm, getHeadersAuthorization(user?.token!))
				.then(() => {
					return toast.success('mínimo de acertos atualizado com sucesso!');
				})
				.catch((err) => toast.error(err.response.data.message));
		} catch (err) {
			getZodErrors(err);
		}
	}

	const { mutateAsync: handleUpdateMinimumHits } = useMutation(
		{
			mutationFn: async (minimum_hits: number) => {
				await updateMinimumHits(minimum_hits);
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['get-draw-id'] });
			},
		},
		queryClient,
	);

	function renderTypeDrawAuxMessage(type: string) {
		switch (type) {
			case DrawType.questions:
				return 'Você definiu esse sorteio como um questionário. Para mudar ';
			case DrawType.list:
				return 'Você definiu esse sorteio como uma lista. Para mudar ';
			default:
				return 'Você definiu esse sorteio como campo de texto. Para mudar ';
		}
	}

	function renderForms() {
		return (
			<motion.div
				variants={SlideInFromLeft}
				initial="initial"
				animate="animate"
				exit={{ x: 75 }}
				transition={{ duration: 0.7 }}
				className="flex flex-1 flex-col space-y-8 bg-background pb-4 md:max-w-2xl"
			>
				<div className="flex w-full items-center gap-1.5">
					<Header title={Draw ? Draw.title : 'Participação'} description="Defina aqui como o usuário irá participar do sorteio" showBreadCrumb />
					{Draw && (
						<Button variant={'outline'} size="icon" onClick={() => navigate(`/new-draw/${Draw.id}`)}>
							<LucidePen size={18} />
						</Button>
					)}
				</div>

				{Draw && Draw.type && (
					<h1 className="font-normal">
						{renderTypeDrawAuxMessage(Draw.type)}
						<code className="cursor-pointer text-primary underline underline-offset-1" onClick={() => handleOpenModalEditDraw(Draw.type)}>
							clique aqui
						</code>
					</h1>
				)}

				<Tabs
					selectedKey={Draw?.type!}
					isVertical={isMobile}
					variant="bordered"
					color="primary"
					classNames={{ tabList: `border-1 sm:w-auto w-full`, wrapper: isMobile && 'flex-col flex gap-8' }}
					aria-label="Options"
				>
					<Tab
						isDisabled={Draw && (Draw.type === DrawType.list || Draw.type === DrawType.text)}
						key="questions"
						title={
							<div className="flex items-center gap-2">
								<LucideListTodo size={18} />
								<h1 className="text-sm font-medium">Questionário</h1>
							</div>
						}
					>
						<NewQuestionaryForm
							token={user?.token!}
							minimumHits={minimumHits}
							setMinimumHits={setMinimumHits}
							id_draw={Draw?.id}
							questions={questions}
							setQuestions={setQuestions}
						/>
					</Tab>
					<Tab
						isDisabled={Draw && (Draw.type === DrawType.list || Draw.type === DrawType.questions)}
						key="text"
						title={
							<div className="flex items-center gap-2">
								<LucideNotepadText size={18} />
								<h1 className="text-sm font-medium">Campo de texto</h1>
							</div>
						}
					>
						<NewTextFieldForm
							label={label}
							setLabel={setLabel}
							placeholder={placeholder}
							setPlaceholder={setPlaceholder}
							minimumWords={minimumWords}
							setMinimumWords={setMinimumWords}
						/>
					</Tab>
					<Tab
						isDisabled={Draw && (Draw.type === DrawType.text || Draw.type === DrawType.questions)}
						key="list"
						title={
							<div className="flex items-center gap-2">
								<LucideUsers2 size={18} />
								<h1 className="text-sm font-medium">Lista de participantes</h1>
							</div>
						}
					>
						<h1 className="mb-6 text-sm font-medium">O sorteio irá requisitar uma lista manual de participantes!</h1>
					</Tab>
				</Tabs>

				<Button disabled={disableSaveButton()} className="self-end" onClick={() => handleCreateDrawUserParticipation()}>
					Salvar
				</Button>
			</motion.div>
		);
	}

	function disableSaveButton(): boolean | undefined {
		if (Draw) {
			if (Draw.type === DrawType.questions) {
				const shouldDisable = questions.length === 0 || minimumHits! > questions.length;
				return shouldDisable;
			} else if (Draw.type === DrawType.text) {
				return !!(label.length === 0 || placeholder.length === 0);
			} else if (Draw.type === DrawType.list) {
				return false;
			} else {
				return false;
			}
		}

		return undefined;
	}

	const {
		data: Draws,
		status,
		isLoading,
		hasNextPage,
		error: ErrorOnLoadDraws,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['get-all-draws', page, quantity, searchWDelay],
		queryFn: ({ pageParam }) => getAllPaginatedDraws(user?.token!, pageParam, quantity, searchWDelay),
		initialPageParam: page,
		getNextPageParam: ({ data }) => {
			const nextPage = data.total_pages > data.current_page ? data.current_page + 1 : undefined;
			return nextPage;
		},
		enabled: !!user?.token,
	});

	const { ref, inView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage && !isLoading && !ErrorOnLoadDraws) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isLoading, ErrorOnLoadDraws, fetchNextPage]);

	const renderDraws = useMemo(() => {
		if (status === 'pending') {
			return <CircularProgress className="mx-auto" />;
		} else if (status === 'error') {
			return (
				<h1 className="mx-auto my-2 flex w-full items-center justify-center gap-1.5 text-center text-lg font-medium text-muted-foreground">
					Falha ao carregar dados -{' '}
					<span className="text-sm underline" onClick={() => window.location.reload()}>
						Recarregar
					</span>{' '}
				</h1>
			);
		} else {
			return (
				<div className="mb-10 space-y-4">
					{Draws &&
						Draws.pages.map((page: QueryGetAllPaginatedDraws, index) => {
							return (
								<Fragment key={index}>
									{page.data.results && page.data.results.length > 0 ? (
										page.data.results.map((year: DrawYear, yearIndex: number) => (
											<div key={yearIndex + 'year'} className="flex flex-col gap-2">
												<h1 className="flex items-center gap-4 text-xl font-medium">
													<Divider className="flex-1" />
													{year.year}
												</h1>
												{year.months.map((month: DrawMonth, monthIndex: number) => (
													<motion.div
														variants={SlideInFromLeft}
														initial="initial"
														animate="animate"
														key={monthIndex + 'month'}
														className="space-y-2"
													>
														<motion.h2
															variants={SlideInFromLeftItem}
															className="flex items-center gap-4 rounded-bl-md border-l pl-3 text-lg font-normal capitalize"
														>
															<Badge variant={'secondary'} className="translate-y-3 font-normal">
																{getMonthText(Number(month.month))}
															</Badge>
														</motion.h2>
														<div className="translate-y-3 space-y-3 pl-3">
															{month.draws.map((draw: Draw, drawIndex: number) => {
																const prizeIsMoney = !/[^\d]/.test(draw.prize);
																return (
																	<motion.div key={drawIndex + 'draw'} variants={SlideInFromLeftItem}>
																		<DrawCard draw={draw} prizeIsMoney={prizeIsMoney}>
																			<CardFooter className="justify-end px-6">
																				<Button
																					onClick={() => {
																						setSelectedDrawId(draw.id);
																					}}
																				>
																					Selecionar
																				</Button>
																			</CardFooter>
																		</DrawCard>
																	</motion.div>
																);
															})}
														</div>
													</motion.div>
												))}
											</div>
										))
									) : (
										<div className="mt-4 flex w-full justify-center text-2xl text-muted-foreground">
											<h1 className="mx-auto font-normal">Sem mais registros</h1>
										</div>
									)}
								</Fragment>
							);
						})}
					<div ref={ref} id="intersectionObserver" />
				</div>
			);
		}
	}, [status, Draws]);

	const handleRedirect = (link?: string, href?: string) => {
		if (link) return navigate(link);
		if (href) {
			const newTab = window.open(href, '_blank');
			if (newTab) {
				newTab.focus();
			}
			return null;
		}
		return null;
	};

	if (id && error) return <h1>lost</h1>;

	return (
		<Suspense fallback={<LoadingPage />}>
			<Template.Root>
				<Template.Aside className="max-w-64">
					<CreateDrawAsideActions />
				</Template.Aside>
				<Template.Page>
					<Template.Nav>
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon" className="md:hidden">
									<LucideMenu size={20} />
								</Button>
							</SheetTrigger>
							<SheetContent side="left" className="flex flex-col">
								<SheetHeader>
									<SheetTitle>Pormade Sorteador</SheetTitle>
								</SheetHeader>
								<CreateDrawAsideActions />
							</SheetContent>
						</Sheet>
					</Template.Nav>
					<Template.Content>
						{!selectedDrawId ? (
							<div className="flex h-full w-full flex-col gap-2 lg:max-w-3xl">
								<h1>Primeiro escolha um sorteio</h1>
								<div className="mt-2 flex w-full items-center gap-2 ">
									<Label htmlFor="search" className="relative flex w-full flex-1 items-center">
										<LucideSearch size={16} className="absolute left-4 top-2" />
										<Input
											id="search"
											value={search ?? ''}
											onChange={handleSearch}
											placeholder="Pesquise sorteios aqui"
											className="pl-12"
										/>
									</Label>
									<Button onClick={() => handleRedirect(undefined, `/new-draw`)} variant={`emphasis`}>
										Novo sorteio <LucidePlusCircle className="transition-all duration-150 group-hover:rotate-90" size={18} />
									</Button>
								</div>
								{renderDraws}
							</div>
						) : (
							renderForms()
						)}
					</Template.Content>
				</Template.Page>
			</Template.Root>

			<Modal
				backdrop="blur"
				shouldBlockScroll
				classNames={{
					base: 'bg-background',
					backdrop: 'bg-gradient-to-t from-background/50 to-background/70 ',
					closeButton: `z-50`,
				}}
				size={'3xl'}
				isOpen={modalEditDrawType.isOpen}
				onOpenChange={modalEditDrawType.onOpenChange}
			>
				<ModalContent className="rounded-md  bg-background p-4">
					<ModalHeader className="relative overflow-hidden p-1 text-lg">
						<h1>Defina o tipo do sorteio</h1>
					</ModalHeader>
					<ModalBody>
						<RadioGroup value={newDrawType!} onValueChange={(value) => setNewDrawType(value)}>
							<Radio classNames={{ label: 'font-normal' }} value={DrawType.questions}>
								Questionário
							</Radio>
							<Radio classNames={{ label: 'font-normal' }} value={DrawType.text}>
								Campo de texto
							</Radio>
							<Radio classNames={{ label: 'font-normal' }} value={DrawType.list}>
								Lista de participantes
							</Radio>
						</RadioGroup>
					</ModalBody>
					<ModalFooter>
						<Button disabled={newDrawType === Draw?.type} onClick={() => handleUpdateDrawType(newDrawType!)}>
							Salvar
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Suspense>
	);
}
