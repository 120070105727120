import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface TemplateAsideProps extends HTMLAttributes<HTMLElement> {}
export default function TemplateAside({ className, children, ...rest }: TemplateAsideProps) {
	return (
		<aside className={twMerge('hidden h-screen w-[30%] flex-col gap-4 overflow-auto border-r border-input p-4 md:flex lg:w-[25%]', className)} {...rest}>
			{children}
		</aside>
	);
}
