import { Breadcrumb, BreadcrumbEllipsis, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from '@/components/ui/drawer';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import useMediaQuery from '@/hooks/use-media-query';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const ITEMS_TO_DISPLAY = 3;

export function BreadcrumbResponsive() {
	const [open, setOpen] = useState(false);
	const isMobile = useMediaQuery({ breakpoint: 640 });

	const paths = useLocation().pathname;
	const pathNames = paths.split('/').filter((path) => path);

	if (paths.length === 1 && pathNames.at(0) === 'dashboard') return null;

	return (
		<Breadcrumb>
			<BreadcrumbList>
				<BreadcrumbLink asChild className="max-w-20 truncate md:max-w-none">
					<Link to={'/dashboard'}>Dashboard</Link>
				</BreadcrumbLink>
				<BreadcrumbSeparator />
				{pathNames.length > ITEMS_TO_DISPLAY && (
					<>
						<BreadcrumbItem>
							{!isMobile ? (
								<DropdownMenu open={open} onOpenChange={setOpen}>
									<DropdownMenuTrigger className="flex items-center gap-1" aria-label="Toggle menu">
										<BreadcrumbEllipsis className="h-4 w-4" />
									</DropdownMenuTrigger>
									<DropdownMenuContent align="start">
										{pathNames.map((link, index) => {
											const isLastLink = pathNames.at(-1) === link;
											const href = isLastLink ? paths : `/${pathNames.slice(0, index + 1).join('/')}`;
											return (
												<DropdownMenuItem key={index}>
													<Link
														to={href ?? '#'}
														data-is-last-link={isLastLink}
														className="capitalize hover:text-secondary-foreground data-[is-last-link=true]:text-primary"
													>
														{link}
													</Link>
												</DropdownMenuItem>
											);
										})}
									</DropdownMenuContent>
								</DropdownMenu>
							) : (
								<Drawer open={open} onOpenChange={setOpen}>
									<DrawerTrigger aria-label="Toggle Menu">
										<BreadcrumbEllipsis className="h-4 w-4" />
									</DrawerTrigger>
									<DrawerContent>
										<DrawerHeader className="text-left">
											<DrawerTitle>Navegar para</DrawerTitle>
											<DrawerDescription>Selecione uma página para ir.</DrawerDescription>
										</DrawerHeader>
										<div className="grid gap-1 px-4">
											{pathNames.map((link, index) => {
												const isLastLink = pathNames.at(-1) === link;
												const href = isLastLink ? paths : `/${pathNames.slice(0, index + 1).join('/')}`;
												return (
													<Link to={href ?? '#'} key={index}>
														<Button className="capitalize" variant={isLastLink ? 'default' : 'emphasis'}>
															{link}
														</Button>
													</Link>
												);
											})}
										</div>
										<DrawerFooter className="pt-4">
											<DrawerClose asChild>
												<Button variant="outline">Fechar</Button>
											</DrawerClose>
										</DrawerFooter>
									</DrawerContent>
								</Drawer>
							)}
						</BreadcrumbItem>
						<BreadcrumbSeparator />
					</>
				)}
				{pathNames.slice(-ITEMS_TO_DISPLAY + 1).map((link, index) => {
					const isLastLink = pathNames.at(-1) === link;
					const href = isLastLink ? paths : `/${pathNames.slice(0, index + 1).join('/')}`;
					return (
						<BreadcrumbItem key={index}>
							<BreadcrumbLink asChild className="max-w-20 truncate md:max-w-none">
								<Link
									to={href}
									data-is-last-link={isLastLink}
									className="capitalize hover:text-secondary-foreground data-[is-last-link=true]:text-primary"
								>
									{link}
								</Link>
							</BreadcrumbLink>
							{!isLastLink && <BreadcrumbSeparator />}
						</BreadcrumbItem>
					);
				})}
			</BreadcrumbList>
		</Breadcrumb>
	);
}
