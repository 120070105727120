import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime).locale(ptBR);

export function getFormattedDatesDifference(date1: string | Date, date2: string | Date, with_suffix?: boolean) {
	const diffInMilliseconds = dayjs(date1).diff(date2);
	const durationFormatted = dayjs.duration(diffInMilliseconds).humanize(with_suffix);

	// Para obter o tempo formatado em um formato mais detalhado, como "X dias, Y horas, Z minutos, W segundos":
	/* const durationDetails = dayjs.duration(diffInMilliseconds);
	const days = durationDetails.days();
	const hours = durationDetails.hours();
	const minutes = durationDetails.minutes();
	const seconds = durationDetails.seconds();

	return `${days} dias, ${hours} horas, ${minutes} minutos, ${seconds} segundos`; */
	return durationFormatted;
}
